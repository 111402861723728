import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import RaffleItem from "../../Components/RaffleItem";
import '../../App.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ethers} from 'ethers';
import PolyDogeABI from '../../Components/polyDogeABI.json';
import SPepeABI from '../../Components/SPepeABI.json';
import { useParams } from 'react-router-dom';

var qs = require('qs');

library.add(faSearch, faTimes);

const Profile = (props) => {
  // loading variable
  const [isLoading, setIsLoading] = useState(true)

  // user variables
  const [walletType, setWalletType] = useState()
  const { userAddress } = useParams();

  // boolean variables
  const [toggleCreated, setToggleCreated] = useState(true)

  // render variables
  const [filterName, setFilterName] = useState('')
  const [filterCollectionName, setFilterCollectionName] = useState('')
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingCollection, setIsSearchingCollection] = useState(false);

  // stats variables
  const [raffleAmountCreated, setRaffleAmountCreated] = useState()
  const [ticketAmountSold, setTicketAmountSold] = useState()
  const [salesVolumeMatic, setSalesVolumeMatic] = useState()
  const [salesVolumePolyDoge, setSalesVolumePolyDoge] = useState()
  const [salesVolumePepe, setSalesVolumePepe] = useState()
  const [raffleAmountBought, setRaffleAmountBought] = useState()
  const [ticketAmountBought, setTicketAmountBought] = useState()
  const [raffleAmountWon, setRaffleAmountWon] = useState()
  const [purchaseVolumeMatic, setPurchaseVolumeMatic] = useState()
  const [purchaseVolumePolyDoge, setPurchaseVolumePolyDoge] = useState()
  const [purchaseVolumePepe, setPurchaseVolumePepe] = useState()
  const [currencyIndex, setCurrencyIndex] = useState(0)
  const currencyOptions = ['$MATIC', '$POLYDOGE', '$SPEPE']

  const [walletAddress, setWalletAddress] = useState()
  const [notFetchedUser, setNotFetchedUser] = useState(true)
  const [connectedTwitter, setConnectedTwitter] = useState(0);
  const [twitterDict, setTwitterDict] = useState();
  const [connectedDiscord, setConnectedDiscord] = useState(0);
  const [discordDict, setDiscordDict] = useState();

  // shouldnt be necessary but adding it so I dont break anything
  const [selectedRaffle, setSelectedRaffle] = useState()
  const [showSelectedRaffle, setShowSelectedRaffle] = useState(false)

  // set public key & wallet type
  useEffect(() => {
    if (props.defaultAccount.address){
      if (!walletAddress){
        setWalletAddress(props.defaultAccount.address)
      }
      else {
        if (walletAddress !== props.defaultAccount.address){
          setNotFetchedUser(true)
          setWalletAddress(props.defaultAccount.address)
        }
      }
      // setprops.defaultAccount.address(props.defaultAccount.address)
      setWalletType(props.defaultAccount.connector.walletConnector.name)
    }
  }, [props.defaultAccount.address])

  // get all stats && purchasedRaffles
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      let tempAmountCreated = 0.0
      let tempTicketAmountSold = 0.0
      let tempSalesVolumeMatic = 0.0
      let tempSalesVolumePolyDoge = 0.0
      let tempSalesVolumePepe = 0.0
      let tempRaffleAmountBought = 0.0
      let tempTicketAmountBought = 0.0
      let tempRafflesWon = 0.0
      let tempPurchaseVolumeMatic = 0.0
      let tempPurchaseVolumePolyDoge = 0.0
      let tempPurchaseVolumePepe = 0.0
      for (const raffle of props.allRaffles) {
        if (userAddress === 'user'){
          if (raffle.creator === props.defaultAccount.address){
            tempAmountCreated += 1
            tempTicketAmountSold += raffle.tickets.length
            if (raffle.tokenAddress === '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'){
              tempSalesVolumeMatic += (raffle.tickets.length*parseFloat(ethers.utils.formatEther(raffle.ticketCost)))
            }
            else if (raffle.tokenAddress === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472'){
              tempSalesVolumePolyDoge += (raffle.tickets.length*parseFloat(ethers.utils.formatEther(raffle.ticketCost)))
            }
            else if (raffle.tokenAddress === '0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1'){
              tempSalesVolumePepe += (raffle.tickets.length*parseFloat(ethers.utils.formatEther(raffle.ticketCost)))
            }
          }
          if (raffle.tickets.includes(props.defaultAccount.address)){
            tempRaffleAmountBought += 1
          }
          if (raffle.winner === props.defaultAccount.address){
            tempRafflesWon += 1
          }
          for (const ticket of raffle.tickets){
            if (ticket === props.defaultAccount.address){
              tempTicketAmountBought += 1
              if (raffle.tokenAddress === '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'){
                tempPurchaseVolumeMatic += parseFloat(ethers.utils.formatEther(raffle.ticketCost))
              }
              else if (raffle.tokenAddress === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472'){
                tempPurchaseVolumePolyDoge += parseFloat(ethers.utils.formatEther(raffle.ticketCost))
              }
              else if (raffle.tokenAddress === '0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1'){
                tempPurchaseVolumePepe += parseFloat(ethers.utils.formatEther(raffle.ticketCost))
              }
            }
          }
        }
        else{
          if (raffle.creator === userAddress){
            tempAmountCreated += 1
            tempTicketAmountSold += raffle.tickets.length
            if (raffle.tokenAddress === '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'){
              tempSalesVolumeMatic += (raffle.tickets.length*parseFloat(ethers.utils.formatEther(raffle.ticketCost)))
            }
            else if (raffle.tokenAddress === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472'){
              tempSalesVolumePolyDoge += (raffle.tickets.length*parseFloat(ethers.utils.formatEther(raffle.ticketCost)))
            }
            else if (raffle.tokenAddress === '0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1'){
              tempSalesVolumePepe += (raffle.tickets.length*parseFloat(ethers.utils.formatEther(raffle.ticketCost)))
            }
          }
          if (raffle.tickets.includes(userAddress)){
            tempRaffleAmountBought += 1
          }
          if (raffle.winner === userAddress){
            tempRafflesWon += 1
          }
          for (const ticket of raffle.tickets){
            if (ticket === userAddress){
              tempTicketAmountBought += 1
              if (raffle.tokenAddress === '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'){
                tempPurchaseVolumeMatic += parseFloat(ethers.utils.formatEther(raffle.ticketCost))
              }
              else if (raffle.tokenAddress === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472'){
                tempPurchaseVolumePolyDoge += parseFloat(ethers.utils.formatEther(raffle.ticketCost))
              }
              else if (raffle.tokenAddress === '0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1'){
                tempPurchaseVolumePepe += parseFloat(ethers.utils.formatEther(raffle.ticketCost))
              }
            }
          }
        }
      }
      setRaffleAmountCreated(tempAmountCreated)
      setTicketAmountSold(tempTicketAmountSold)
      setSalesVolumeMatic(tempSalesVolumeMatic.toFixed(2))
      setSalesVolumePolyDoge(tempSalesVolumePolyDoge.toFixed(2))
      setSalesVolumePepe(tempSalesVolumePepe.toFixed(2))
      setRaffleAmountBought(tempRaffleAmountBought)
      setTicketAmountBought(tempTicketAmountBought)
      setRaffleAmountWon(tempRafflesWon)
      setPurchaseVolumeMatic(tempPurchaseVolumeMatic.toFixed(2))
      setPurchaseVolumePolyDoge(tempPurchaseVolumePolyDoge.toFixed(2))
      setPurchaseVolumePepe(tempPurchaseVolumePepe.toFixed(2))
      setIsLoading(false)
    }
    fetchData();
  }, [userAddress, props.defaultAccount.address, props.allRaffles])


  useEffect(() => {
    if (walletAddress && notFetchedUser){
      var data = JSON.stringify({
        "action": "getUserData",
        'walletAddress': walletAddress,
        "platform": "raffles"
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log(response)
          setNotFetchedUser(0)
          if (response.data.connectedTwitter){
            setConnectedTwitter(1)
            setTwitterDict(response.data.twitterDict)
          }
          else {
            const urlParams = new URLSearchParams(window.location.search);
            const oauth_token = urlParams.get('oauth_token');
            const oauth_verifier = urlParams.get('oauth_verifier');
            if (oauth_token && oauth_verifier){
              connectTwitter(oauth_token, oauth_verifier)
            }
          }
          if (response.data.connectedDiscord){
            setConnectedDiscord(1)
            setDiscordDict(response.data.discordDict)
          }
          else {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (code){
              connectDiscord(code)
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [walletAddress, notFetchedUser]);

  // Toggle between live and past raffles.
  const showCreatedRaffles = useCallback(() => {
    setToggleCreated(true);
  }, []);
  const showPurchased = useCallback(() => {
    setToggleCreated(false);
  }, []);

  // Toggle between currencies
  const nextCurrency = () => {
    setCurrencyIndex((currencyIndex + 1) % currencyOptions.length);
  };

  // Show selected Raffle
  const selectRaffle = (async (item) => {
    // const index = await props.raffleContract.getRaffleIndex(item.creator, item.nftContractAddress, item.nftId)
    props.navigate(`/raffles/raffle/${item}`)
  })

  // Render Created Raffles
  const renderCreated = () => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 xl:gap-10 w-full h-full">
        {props.allRaffles
          .filter((item) => {
            if (userAddress === 'user'){
              if (isSearching){
                return item.creator === props.defaultAccount.address && (item.nftName.toLowerCase().includes(filterName.toLowerCase()) || item.collectionName.toLowerCase().includes(filterName.toLowerCase()));
              }
              else if (isSearchingCollection){
                return item.creator === props.defaultAccount.address && (item.nftName.toLowerCase().includes(filterCollectionName.toLowerCase()) || item.collectionName.toLowerCase().includes(filterCollectionName.toLowerCase()));
              }
              return item.creator === props.defaultAccount.address;
            }
            else{
              if (isSearching){
                return item.creator === userAddress && (item.nftName.toLowerCase().includes(filterName.toLowerCase()) || item.collectionName.toLowerCase().includes(filterName.toLowerCase()));
              }
              else if (isSearchingCollection){
                return item.creator === userAddress && (item.nftName.toLowerCase().includes(filterCollectionName.toLowerCase()) || item.collectionName.toLowerCase().includes(filterCollectionName.toLowerCase()));
              }
              return item.creator === userAddress;
            }
          })
          .map((item, index) => {
            let raffleCreator = item.creator
            if (props.verifiedUsers[item.creator]?.twitterHandle){
              raffleCreator = "@" + props.verifiedUsers[item.creator]?.twitterHandle
            }
            else if (props.verifiedUsers[item.creator]?.discordHandle){
              raffleCreator = "@" + props.verifiedUsers[item.creator]?.discordHandle
            }
            return (
              <RaffleItem
                key={`${item.raffleID}-${item.nftContractAddress}-${item.creator}-${item.nftId}-${(item.duration.toNumber() + item.startDate.toNumber())}`}
                raffle={item}
                publicKey={props.defaultAccount.address}
                walletType={walletType}
                selectRaffle = {selectRaffle}
                purchaseRaffleTicket={purchaseRaffleTicket}
                type='createdRaffle'
                cancelRaffle={cancelRaffle}
                verifiedCollections={props.verifiedCollections}
                endTime={item.duration.toNumber() + item.startDate.toNumber()}
                raffleCreator={raffleCreator}
                navigate={props.navigate}
              />
            )
          })}
      </div>
    );
};
  const renderBought = () => {
    return (
      <div className="grid sm:grid-cols-2 items-center justify-center w-full h-full gap-4">
        {
          props.allRaffles
          .filter((item) => {
            if (userAddress === 'user'){
              if (isSearching){
                return item.tickets.includes(props.defaultAccount.address) && (item.nftName.toLowerCase().includes(filterName.toLowerCase()) || item.collectionName.toLowerCase().includes(filterName.toLowerCase()));
              }
              else if (isSearchingCollection){
                return item.tickets.includes(props.defaultAccount.address) && (item.nftName.toLowerCase().includes(filterCollectionName.toLowerCase()) || item.collectionName.toLowerCase().includes(filterCollectionName.toLowerCase()));
              }
              return (item.tickets.includes(props.defaultAccount.address));
            }
            else{
              if (isSearching){
                return item.tickets.includes(userAddress) && (item.nftName.toLowerCase().includes(filterName.toLowerCase()) || item.collectionName.toLowerCase().includes(filterName.toLowerCase()));
              }
              else if (isSearchingCollection){
                return item.tickets.includes(userAddress) && (item.nftName.toLowerCase().includes(filterCollectionName.toLowerCase()) || item.collectionName.toLowerCase().includes(filterCollectionName.toLowerCase()));
              }
              return (item.tickets.includes(userAddress));
            }
          })
          .map((item, index) => {
            let raffleCreator = item.creator
            if (props.verifiedUsers[item.creator]?.twitterHandle){
              raffleCreator = "@" + props.verifiedUsers[item.creator]?.twitterHandle
            }
            else if (props.verifiedUsers[item.creator]?.discordHandle){
              raffleCreator = "@" + props.verifiedUsers[item.creator]?.discordHandle
            }
            return (
              <RaffleItem
                key={`${index}-${item.nftContractAddress}-${item.creator}-${item.nftId}-${(item.duration.toNumber() + item.startDate.toNumber())}`}
                raffle={item}
                publicKey={props.defaultAccount.address}
                walletType={walletType}
                selectRaffle = {selectRaffle}
                purchaseRaffleTicket={purchaseRaffleTicket}
                type='purchasedRaffles'
                cancelRaffle={cancelRaffle}
                verifiedCollections={props.verifiedCollections}
                endTime={item.duration.toNumber() + item.startDate.toNumber()}
                userAddress={userAddress}
                raffleCreator={raffleCreator}
                navigate={props.navigate}
              />
            )
          })}
      </div>
    )
  };

  // Purchase Raffle Entry Functionality
  const purchaseRaffleTicket = useCallback( async (ticket, amountPurchased) => {
    // check wallet type for injected provider arg
    let walletProvider = window.ethereum
    if (walletType === "PhantomEvm"){
      walletProvider = window.phantom.ethereum
    }

    const clientProvider = new ethers.providers.Web3Provider(walletProvider);
    const raffleContract = new ethers.Contract(props.raffleContractAddress, props.raffleContractABI, clientProvider);
    const index = await raffleContract.getRaffleIndex(ticket.creator, ticket.nftContractAddress, ticket.nftId)
    if (amountPurchased > 0 && amountPurchased <= (ticket.ticketQuantity.toNumber() - ticket.tickets.length)){
      props.setPopup(true)
      props.setPopupState("purchaseInProgress")
      if (ticket.tokenAddress === '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'){
        const totalPrice = (ticket.ticketCost * amountPurchased).toString()
        try {
          const enterRaffle = await raffleContract.connect(clientProvider.getSigner()).purchaseTickets(
            index,
            amountPurchased,
            {
              value: totalPrice
            }
          )
          enterRaffle.wait()
          .then((receipt) => {
            let ticketsBought = Array(parseInt(amountPurchased)).fill(props.defaultAccount.address);
            ticket.tickets = [...ticket.tickets, ...ticketsBought]
            props.setPopupState("rafflePurchased")
          })
          .catch((error) => {
            props.setPopupState('transactionError')
          });
        }
        catch (error){
          props.setPopupState('transactionError')
        }
      }
      else if (ticket.tokenAddress === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472'){
        const etherCost = parseFloat(ethers.utils.formatEther(ticket.ticketCost));
        let finalPrice = ethers.utils.parseUnits((etherCost*amountPurchased).toString(), "ether")
        const tokenContract = new ethers.Contract('0x8A953CfE442c5E8855cc6c61b1293FA648BAE472', PolyDogeABI, clientProvider);
        try {
          const approveTransaction = await tokenContract.connect(clientProvider.getSigner()).approve(props.raffleContractAddress, finalPrice)
          approveTransaction.wait()
          .then(async (receipt) => {
            try {
              const enterRaffle = await raffleContract.connect(clientProvider.getSigner()).purchaseTickets(
                index,
                amountPurchased
              )
              enterRaffle.wait()
              .then((receipt) => {
                let ticketsBought = Array(parseInt(amountPurchased)).fill(props.defaultAccount.address);
                ticket.tickets = [...ticket.tickets, ...ticketsBought]
                props.setPopupState("rafflePurchased")
              })
              .catch((error) => {
                props.setPopupState('transactionError')
              });
            }
            catch {
              props.setPopupState('transactionError')
            }
          })
          .catch((error) => {
            props.setPopupState('transactionError')
          });
        } catch (error){
          props.setPopupState('transactionError')
        }
      }
      else if (ticket.tokenAddress === '0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1'){
        const etherCost = parseFloat(ethers.utils.formatEther(ticket.ticketCost));
        let finalPrice = ethers.utils.parseUnits((etherCost*amountPurchased).toString(), "ether")
        const tokenContract = new ethers.Contract('0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1', SPepeABI, clientProvider);
        try {
          const approveTransaction = await tokenContract.connect(clientProvider.getSigner()).approve(props.raffleContractAddress, finalPrice)
          approveTransaction.wait()
          .then(async (receipt) => {
            try {
              const enterRaffle = await raffleContract.connect(clientProvider.getSigner()).purchaseTickets(
                index,
                amountPurchased
              )
              enterRaffle.wait()
              .then((receipt) => {
                let ticketsBought = Array(parseInt(amountPurchased)).fill(props.defaultAccount.address);
                ticket.tickets = [...ticket.tickets, ...ticketsBought]
                props.setPopupState("rafflePurchased")
              })
              .catch((error) => {
                props.setPopupState('transactionError')
              });
            }
            catch (error) {
              props.setPopupState('transactionError')
            }
          })
          .catch((error) => {
            props.setPopupState('transactionError')
          });
        } catch (error) {
          props.setPopupState('transactionError')
        }
      }
    }
    else{
      props.setPopup(true)
      props.setPopupState('wrongAmount')
    }

  }, [props.defaultAccount.address, props.allRaffles, walletType])

  // Search Raffles by NFT Name
  const handleSearch = () => {
    setIsSearching(true);
  };
  const handleClearSearch = () => {
    setIsSearching(false);
    setFilterName('');
    setFilterCollectionName('');
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  // Search Raffles by Collection Name
  const handleSearchCollection = () => {
    setIsSearchingCollection(true);
  };
  const handleClearSearchCollection = () => {
    setIsSearchingCollection(false);
    setFilterCollectionName('');
  };
  const handleKeyDownCollection = (event) => {
    if (event.key === 'Enter') {
      handleSearchCollection();
    }
  };

  // cancel Raffle function
  const cancelRaffle = (async (ticket) => {
    props.setPopup(true)
    props.setPopupState("cancellationInProgress")

    // check wallet type for injected provider arg
    let walletProvider = window.ethereum
    if (walletType === "PhantomEvm"){
      walletProvider = window.phantom.ethereum
    }

    // instantiate raffle contract
    const clientProvider = new ethers.providers.Web3Provider(walletProvider);
    const raffleContract = new ethers.Contract(props.raffleContractAddress, props.raffleContractABI, clientProvider);

    // get raffle index
    const index = await raffleContract.getRaffleIndex(ticket.creator, ticket.nftContractAddress, ticket.nftId)

    try {
      const cancelRaffle = await raffleContract.connect(clientProvider.getSigner()).cancelRaffle(index)
      cancelRaffle.wait()
      .then((receipt) => {
        props.setIsLoading(true)
        props.setUpdatedRaffles(prevState => !prevState);
        props.setPopupState("cancelledRaffle")
      })
      .catch((error) => {
        props.setPopupState("transactionError")
      })
    }
    catch {
      props.setPopupState("transactionError")
    }
  })

  // formatting amount for cleaner render
  function formatTotalAmount(totalAmount) {
    if (parseFloat(totalAmount) >= 1000000) {
      // Convert to millions format
      const millionCost = parseFloat(totalAmount) / 1000000;
      return `${millionCost.toFixed(0)}m`;
    } else if (parseFloat(totalAmount) >= 1000) {
      // Convert to thousands format
      const thousandCost = parseFloat(totalAmount) / 1000;
      return `${thousandCost.toFixed(0)}k`;
    } else {
      // Return as is
      return parseFloat(totalAmount).toFixed(2);
    }
  }

  const connectDiscord = (code) => {
    // change this to back end
    let client_id = process.env.DISCORD_CLIENT_ID
    let client_secret = process.env.DISCORD_CLIENT_SECRET

    var data = qs.stringify({
      'client_id': client_id,
      'client_secret': client_secret,
      'grant_type': 'authorization_code',
      'code': code,
      'redirect_uri': 'https://www.maxinout.com/raffles/profile/user'
    });

    var config = {
      method: 'post',
      url: 'https://discord.com/api/oauth2/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      var data = JSON.stringify({
        'action': 'saveDiscordCredentials',
        'accessToken': response.data.access_token,
        'refreshToken': response.data.refresh_token,
        'walletAddress': walletAddress,
        'platform': "raffles"
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log(response)
          setDiscordDict(response.data.discordDict)
          setConnectedDiscord(1)
        })
        .catch(function (error) {
          // console.log(error);
        });
    })
    .catch(function (error) {
      // console.log(error);
    })
  }

  const disconnectDiscord = () => {
    var data = JSON.stringify({
      'action': 'disconnectDiscord',
      'walletAddress': walletAddress,
      'platform': "raffles"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        setDiscordDict()
        setConnectedDiscord(0)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const twitterRedirect = (response) => {

    var data = JSON.stringify({
      "action": "getTwitterRedirect",
      "platform": "raffles",
      'walletAddress': walletAddress
    });
    console.log(data)
    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response)
        if (response.data.oauth_token){
          const redirectUrl = 'https://api.twitter.com/oauth/authenticate?oauth_token=' + response.data.oauth_token
          window.location.href = redirectUrl;
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const connectTwitter = async (oauthToken, verifier) => {

    var data = JSON.stringify({
      "action": "connectTwitter",
      "oauth_token": oauthToken,
      "oauth_verifier": verifier,
      "walletAddress": walletAddress,
      'platform': 'raffles'
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response)
        setTwitterDict(response.data.twitterDict)
        setConnectedTwitter(1)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const disconnectTwitter = () => {
    var data = JSON.stringify({
      'action': 'disconnectTwitter',
      'walletAddress': walletAddress,
      'platform': 'raffles'
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        setTwitterDict()
        setConnectedTwitter(0)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <div className="grid gap-8 relative w-full">
      {
        isLoading ?
        <div className="flex items-center justify-center h-screen">
          <div className="loader"></div>
        </div>
        :
        <>
        {
          userAddress === 'user' ?
          <p className="bg-[#A7A2A6] sm:w-[30%] text-lg md:mt-0 md:text-3xl font-bold text-bold text-dark-gray text-center">
            MY PROFILE
          </p>
          :
          <p className="bg-[#A7A2A6] sm:w-[30%] text-lg md:mt-0 md:text-3xl font-bold text-bold text-dark-gray text-center">
            USER PROFILE
          </p>
        }
        <div class="flex flex-col items-center justify-center">
          <div class="flex flex-col items-center justify-between w-[90%] gap-4 lg:flex-row">
            {
              userAddress === 'user' ?
              <div class="flex sm:w-[60%] flex-col items-center justify-center gap-2">
                <div class="mb-1 flex items-center gap-8">
                  {
                    connectedTwitter ?
                      <button
                        className="flex justify-start items-center gap-10 w-[50%] text-sm sm:text-2xl py-2 px-4 rounded-full bg-primary-yellow font-text font-bold text-bold text-dark-gray text-center border-2 border-dark-gray hover:bg-opacity-50"
                      >
                        <img className="w-[12%] h-[12%] rounded-full" alt="TwitterPFP" src={twitterDict.twitterPFP}/>
                        <p class="flex-1 truncate">{twitterDict.twitterHandle}</p>
                      </button>
                    :
                      <button onClick={() => twitterRedirect()} class="text-sm sm:text-2xl py-2 px-4 rounded-full w-[50%] bg-primary-yellow font-text font-bold text-bold text-dark-gray text-center border-2 border-dark-gray hover:bg-opacity-50 truncate">
                        Connect Twitter
                      </button>
                  }
                  {
                    connectedDiscord ?
                    <button
                      className="flex justify-start items-center gap-10 w-[50%] text-sm sm:text-2xl py-2 px-4 rounded-full bg-primary-yellow font-text font-bold text-bold text-dark-gray text-center border-2 border-dark-gray hover:bg-opacity-50"
                    >
                      <img className="w-[12%] h-[12%] rounded-full" alt="DiscordPFP" src={discordDict.discordPFP}/>
                      <p class="flex-1 truncate">{discordDict.discordHandle}</p>
                    </button>
                    :
                    <button onClick={() => window.location.href = "https://discord.com/api/oauth2/authorize?client_id=1109141234762645504&redirect_uri=https%3A%2F%2Fwww.maxinout.com%2Fraffles%2Fprofile%2Fuser&response_type=code&scope=identify%20guilds%20email%20connections"} class="text-sm sm:text-2xl py-2 px-4 w-[50%] rounded-full bg-primary-yellow font-text font-bold text-bold text-dark-gray text-center border-2 border-dark-gray hover:bg-opacity-50 truncate">
                      Connect Discord
                    </button>
                  }
                </div>
                {
                  (connectedDiscord || connectedTwitter) ?
                  <div class="flex flex-cols-2 items-center text-center justify-between w-full">
                    {
                      connectedTwitter ?
                      <div onClick={() => disconnectTwitter()} class="w-[50%] text-sm font-text font-bold text-dark-gray underline hover:text-primary-yellow">
                        DISCONNECT TWITTER
                      </div>
                      :
                      <div class="w-[50%]"></div>
                    }
                    {
                      connectedDiscord ?
                      <div onClick={() => disconnectDiscord()} class="w-[50%] text-sm font-text font-bold text-dark-gray underline hover:text-primary-yellow">
                        DISCONNECT DISCORD
                      </div>
                      :
                      <div class="w-[50%]"></div>
                    }
                  </div>
                  :
                  <div class="text-md font-text font-bold text-dark-gray">
                    CONNECT YOUR SOCIALS FOR PRIZES AND GIVEAWAYS INCLUDING AUTOMATIC MAXIN WL
                  </div>
                }
              </div>
              :
              ""
            }
            <div class="flex flex-col items-center justify-center text-center w-full gap-2">
              <div class="grid grid-cols-4 gap-2 w-full">
                <div class="flex flex-col bg-[#D9D9D9] justify-center items-center rounded-lg px-2 py-4">
                  <p class="text-[10px] sm:text-sm w-full h-[50%] font-text font-bold text-dark-gray uppercase">Tickets Sold</p>
                  <p class="text-2xl sm:text-4xl w-full font-text font-bold text-primary-red">{ticketAmountSold}</p>
                </div>
                <div class="flex flex-col bg-[#D9D9D9] justify-center items-center rounded-lg px-2 py-4">
                  <p class="text-[10px] sm:text-sm w-full h-[50%] font-text font-bold text-dark-gray uppercase">Tickets Bought</p>
                  <p class="text-2xl sm:text-4xl font-text font-bold text-primary-red">{ticketAmountBought}</p>
                </div>
                <div class="flex flex-col bg-[#D9D9D9] justify-center items-center rounded-lg px-2 py-4">
                  <p class="text-[10px] sm:text-sm w-full h-[50%] font-text font-bold text-dark-gray uppercase">Purchase Volume <br></br>({currencyOptions[currencyIndex]})</p>
                  <p class="text-2xl sm:text-4xl font-text font-bold text-primary-red">{currencyIndex === 0 ? purchaseVolumeMatic : (currencyIndex === 1 ? formatTotalAmount(purchaseVolumePolyDoge) : formatTotalAmount(purchaseVolumePepe))}</p>
                </div>
                <div class="flex flex-col bg-[#D9D9D9] justify-center items-center rounded-lg px-2 py-4">
                  <p class="text-[10px] sm:text-sm w-full h-[50%] font-text font-bold text-dark-gray uppercase">Sales Volume <br></br>({currencyOptions[currencyIndex]})</p>
                  <p class="text-2xl sm:text-4xl font-text font-bold text-primary-red">{currencyIndex === 0 ? salesVolumeMatic : (currencyIndex === 1 ? formatTotalAmount(salesVolumePolyDoge) : formatTotalAmount(salesVolumePepe))}</p>
                </div>
              </div>
              <div class="flex flex-cols-2 items-center text-center justify-between w-full">
                <div class="w-[50%]"></div>
                <div onClick={nextCurrency} class="w-[50%] text-sm font-text font-bold text-dark-gray underline hover:text-primary-yellow">
                  CLICK TO TOGGLE CURRENCIES
                </div>
              </div>
            </div>
          </div>
          <div class="mb-1 flex w-[90%] items-center pt-8 gap-8 flex-row">
            {
            toggleCreated ?
            <>
              <button onClick={showCreatedRaffles} class="text-sm sm:text-xl py-1 px-4 rounded-full bg-dark-gray font-text font-bold text-bold text-primary-yellow text-center border-2 border-primary-yellow">
                Created Raffles
              </button>
              <button onClick={showPurchased} class="text-sm sm:text-xl py-1 px-4 rounded-full font-text font-bold text-bold text-dark-gray text-center hover:text-primary-yellow">
                Raffles Purchased
              </button>
            </>
            :
            <>
            <button onClick={showCreatedRaffles} class="text-sm sm:text-xl py-1 px-4 rounded-full font-text font-bold text-bold text-dark-gray text-center hover:text-primary-yellow">
              Created Raffles
            </button>
            <button onClick={showPurchased} class="text-sm sm:text-xl py-1 px-4 rounded-full bg-dark-gray font-text font-bold text-bold text-primary-yellow text-center border-2 border-primary-yellow">
              Raffles Purchased
            </button>
            </>
          }
          </div>
          <div class="mb-1 flex w-[90%] items-center flex-col pt-8 pb-4 gap-8 md:flex-row">
            <div className="relative">
              <button className="absolute inset-y-0 left-0 flex items-center pl-3" onClick={handleSearch}>
                <FontAwesomeIcon icon="search" className="text-gray-deep hover:text-primary-yellow"/>
              </button>
              {isSearching && (
                <button className="absolute inset-y-0 right-0 flex items-center pr-3" onClick={handleClearSearch}>
                  <FontAwesomeIcon icon="times" className="text-gray-deep" />
                </button>
              )}
              <input
                className="bg-gray-light border-2 border-dark-gray rounded-full pl-12 pr-1 py-[2px] text-[16px] font-bold text-gray-deep focus:outline-none"
                type="text"
                placeholder="Search Raffle Name"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="relative">
              <button className="absolute inset-y-0 left-0 flex items-center pl-3" onClick={handleSearchCollection}>
                <FontAwesomeIcon icon="search" className="text-gray-deep hover:text-primary-yellow"/>
              </button>
              {isSearchingCollection && (
                <button className="absolute inset-y-0 right-0 flex items-center pr-3" onClick={handleClearSearchCollection}>
                  <FontAwesomeIcon icon="times" className="text-gray-deep" />
                </button>
              )}
              <input
                className="bg-gray-light border-2 border-dark-gray rounded-full pl-12 pr-1 py-[2px] text-[16px] font-bold text-gray-deep focus:outline-none"
                type="text"
                placeholder="Search By Collection"
                value={filterCollectionName}
                onChange={(e) => setFilterCollectionName(e.target.value)}
                onKeyDown={handleKeyDownCollection}
              />
            </div>
          </div>
          <div class="flex w-[90%] items-center justify-center mb-8">
            {
              toggleCreated ?
              <>
              {
                raffleAmountCreated ?
                renderCreated()
                :
                <div class="text-4xl font-text font-bold text-dark-gray pt-10 pb-4">
                  NO RAFFLES HAVE BEEN CREATED.
                </div>
              }
              </>
              :
              <>
              {
                raffleAmountBought ?
                renderBought()
                :
                <div class="text-4xl font-text font-bold text-dark-gray pt-10 pb-4">
                  NO RAFFLES HAVE BEEN PURCHASED.
                </div>
              }
              </>
            }
          </div>
        </div>
        </>
      }
    </div>
  )

}

export default Profile;
