import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { useRef } from 'react';
import { saveAs } from 'file-saver';

const Builder = () => {

  const [backgroundImage, setBackgroundImage] = useState();
  const [maxinImage, setMaxinImage] = useState();
  const [allLayerImages, setAllLayerImages] = useState({});
  const [imageArray, setImageArray] = useState([]);
  const [downloadImage, setDownloadImage] = useState([]);
  const [popupState, setPopupState] = useState("creatingImage")
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    async function fetchData() {
      let tempImageArray = []
      const response = await axios.get('https://maxinbodyshop.s3.us-east-2.amazonaws.com/maxinCustomizer.json');
      setAllLayerImages(response.data);
      tempImageArray.push("https://maxinbodyshop.s3.us-east-2.amazonaws.com/customizer/polypurple.png")
      tempImageArray.push("https://maxinbodyshop.s3.us-east-2.amazonaws.com/customizer/maxinBack.png")
      tempImageArray.push(response.data['Skin'][Math.floor(Math.random() * response.data['Skin'].length)])
      tempImageArray.push(response.data['Clothing'][Math.floor(Math.random() * response.data['Clothing'].length)])
      tempImageArray.push(response.data['Held_Item'][Math.floor(Math.random() * response.data['Held_Item'].length)])
      tempImageArray.push(response.data['Head'][Math.floor(Math.random() * response.data['Head'].length)])
      tempImageArray.push(response.data['Eyes'][Math.floor(Math.random() * response.data['Eyes'].length)])
      tempImageArray.push(response.data['Ear'][Math.floor(Math.random() * response.data['Ear'].length)])
      tempImageArray.push(response.data['Face_Accessory'][Math.floor(Math.random() * response.data['Face_Accessory'].length)])
      tempImageArray.push(response.data['Mouth'][Math.floor(Math.random() * response.data['Mouth'].length)])
      setImageArray(tempImageArray)
    }

    fetchData();
  }, []);

  const randomHash = (length) => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const randomize = async () => {
    let tempImageArray = []
    tempImageArray.push("https://maxinbodyshop.s3.us-east-2.amazonaws.com/customizer/polypurple.png")
    tempImageArray.push("https://maxinbodyshop.s3.us-east-2.amazonaws.com/customizer/maxinBack.png")
    tempImageArray.push(allLayerImages['Skin'][Math.floor(Math.random() * allLayerImages['Skin'].length)])
    tempImageArray.push(allLayerImages['Clothing'][Math.floor(Math.random() * allLayerImages['Clothing'].length)])
    tempImageArray.push(allLayerImages['Held_Item'][Math.floor(Math.random() * allLayerImages['Held_Item'].length)])
    tempImageArray.push(allLayerImages['Head'][Math.floor(Math.random() * allLayerImages['Head'].length)])
    tempImageArray.push(allLayerImages['Ear'][Math.floor(Math.random() * allLayerImages['Ear'].length)])
    tempImageArray.push(allLayerImages['Eyes'][Math.floor(Math.random() * allLayerImages['Eyes'].length)])
    tempImageArray.push(allLayerImages['Face_Accessory'][Math.floor(Math.random() * allLayerImages['Face_Accessory'].length)])
    tempImageArray.push(allLayerImages['Mouth'][Math.floor(Math.random() * allLayerImages['Mouth'].length)])
    setImageArray(tempImageArray)

  }

  const handleDownloadAndTweet = async () => {
      setPopupState("creatingImage")
      setPopup(true)
      let identifyingHash = randomHash(50);
      var data = JSON.stringify({
        action: "createImage",
        imageArray: imageArray,
        identifyingHash: identifyingHash
      });
      var config = {
        method: "post",
        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/twitter",
        headers: {
          "x-api-key": 'YdODCb97vJ4dUhHHBqZSP5doyeYQ3W9o2aMDYX6Z',
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(async (response) => {
          console.log(response.data.imageLink)
          let blob = await fetch(response.data.imageLink).then((r) => r.blob());
          await saveAs(blob, 'maxin.png');

          console.log('got here')

          const tweetText = "Just used the @maxinNFT builder and it was dope - I'm officially a maxie! \n\nCheck it out:";
          const imageUrl = "https://maxinout.com/";
          const mediaId = "1646561367388717064";

          const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(imageUrl)}&media_ids=${encodeURIComponent(mediaId)}`;
          window.open(tweetUrl, "_blank");

          setPopup(false)
          setPopupState()

        })
        .catch(function (error) {
          // // console.log(error);
        });
}

  const renderPopup = () => {
    if (popupState === "creatingImage"){
      return (
        <div style={{position:'absolute', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-white rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-gray-800 font-gilroy-bold">Image is being created...</p>
          </div>
        </div>
      )
    }
    // else if (popupState === "twitterURLfailed"){
    //   <div style={{position:'absolute', bottom: 35, right: 35, zIndex:100}}>
    //     <div className="bg-white rounded-md p-4 flex items-center">
    //       <img
    //         className="h-5 w-5 mr-4 animate-spin"
    //         src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
    //         alt="Loading"
    //       />
    //       <p className="text-gray-800 font-gilroy-bold">We are unable to open up </p>
    //     </div>
    //   </div>
    // }
  }

  return (
    <div className="grid grid-cols-3 h-full w-full">
      {popup ? renderPopup() : ""}
      <div className="flex justify-center items-center lg:items-center lg:mb-8">
        <p className="font-gilroy-bold text-center uppercase text-[100%]">click image <br></br> to randomize</p>
      </div>
      <div class="flex flex-col items-center justify-center w-full h-full overflow-hidden">
        {imageArray.slice(2).map((image, index) => (
              <img
                key={index}
                className={`absolute bottom-0 sm:max-w-[90%] sm:max-h-[90%] md:max-w-[80%] md:max-h-[80%] lg:max-w-[90%] lg:max-h-[90%] object-contain`}
                src={image}
                onClick={()=>randomize()}
              />
            ))}
      </div>
      <div>
        <img className="h-24 w-24 mr-8 mt-24 hover:opacity-50" src={require('../../img/twitter-icon.png')} onClick = {handleDownloadAndTweet} style={{position:'absolute', top: 0, right: 0, zIndex:100}} alt="Logo"/>
        <img className="h-[100px] w-[100px] mr-32 mt-32 lg:mr-36" src={require('../../img/arrow-one.png')} style={{position:'absolute', top: 0, right: 0, zIndex:100}} alt="Logo"/>
        <p className="font-gilroy-bold md:text-lg lg:text-2xl text-right h-[100px] w-[240px] lg:w-[300px] xl:w-[400px] mr-16 mt-56" style={{position:'absolute', top: 0, right: 0, zIndex:100}}>post your creation on twitter for a chance to win a free NFT!</p>
      </div>
    </div>
  )

}

export default Builder
