import React, { useCallback, useState, useEffect, useRef } from "react";
import axios from "axios";
import {ethers} from 'ethers';
import '../../App.css'
import PacksABI from '../../Components/packsABI.json';
import DropDown from "../../Components/UI/DropDown/DropDown";


const { Network, Alchemy } = require("alchemy-sdk");

const TraitsInfo = (props) => {
  const packsContractAddress = "0x0dB6aD9ce8f2299ef23E06AB55A6E126878855B3"
  const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BS_KEY}`);
  const packsContractProvider = new ethers.Contract(packsContractAddress, PacksABI, provider);

  // traits variables
  const [traitsDict, setTraitsDict] = useState({'Clothes': [], 'Head': [], 'Accessory': [], 'Item': [], 'Ears': [], 'Mouth': [], 'Eyes': []})
  const [categories, setCategories] = useState(['Clothes', 'Head', 'Accessory', 'Item', 'Ears', 'Mouth', 'Eyes'])
  const [supplyPerCategory, setSupplyPerCategory] = useState()
  const [selectedCategory, setSelectedCategory] = useState('Clothes')
  const [colorScheme, setColorScheme] = useState({
    'legendary': '#BD00FF',
    'epic':'#7d032a',
    'rare': '#6db047',
    'uncommon': '#6863b5',
    'common': '#254590'
  })
  // useEffect(() => {
  //   const fetchAllTraits = async () => {
  //     // Create an array of promises
  //     const promises = Array.from({ length: 200 }, (_, i) => packsContractProvider.getTrait(i));
  //
  //     try {
  //       // Wait for all promises to complete
  //       const results = await Promise.all(promises);
  //       console.log(results)
  //       // Now, 'results' is an array of all the fetched traits.
  //       // Do something with results, like setting state
  //     } catch (error) {
  //       console.error('Error fetching traits:', error);
  //     }
  //   };
  //
  //   fetchAllTraits();
  // }, []);

  useEffect(() => {
      var data = JSON.stringify({
        "action": "getAllTraits",
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        // console.log(response.data)
        setTraitsDict(response.data.traitDict)
        setSupplyPerCategory(response.data.supplyPerCategory)
      })
      .catch(function (error){
        console.log(error)
      })
  }, [])

  const updateTraitsTable = async () => {
    const delay = (duration) => {
        return new Promise(resolve => setTimeout(resolve, duration));
    };

    for (let i = 2; i < 213; i++) {
      const finalTrait = await packsContractProvider.getTrait(i)
      // console.log(finalTrait.category.toNumber(), finalTrait.name, finalTrait.amountMinted.toNumber(), finalTrait.maxSupply.toNumber(), finalTrait.rarityTier.toNumber());
      const traitURI = await packsContractProvider.getTraitURI(i)
      // console.log(traitURI)
      const response = await axios.get(traitURI);
      // console.log(response.data.image)
      var data = JSON.stringify({
        "action": "updateTraitsInfo",
        "category": finalTrait.category.toNumber(),
        "name": finalTrait.name.replace(" trait", ""),
        "amountMinted": finalTrait.amountMinted.toNumber(),
        "maxSupply": finalTrait.maxSupply.toNumber(),
        "rarityTier": finalTrait.rarityTier.toNumber(),
        "imageURI": response.data.image
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        // console.log(response)
      })
      .catch(function (error){
        console.log(error)
      })
      await delay(2500);
    }

  }

  const renderTraits = useCallback(() => {

    return (
      <div className=" w-full h-full grid grid-rows-2 md:grid-cols-2 xl:grid-cols-4 gap-12">
        {traitsDict[selectedCategory]?.length > 0 ? (
          traitsDict[selectedCategory].map((trait, key) => {
            return (
                <div className = "flex flex-col h-full items-center justify-start p-3 rounded-2xl relative">
                  <img src={trait.imageURI} className="cursor-pointer object-cover w-full rounded-t-2xl"/>
                  <div className="absolute top-5 right-5 font-bold px-2 py-1 rounded-xl  text-[80%] flex justify-center items-center text-center uppercase" style={{backgroundColor: colorScheme[trait.rarityTier]}}>
                      <p className="text-white">{trait.rarityTier}</p>
                  </div>
                  <div
                    key={selectedCategory + key}
                    className="flex flex-col items-center justify-start w-full h-full rounded-b-2xl px-4 py-2 gap-2"
                    style={{backgroundColor: colorScheme[trait.rarityTier]}}
                  >
                    <p className="w-full text-start text-[#E0DFDF]  font-bold text-[100%] uppercase truncate">
                      {trait.name}
                    </p>
                    <div className="flex items-start justify-between text-start gap-4 w-full">
                      <div className="flex flex-col items-center justify-center text-start w-[70%]">
                        <p className="w-full text-start font-bold text-[80%] text-primary-yellow opacity-[90%] uppercase">
                          Supply
                        </p>
                        <p className="w-full text-start text-[#E0DFDF]  font-bold text-[100%] uppercase">
                          {trait.amountMinted}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center text-start">
                        <p className="w-full text-start font-bold text-[80%] text-primary-yellow opacity-[90%] uppercase truncate">
                          % Rarity
                        </p>
                        <p className="w-full text-start text-[#E0DFDF] font-bold text-[80%] uppercase">
                          {((trait.amountMinted / supplyPerCategory[selectedCategory])*100).toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            )
          })
        ):
        ("")
        }
      </div>
    )
  }, [traitsDict, categories, selectedCategory])

  return (
    <div className="w-full h-full flex-grow flex-col flex px-5 xl:px-10 py-5 gap-4">
      {/*<button onClick={() => updateTraitsTable()}>Update Traits</button>*/}
      <div class="flex flex-col font-bold text-dark-gray items-start justify-start">
        <h1 className="text-[8vw] sm:text-[4vw]">TRAITS OVERVIEW</h1>
      </div>
      <div class = "grid md:grid-cols-4 w-full">
        {categories?.length > 0 && (
        <DropDown
          value={selectedCategory}
          values={categories}
          selectValue={setSelectedCategory}
        />
      )}
      </div>
      {renderTraits()}
    </div>
  )



}

export default TraitsInfo;
