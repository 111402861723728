import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {ethers} from 'ethers';
import { DynamicWidget, DynamicConnectButton } from '@dynamic-labs/sdk-react';
import { faQuestionCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../App.css'


const { Network, Alchemy } = require("alchemy-sdk");
var qs = require('qs');

export default function PointsBar({
  portal,
  level,
  totalXP,
  totalMP,
  userRank
}) {
  // console.log(portal)
  return (
    <>
    <div className="hidden sm:flex flex-col justify-start items-center text-center w-full h-full px-4 gap-4">
      <div className="bg-dark-gray flex flex-col justify-center items-center text-center w-full bg-opacity-10 rounded-xl aspect-ratio-square">
        <div className="aspect-ratio-content">
          <span className="flex items-center justify-center w-full uppercase font-bold text-dark-gray text-[70%] xl:text-[100%]">
            Max Points
          </span>
          <p className="text-[200%] xl:text-[400%] w-full font-text font-bold text-primary-red">
              {totalMP}
          </p>
        </div>
      </div>
      <div className="relative flex flex-row justify-start items-center text-center w-full">
        <button className="absolute bg-primary-red rounded-full p-2 w-6 h-6 xl:w-8 xl:h-8 flex items-center justify-center border-[#F8F2E5] border-2 text-[#F8F2E5] font-bold">
          {level}
        </button>
        <p className="bg-[#F8F2E5] w-full rounded-xl text-primary-red font-bold flex justify-end px-2 text-[40%] xl:text-[70%]">
          {totalXP} / {level*20} XP
        </p>
      </div>
      {
        portal === "earnPortal" ?
        <p className = "font-lekton text-dark-gray text-[50%]">
          You can earn points to use on lunchboxes and trait claims by liking and retweeting maxin and maxin related posts!
          <br></br>
          <br></br>
          You can also create your own post and request to get it added to the live posts for other maxies to interact with!
          <br></br>
          <br></br>
          Maxie Holders profiles are featured in our "maxies follow maxies" tab. Make sure to follow your fellow Maxies!
        </p>
        :
        <>
        {
          portal === "claimsPortal" ?
          <p className = "font-lekton text-dark-gray text-[50%]">
            Here you can spend your MAX points on traits and lunchboxes.
            <br></br>
            <br></br>
            Traits and Lunchboxes can only be purchased by Maxie Holders. Keep a
            lookout for exclusive purchasable traits/lunchboxes for Maxie Mini Whales, Whales, Mega Whales, etc.
          </p>
          :
          <>
          {
            portal === "rankPortal" ?
            <>
            <p className="font-bold text-primary-red text-full">
              My Rank: {userRank ? userRank : 'N/A'}
            </p>
            </>
            :
            <p className = "font-lekton text-dark-gray text-[50%]">
              Welcome to the maxin' points portal! To get full access to all it's features make sure to connect your wallet & socials.
            </p>
          }
          </>
        }
        </>
      }
    </div>
    <div className="flex sm:hidden flex-col justify-start items-center text-center w-full h-full gap-4">
      <div className="flex flex-row justify-start items-center text-center w-full gap-4">
        <div className="bg-dark-gray flex flex-col justify-center items-center text-center w-full h-full bg-opacity-10 rounded-xl">
            <span className="flex items-center justify-center w-full uppercase font-bold text-dark-gray text-[70%] xl:text-[100%]">
              Max Points
            </span>
            <p className="text-[200%] xl:text-[400%] w-full font-text font-bold text-primary-red">
                {totalMP}
            </p>
        </div>
        {
          portal === "earnPortal" ?
          <p className = "font-lekton text-dark-gray text-[50%]">
            You can earn points to use on lunchboxes and trait claims by liking and retweeting maxin and maxin related posts!
            <br></br>
            <br></br>
            You can also create your own post and request to get it added to the live posts for other maxies to interact with!
          </p>
          :
          <>
          {
            portal === "claimsPortal" ?
            <p className = "font-lekton text-dark-gray text-[50%]">
              Here you can spend your MAX points on traits and lunchboxes.
              <br></br>
              <br></br>
              Traits and Lunchboxes can only be purchased by Maxie Holders. Keep a
              lookout for exclusive purchasable traits/lunchboxes for Maxie Mini Whales, Whales, Mega Whales, etc.
            </p>
            :
            <>
            {
              portal === "rankPortal" ?
              <>
              <p className="font-bold text-primary-red text-full">
                My Rank: {userRank}
              </p>
              </>
              :
              ""
            }
            </>
          }
          </>
        }
      </div>
      <div className="relative flex flex-row justify-start items-center text-center w-full">
        <button className="absolute bg-primary-red rounded-full p-2 w-6 h-6 xl:w-8 xl:h-8 flex items-center justify-center border-[#F8F2E5] border-2 text-[#F8F2E5] font-bold">
          {level}
        </button>
        <p className="bg-[#F8F2E5] w-full rounded-xl text-primary-red font-bold flex justify-end px-2 text-[40%] xl:text-[70%]">
          {totalXP} / {level*20} XP
        </p>
      </div>
    </div>
    </>
  );
}
