import './App.css';
import React, { useState, useEffect } from 'react';
import Nav from './Pages/Nav'
import Points from './Pages/Points/Points'
import { DynamicContextProvider } from '@dynamic-labs/sdk-react';
import { DynamicWagmiConnector } from '@dynamic-labs/wagmi-connector';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {

  return (
      <Router>
        <div className="bg-[#EAE9E8] min-h-screen">
          <DynamicContextProvider
             settings={{
               environmentId: process.env.DYNAMIC_KEY,
               initialAuthenticationMode: 'connect-only'
          }}>
            <DynamicWagmiConnector evmNetworks={[
              {
                blockExplorerUrls: ['https://polygonscan.com/'],
                chainId: 137,
                chainName: 'Matic Mainnet',
                iconUrls: ['https://app.dynamic.xyz/assets/networks/polygon.svg'],
                nativeCurrency: {
                  decimals: 18,
                  name: 'MATIC',
                  symbol: 'MATIC',
                },
                networkId: 137,
                rpcUrls: ['https://polygon-rpc.com'],
                shortName: 'MATIC',
                vanityName: 'Polygon',
              },
            ]}>
              <Nav />
            </DynamicWagmiConnector>
          </DynamicContextProvider>
        </div>
      </Router>
  );
}

export default App;
