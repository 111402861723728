import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import {ethers} from 'ethers';
import '../../App.css'
import EmptyMaxie from '../../img/emptyMaxin.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const { Network, Alchemy } = require("alchemy-sdk");

const MaxieCheck = (props) => {

  const [tokenID, setTokenID] = useState()
  const [maxieImage, setMaxieImage] = useState(EmptyMaxie)
  const [imageArray, setImageArray] = useState([])
  const [attributes, setAttributes] = useState([
                                                  {'trait_type': 'Background', 'value': '????'},
                                                  {'trait_type': 'Skin', 'value': '????'},
                                                  {'trait_type': 'Clothes', 'value': '????'},
                                                  {'trait_type': 'Head', 'value': '????'},
                                                  {'trait_type': 'Eyes', 'value': '????'},
                                                  {'trait_type': 'Accessory', 'value': '????'},
                                                  {'trait_type': 'Ears', 'value': '????'},
                                                  {'trait_type': 'Mouth', 'value': '????'},
                                                  {'trait_type': 'Item', 'value': '????'},
                                                ])

  const settings = {
    apiKey: process.env.ALCHEMY_BS_KEY, // Replace with your Alchemy API Key.
    network: Network.MATIC_MAINNET, // Replace with the network your NFT is on.
  };
  // Creating an Alchemy instance to make calls to the Alchemy API
  const alchemy = new Alchemy(settings);

  // Function to get the metadata of an NFT: accepts the NFT contract address and the token ID to get the metadata of
  async function getNFTMetadata(tokenId) {
    // console.log(tokenId)
    if (tokenId && tokenID >= 0 && tokenID <= 5999){
      try {
        const selectedMaxie = await alchemy.nft.getNftMetadata('0xd51b6113995a1d30125F60a542b2aE0bCE678715', tokenId);
        // console.log(selectedMaxie.tokenUri.raw)
        const response = await axios.get(selectedMaxie.tokenUri.raw + '?' + new Date().getTime());
        let currTrait = {}
        console.log(response.data.attributes)
        setAttributes(response.data.attributes)
        response.data.attributes.map((trait) => {
            currTrait[trait['trait_type']] = trait['value'];
        });
        var data = JSON.stringify({
          "action": "getMaxie",
          "currTraits": currTrait,
        });
        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
        .then(function (response) {
            setImageArray(response.data.imageArray)
          })
        .catch(function (error) {
          console.log(error);
        });
      } catch (error) {
        console.error('Error getting NFT metadata:', error);
        throw error;
      }
    }
    else {
      props.setPopup(true)
      props.setPopupState("invalidID")
    }
  }

  function handleChange(event) {
    setTokenID(event.target.value);
  }

  return (
    <div className="flex flex-col lg:grid lg:grid-cols-2 h-full w-full lg:pr-10">
      <div className="flex flex-col justify-center items-end w-full z-10 gap-4 px-5 lg:hidden">
        <div className = "grid grid-cols-3 grid-rows-3 w-full h-full bg-gray rounded-xl gap-4 p-4">
          {
            attributes.map((trait, index) => (
              <div class="flex flex-col justify-start items-center text-center bg-white rounded-lg font-bold text-[40%] text-gray">
                <div class="bg-dark-gray w-full rounded-t-lg text-white">{trait.trait_type}</div>
                <div class= "flex justify-center items-center text-center w-full h-full min-h-[30px]">{trait.value}</div>
              </div>
            ))
          }
        </div>
        <div className="flex flex-row text-[40%]">
          <input className="bg-white font-lekton rounded-l-xl text-gray py-3 px-6 h-full" type="number" placeholder='search by token ID' tokenID={tokenID} onChange={e => {handleChange(e)}}/>
          <button className="bg-primary-red rounded-xl rounded-l-none text-white py-3 px-6 h-full font-text uppercase font-bold" onClick={() => getNFTMetadata(tokenID)}>search</button>
        </div>
      </div>
      <div class="relative flex flex-col items-center justify-center w-full h-full overflow-hidden">
        {
          imageArray?.length === 0 ?
          <img
            className={`absolute bottom-0 left-0 max-w-full max-h-full object-contain`}
            src={EmptyMaxie}
          />
          :
          <>
          {
            imageArray.slice(1).map((image, index) => (
                <img
                  key={index}
                  className={`absolute bottom-0 left-0 max-w-full max-h-full object-contain`}
                  src={image}
                />
              ))}
          </>
        }
      </div>
      <div className="hidden lg:flex lg:flex-col lg:justify-start lg:items-end lg:w-full lg:z-10 lg:gap-4">
        <div className = "grid grid-cols-3 grid-rows-3 w-full bg-gray h-1/2 rounded-xl gap-4 p-4">
          {
            attributes.map((trait, index) => (
              <div class="flex flex-col justify-start items-center text-center bg-white rounded-lg font-bold text-[60%] md:text-[80%] lg:text-[100%] 2xl:text-[140%] text-gray">
                <div class="bg-dark-gray w-full rounded-t-lg text-white">{trait.trait_type}</div>
                <div class= "flex justify-center items-center text-center w-full h-full">{trait.value}</div>
              </div>
            ))
          }
        </div>
        <div className="flex flex-row text-[60%] md:text-[80%] lg:text-[100%] 2xl:text-[140%]">
          <input className="bg-white font-lekton rounded-l-xl text-gray py-3 px-6 h-full" type="number" placeholder='search by token ID' tokenID={tokenID} onChange={e => {handleChange(e)}}/>
          <button className="bg-primary-red rounded-xl rounded-l-none text-white py-3 px-6 h-full font-text uppercase font-bold" onClick={() => getNFTMetadata(tokenID)}>search</button>
        </div>
      </div>
    </div>
  )

}

export default MaxieCheck;
