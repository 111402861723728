import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { useRef } from 'react';
import WPPageOne from '../../img/wp1.png'
import WPPageTwo from '../../img/wp2.png'
import WPPageThree from '../../img/wp3.png'
import WPPageFour from '../../img/wp4.png'
import WPPageFive from '../../img/wp5.png'
import WPPageSix from '../../img/wp6.png'
import WPPageSeven from '../../img/wp7.png'
import WPPageEight from '../../img/wp8.png'
import WPPageNine from '../../img/wp9.png'
import WPPageTen from '../../img/wp10.png'

import BulletOn from '../../img/bulletOn.png'
import BulletOff from '../../img/bulletOff.png'

const Whitepaper = () => {

  const [whitePages, setWhitePages] = useState([WPPageOne, WPPageTwo, WPPageThree, WPPageFour, WPPageFive, WPPageSix, WPPageSeven, WPPageEight, WPPageNine, WPPageTen])
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  const imageRef = useRef();

  const updateImageSize = useCallback(() => {
    setImageHeight(imageRef.current.clientHeight);
  }, []);

  useEffect(() => {
    setImageHeight(imageRef.current.clientHeight);
  }, [currentIndex]);

  useEffect(() => {
    updateImageSize();
    window.addEventListener('resize', updateImageSize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', updateImageSize);
    }
  }, [updateImageSize]);

  const handleNextPage = () => {
    const nextPageIndex = currentIndex + 1 === whitePages.length ? 0 : currentIndex + 1;
    setCurrentIndex(nextPageIndex);
  }

  const handleBulletClick = (index) => {
    setCurrentIndex(index);
  }

  const handleImageLoad = () => {
    updateImageSize();
  };

  return (
    <div className="flex items-center justify-center mx-auto w-[80%] gap-4">
      <div>
        <img ref={imageRef} src={whitePages[currentIndex]} onClick={handleNextPage} onLoad={handleImageLoad}/>
      </div>
      <div class="flex flex-col items-center justify-center gap-2 w-[20%]" style={{
        height: imageHeight
        }}>
        {whitePages.map((page, index) => (
            <img
              src={index === currentIndex ? BulletOn : BulletOff}
              onClick={() => handleBulletClick(index)}
              class="h-[8%] w-auto"
            />
        ))}
      </div>
    </div>
  )

}

export default Whitepaper
