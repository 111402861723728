import React from "react";
import { NavItem } from "./UI/StyledComponents";
import Logo from "../img/initial_image.png";
import MaxinLogo from '../img/maxinLogo.png';

const SideBarNew = (props) => {

  let tabs = [
    // { label: "customizer", tabName: "customizer", bg: "bg-primary-red" },
    { label: "raffles", tabName: "raffles", bg: "bg-primary-red-tint" },
    { label: "points", tabName: "points/profile", bg: "bg-primary-yellow" },
    { label: "whitepaper", tabName: "whitepaper", bg: "bg-primary-yellow" },
    { label: "body shop", tabName: "bodyshop", bg: "bg-primary-yellow" },
  ];
  let subTabs = [
    { label: "drops", tabName: "bodyshop/maxin", bg: "bg-primary-yellow" },
    { label: "customize", tabName: "bodyshop/maxin/customize", bg: "bg-primary-yellow" },
    { label: "maxies", tabName: "bodyshop/maxin/maxies", bg: "bg-primary-yellow" },
    { label: "traits", tabName: "bodyshop/maxin/traits", bg: "bg-primary-yellow" },
  ]

  return (
    <nav className="w-full h-full flex flex-col items-center justify-center">
      <div class="flex items-center justify-center mt-2">
        <img onClick={() => {
          props.navigate("/")
          props.setPage("");
        }}
        src={MaxinLogo} alt="CurrentLogo" class="max-h-full w-[100px] hover:cursor-pointer" />
      </div>
      <ul className="flex flex-col items-center justify-center h-full flex-grow gap-5">
        {tabs.map((tab) => (
          <div
            onClick={() => {
              props.navigate(`/${tab.tabName}`)
              props.setPage(tab.tabName);
            }}
            key={`${tab.tabName}_${tab.label}`}
            className={`px-6 sm:px-8 text-xs sm:text-base cursor-pointer lowercase font-lekton text-white ${
              props?.page.includes(tab.tabName) ? "sm:text-primary-red font-bold" : "sm:text-dark-gray"
            }`}
          >
            {tab.label}
          </div>
        ))}
        {subTabs.map((subTab) => (
          <div
            onClick={() => {
              props.navigate(`/${subTab.tabName}`)
              props.setPage(subTab.tabName);
            }}
            key={`${subTab.tabName}_${subTab.label}`}
            className={`px-6 sm:px-8 text-xs sm:text-xs cursor-pointer lowercase font-lekton text-white ${
              subTab.tabName === props?.page ? "sm:text-primary-yellow font-bold" : "sm:text-gray"
            }`}
          >
            {subTab.label}
          </div>
        ))}
      </ul>
    </nav>
  );
};
export default SideBarNew;
