import React, { useCallback, useState, useEffect, useRef } from 'react';
import PointsIMG from "../../img/points-img.png"
import TweetItem from "./TweetItem.js"
import axios from 'axios';
import {ethers} from 'ethers';
import { DynamicWidget, DynamicConnectButton } from '@dynamic-labs/sdk-react';
import Carousel from 'react-elastic-carousel';
import { faQuestionCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../App.css'
import MaxinTraitsABI from '../../Components/maxinTraitsABI.json';
import { Outlet, Link } from 'react-router-dom';
import styles from "../../Components/Points.module.scss";
const { Network, Alchemy } = require("alchemy-sdk");

var qs = require('qs');

const Points = (props) => {

  // tab variable
  var currentURL = window.location.href;
  const [tab, setTab] = useState('profile')

  useEffect(() => {
    var urlParts = currentURL.split("/");
    var pageName = urlParts[urlParts.length - 1];
    setTab(pageName)
  }, [currentURL])

  return (
    <div className="flex flex-col lg:grid lg:grid-cols-[30%,70%] items-center justify-center mx-auto h-screen sm:w-[80%] sm:h-[80%] gap-4 bg-[#D9D9D9] max-h-screen sm:max-h-[calc(100vh-120px)]">
      <div class="hidden lg:block w-full h-full max-h-[calc(100vh-200px)]">
        <img src={PointsIMG} class="w-full h-full object-cover mt-[3vh]"/>
      </div>
      <div class="w-full h-full flex flex-col justify-start items-center text-center p-8 gap-8 overflow-y-scroll">
        <nav class="w-full flex justify-center items-center text-center font-title-regular">
          <ul className="flex gap-4 md:gap-16 2xl:gap-16 text-dark-gray uppercase text-[60%] sm:text-[70%] xl:text-[100%]">
            <li><Link to="profile" class={`md:whitespace-nowrap hover:font-title-bold ${tab === 'profile' ? "font-title-bold" : ""}`}>PROFILE</Link></li>
            <li><Link to="spend" class={`md:whitespace-nowrap hover:font-title-bold ${tab === 'spend' ? "font-title-bold" : ""}`}>SPEND POINTS</Link></li>
            <li><Link to="earn" class={`md:whitespace-nowrap hover:font-title-bold ${tab === 'earn' ? "font-title-bold" : ""}`}>EARN POINTS</Link></li>
            <li><Link to="leaderboards" class={`md:whitespace-nowrap hover:font-title-bold ${tab === 'leaderboards' ? "font-title-bold" : ""}`}>LEADERBOARDS</Link></li>
          </ul>
        </nav>
        <Outlet />
      </div>
    </div>
  )

}

export default Points;
