import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import '../../App.css'
import { Checkmark } from 'react-checkmark';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BuyNowBtn } from "../../Components/UI/StyledComponents";
import {ethers} from 'ethers';
import twitter from "../../img/twitter.svg";
import polyIcon from "../../img/polyIcon.svg";
import magicEden from "../../img/ME-logo.png";
import PolyDogeABI from '../../Components/polyDogeABI.json';
import SPepeABI from '../../Components/SPepeABI.json';
import { useParams } from 'react-router-dom';

library.add(faSearch, faTimes);


const SelectedRaffle = (props) => {
  const { raffleId } = useParams();
  const [timeLeft, setTimeLeft] = useState("");
  const [timerCheck, setTimerCheck] = useState()
  const [amountPurchased, setAmountPurchased] = useState()
  const [entries, setEntries] = useState({})
  const [magicEdenURL, setMagicEdenURL] = useState()
  const [twitterURL, setTwitterURL] = useState()
  const [winner, setWinner] = useState(true)
  const [walletType, setWalletType] = useState()

  // not necessary but adding anyway
  const [selectedRaffle, setSelectedRaffle] = useState(props.allSelectedRaffles[raffleId])
  const [selectedRaffleIndex, setSelectedRaffleIndex] = useState(raffleId)

  // set public key & wallet type
  useEffect(() => {
    if (props.defaultAccount.address){
      // setprops.defaultAccount.address(props.defaultAccount.address)
      setWalletType(props.defaultAccount.connector.walletConnector.name)
    }
  }, [props.defaultAccount])

  // get time remaining
  useEffect(() => {
    const interval = setInterval(() => {
      let endTime = selectedRaffle.duration.toNumber() + selectedRaffle.startDate.toNumber()
      let now = Math.floor(Date.now());
      let difference = endTime - now;
      setTimerCheck(difference)
      if (difference >= 0 && !(selectedRaffle.status)) {
        difference = Math.floor(difference / 1000);
        let days = Math.floor(difference / (3600 * 24));
        let hours = Math.floor((difference % (3600 * 24)) / 3600);
        let minutes = Math.floor((difference % 3600) / 60);
        let seconds = difference % 60;
        if (days === 0){
          setTimeLeft(`Ends in ${hours} hrs ${minutes} min ${seconds}s`);
        } else{
          setTimeLeft(`Ends in ${days} days ${hours} hrs ${minutes} min`);
        }
      }
      else if (!(selectedRaffle.status) || selectedRaffle.status === 1 || selectedRaffle.status === 2){
        setTimeLeft("Ending Raffle...")
      }
      else {
        let endDate = new Date(selectedRaffle.endDate);
        let endDateString = endDate.toDateString();
        setTimeLeft('Ended on ' + endDateString)
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // get all the raffle entries
  useEffect(() => {
    console.log(selectedRaffle.tickets)
    const countDictionary = selectedRaffle.tickets.reduce((acc, element) => {
      acc[element] = (acc[element] || 0) + 1;
      return acc;
    }, {});
    console.log(countDictionary)
    setEntries(countDictionary);
  }, []);

  // get URLs for verified collections
  useEffect(() => {
    var data = JSON.stringify({
      action: "getVerifiedInfo",
      collectionAddress: selectedRaffle.nftContractAddress
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyraffles",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data)
        setMagicEdenURL(response.data.magicEdenURL)
        setTwitterURL(response.data.twitterURL)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onChange = (e) => {
    if (e.target.name === "ticketAmount"){
      setAmountPurchased(e.target.value)
    }
  }

  // toggle between winners and participants
  const showWinner = useCallback(() => {
    setWinner(true);
  }, []);
  const showParticipants = useCallback(() => {
    // console.log(toggleLivePast)
    setWinner(false);
  }, []);

  // Purchase Raffle Entry Functionality
  const purchaseRaffleTicket = useCallback( async () => {
    // check wallet type for injected provider arg
    let walletProvider = window.ethereum
    if (walletType === "PhantomEvm"){
      walletProvider = window.phantom.ethereum
    }

    const clientProvider = new ethers.providers.Web3Provider(walletProvider);
    const raffleContract = new ethers.Contract(props.raffleContractAddress, props.raffleContractABI, clientProvider);
    if (amountPurchased > 0 && amountPurchased <= (selectedRaffle.ticketQuantity.toNumber() - selectedRaffle.tickets.length)){
      props.setPopup(true)
      props.setPopupState("purchaseInProgress")
      if (selectedRaffle.tokenAddress === '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'){
        const totalPrice = (selectedRaffle.ticketCost * amountPurchased).toString()
        try {
          const enterRaffle = await raffleContract.connect(clientProvider.getSigner()).purchaseTickets(
            selectedRaffleIndex,
            amountPurchased,
            {
              value: totalPrice
            }
          )
          enterRaffle.wait()
          .then((receipt) => {
            console.log('Approval successful!');
            console.log('Transaction receipt:', receipt);
            let ticketsBought = Array(parseInt(amountPurchased)).fill(props.defaultAccount.address);
            selectedRaffle.tickets = [...selectedRaffle.tickets, ...ticketsBought]
            props.setPopupState("rafflePurchased")
          })
          .catch((error) => {
            console.error('Approval failed:', error);
            props.setPopupState('transactionError')
          });
        }
        catch {
          props.setPopupState('transactionError')
        }
      }
      else if (selectedRaffle.tokenAddress === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472'){
        const etherCost = parseFloat(ethers.utils.formatEther(selectedRaffle.ticketCost));
        let finalPrice = ethers.utils.parseUnits((etherCost*amountPurchased).toString(), "ether")
        const tokenContract = new ethers.Contract('0x8A953CfE442c5E8855cc6c61b1293FA648BAE472', PolyDogeABI, clientProvider);
        try {
          const approveTransaction = await tokenContract.connect(clientProvider.getSigner()).approve(props.raffleContractAddress, finalPrice)
          approveTransaction.wait()
          .then(async (receipt) => {
            console.log('Approval successful!');
            console.log('Transaction receipt:', receipt);
            try {
              const enterRaffle = await raffleContract.connect(clientProvider.getSigner()).purchaseTickets(
                selectedRaffleIndex,
                amountPurchased
              )
              enterRaffle.wait()
              .then((receipt) => {
                console.log('Approval successful!');
                console.log('Transaction receipt:', receipt);
                let ticketsBought = Array(parseInt(amountPurchased)).fill(props.defaultAccount.address);
                selectedRaffle.tickets = [...selectedRaffle.tickets, ...ticketsBought]
                props.setPopupState("rafflePurchased")
              })
              .catch((error) => {
                console.error('Approval failed:', error);
                props.setPopupState('transactionError')
              });
            }
            catch {
              props.setPopupState('transactionError')
            }
          })
          .catch((error) => {
            console.error('Approval failed:', error);
            props.setPopupState('transactionError')
          });
        } catch {
          console.log('got here')
          props.setPopupState('transactionError')
        }
      }
      else if (selectedRaffle.tokenAddress === '0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1'){
        const etherCost = parseFloat(ethers.utils.formatEther(selectedRaffle.ticketCost));
        let finalPrice = ethers.utils.parseUnits((etherCost*amountPurchased).toString(), "ether")
        console.log(finalPrice.toString())
        const tokenContract = new ethers.Contract('0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1', SPepeABI, clientProvider);
        try {
          const approveTransaction = await tokenContract.connect(clientProvider.getSigner()).approve(props.raffleContractAddress, finalPrice)
          approveTransaction.wait()
          .then(async (receipt) => {
            console.log('Approval successful!');
            console.log('Transaction receipt:', receipt);
            try {
              const enterRaffle = await raffleContract.connect(clientProvider.getSigner()).purchaseTickets(
                selectedRaffleIndex,
                amountPurchased
              )
              enterRaffle.wait()
              .then((receipt) => {
                console.log('Approval successful!');
                console.log('Transaction receipt:', receipt);
                let ticketsBought = Array(parseInt(amountPurchased)).fill(props.defaultAccount.address);
                selectedRaffle.tickets = [...selectedRaffle.tickets, ...ticketsBought]
                props.setPopupState("rafflePurchased")
              })
              .catch((error) => {
                console.error('Approval failed:', error);
                props.setPopupState('transactionError')
              });
            }
            catch {
              props.setPopupState('transactionError')
            }
          })
          .catch((error) => {
            console.error('Approval failed:', error);
            props.setPopupState('transactionError')
          });
        } catch {
          console.log('got here')
          props.setPopupState('transactionError')
        }
      }
    }
    else{
      props.setPopup(true)
      props.setPopupState('wrongAmount')
    }
  }, [props.defaultAccount.address, selectedRaffleIndex, selectedRaffle, amountPurchased, props.walletType])

  function formatTicketCost(ticketCost) {
    const etherCost = parseFloat(ethers.utils.formatEther(ticketCost));

    if (etherCost >= 1000000) {
      // Convert to millions format
      const millionCost = etherCost / 1000000;
      return `${millionCost.toFixed(0)}m`;
    } else if (etherCost >= 1000) {
      // Convert to thousands format
      const thousandCost = etherCost / 1000;
      return `${thousandCost.toFixed(0)}k`;
    } else {
      // Return as is
      return etherCost.toFixed(2);
    }
  }

  const tweetRaffle = () => {
    const tweetText = `Raffle is LIVE @maxinNFT!

    ▸ Time Remaining: ${timeLeft}
    ▸ Ticket Remaining: ${selectedRaffle.ticketQuantity.toNumber() - selectedRaffle.tickets.length}
    ▸ Ticket Price: ${formatTicketCost(selectedRaffle.ticketCost)} ${selectedRaffle.tokenAddress === '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0' ? '$MATIC' : selectedRaffle.tokenAddress === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472' ? '$PolyDoge' : '$SPEPE'}

    LINK - `

    const raffleUrl = window.location.href;
    const mediaId = "1646561367388717064";

    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(raffleUrl)}`;
    window.open(tweetUrl, "_blank");
  }

  return(
    <div className="grid   gap-8  relative w-full">
      <p className="bg-[#A7A2A6] sm:w-[30%] text-lg md:text-3xl font-bold text-bold text-dark-gray text-center">
        RAFFLE DETAILS
      </p>
      <div className="container bg-dark-gray mx-auto p-6 max-w-screen-lg">
        <div className = "flex justify-between">
          <div className = "flex items-center">
            <p className="text-left text-primary-yellow w-[80%] sm:w-full font-text font-bold text-md sm:text-2xl xl:text-4xl truncate">
              {selectedRaffle.collectionName}
            </p>
            <div className="ml-2">
              {
                (selectedRaffle.verified || props.verifiedCollections.includes(selectedRaffle.nftContractAddress.toLowerCase())) ?
                <Checkmark size="18px" />
                :
                <FontAwesomeIcon icon={faExclamationTriangle} size="18x" color="yellow"/>
              }
            </div>
          </div>
          <button
            className="sm:text-end sm:font-title-regular text-sm sm:text-md xl:text-2xl text-white uppercase hover:text-primary-yellow"
            onClick={() => props.navigate("/raffles")}
          >
            &#9664; Back
          </button>
        </div>
        <div className = "flex items-start justify-start gap-3">
          <p className="text-start text-white font-text font-bold text-lg xl:text-2xl mb-4">
            {selectedRaffle.nftName}
          </p>
          <div className="flex gap-3 items-center">
            {
              (selectedRaffle.verified || props.verifiedCollections.includes(selectedRaffle.nftContractAddress.toLowerCase())) ?
              <>
              <a href={twitterURL} target="_blank" rel="noopener noreferrer">
                <img src={twitter} alt="twitter" />
              </a>
              <a href={magicEdenURL} target="_blank" rel="noopener noreferrer">
                <img className="h-[25px] w-[25px]" src={magicEden} alt="magicEden" />
              </a>
              <a href={`https://polygonscan.com/address/${selectedRaffle.nftContractAddress}`} target="_blank" rel="noopener noreferrer">
                <img src={polyIcon} alt="magicEden" />
              </a>
              </>
              :
              <a href={`https://polygonscan.com/address/${selectedRaffle.nftContractAddress}`} target="_blank" rel="noopener noreferrer">
                <img src={polyIcon} alt="magicEden" />
              </a>
            }
          </div>
        </div>
        <div className="sm:w-full sm:grid sm:grid-cols-3 sm:gap-5 xl:gap-12">
            <div className="flex flex-col items-center gap-4">
              <div className="relative group w-full inline-block col-span-1">
                <img
                  src={`${
                    selectedRaffle.imageLink
                  }?${new Date().getTime()}`}
                  className="w-full cursor-pointer border-2 border-white rounded-2xl bg-[#EAE9E8]"
                />
              </div>
              <button
                onClick={tweetRaffle}
                className="flex justify-start items-center gap-10 w-[90%] p-1 text-sm xl:text-xl bg-transparent border-2 border-white text-white rounded-xl uppercase shadow-lg hover:text-primary-yellow hover:border-primary-yellow mb-4"
              >
                <img className="w-[10%] rounded-full" alt="TwitterPFP" src={twitter}/>
                <p class="justify-start flex-1 truncate">Share Raffle</p>
              </button>
            </div>
            <div class="grid grid-cols-2 justify-start items-start gap-4 col-span-2">
              <div className="flex flex-col justify-center text-center">
                {
                    !(selectedRaffle.status) ?
                    <div>
                      <h3 className='bg-gray-100 font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-red mb-2'>Time Remaining</h3>
                      <h3 className='font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-yellow mb-5'>
                        {timeLeft}
                      </h3>
                      <h3 className='bg-gray-100 font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-red mb-2'>Tickets Remaining</h3>
                    </div>
                    :
                    <div>
                      <h3 className='bg-gray-100 font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-red mb-2'>Status</h3>
                      <h3 className='font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-yellow mb-5'>
                        {timeLeft}
                      </h3>
                      <h3 className='bg-gray-100 font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-red mb-2'>Tickets Purchased</h3>
                    </div>
                  }
                  {
                    (selectedRaffle.ticketQuantity.toNumber() - selectedRaffle.tickets.length) === 0 ?
                    <h3 className='font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-yellow mb-5'>
                      <span class="text-[#FF7F7F]">SOLD OUT</span>
                    </h3>
                    :
                    <h3 className='font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-yellow mb-5'>
                    {selectedRaffle.ticketQuantity.toNumber() - selectedRaffle.tickets.length}/{selectedRaffle.ticketQuantity.toNumber()}
                    </h3>
                  }
                <h3 className='bg-gray-100 font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-red mb-2'>Price Per Ticket</h3>
                <h3 className='font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-yellow mb-5'>
                  {parseFloat(ethers.utils.formatEther(selectedRaffle.ticketCost)).toFixed(2)} {selectedRaffle.tokenAddress === '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0' ? '$MATIC' : `${selectedRaffle.tokenAddress === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472' ? '$PolyDoge' : '$SPEPE'}`}
                </h3>
                <h3 className='bg-gray-100 font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-red mb-2'>Raffle Creator</h3>
                <h3 onClick={() => props.navigate(`/raffles/profile/${selectedRaffle.creator}`)} className='font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-yellow mb-10 text-center truncate hover:underline'>
                  {
                    props.verifiedUsers[selectedRaffle.creator]?.twitterHandle ?
                    <>
                    {"@" + props.verifiedUsers[selectedRaffle.creator]?.twitterHandle}
                    </>
                    :
                    <>
                    {
                      props.verifiedUsers[selectedRaffle.creator]?.discordHandle ?
                      <>
                      {"@" + props.verifiedUsers[selectedRaffle.creator]?.discordHandle}
                      </>
                      :
                      selectedRaffle.creator
                    }
                    </>
                  }
                </h3>
              </div>
              {
                  selectedRaffle.status === 3 ?
                  <div className="flex flex-col justify-center text-center">
                      {
                        winner ?
                        <>
                        <div className = 'flex flex-col-2 justify-center items-center'>
                        <h3 className='bg-primary-red font-gilroy-bold text-xs md:text-lg lg:text-2xl text-white mb-5 min-w-[50%] p-1 sm:p-0'>Winner</h3>
                        <h3 onClick={showParticipants} className='bg-gray-100 font-gilroy-bold text-xs md:text-lg lg:text-2xl text-primary-red mb-5 min-w-[50%] hover:bg-opacity-50 p-1 sm:p-0'>Participants</h3>
                        </div>
                        <p className='font-gilroy-bold text-xs lg:text-sm text-white text-start mb-2'>Wallet Address</p>
                        <h3 className='font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-yellow mb-4 text-start break-all'>
                          {selectedRaffle.winner}
                        </h3>
                        <p className='font-gilroy-bold text-xs lg:text-sm text-white text-start mb-2'>Won with <span className='font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-yellow'>{entries[selectedRaffle.winner]} </span> ticket(s)</p>
                        </>
                        :
                        <>
                        <div className = 'flex flex-col-2 justify-center items-center'>
                          <h3 onClick={showWinner} className='bg-gray-100 font-gilroy-bold text-xs md:text-lg lg:text-2xl text-primary-red mb-5 min-w-[50%] hover:bg-opacity-50 p-1 sm:p-0'>Winner</h3>
                          <h3 className='bg-primary-red font-gilroy-bold text-xs md:text-lg lg:text-2xl text-white mb-5 min-w-[50%] p-1 sm:p-0'>Participants</h3>
                        </div>
                        <div class="grid grid-cols-2">
                          <p className='font-gilroy-bold text-xs lg:text-sm text-white text-start mb-2'>Wallet Address</p>
                          <p className='font-gilroy-bold text-xs lg:text-sm text-white text-end mb-2'>Tickets Bought</p>
                          <div className="col-span-2">
                            <div className="max-h-64 min-w-full overflow-y-auto">
                              <div class="grid grid-cols-2 gap-2">
                                {
                                  Object.keys(entries).map((entry) => {
                                    return (
                                      <>
                                        <p className='font-gilroy-bold text-xs lg:text-sm text-primary-yellow text-start break-all'>{entry}</p>
                                        <p className='font-gilroy-bold text-md lg:text-lg text-primary-yellow text-end'>{entries[entry]}</p>
                                      </>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        </>
                      }
                  </div>
                  :
                  <div className="flex flex-col justify-center text-center">
                    <h3 className='bg-gray-100 font-gilroy-bold text-md md:text-lg lg:text-2xl text-primary-red mb-5'>Raffle Participants</h3>
                    <div class="grid grid-cols-2">
                      <p className='font-gilroy-bold text-xs lg:text-sm text-white text-start mb-2'>Wallet Address</p>
                      <p className='font-gilroy-bold text-xs lg:text-sm text-white text-end mb-2'>Tickets Bought</p>
                      <div className="col-span-2">
                        <div className="max-h-64 min-w-full overflow-y-auto">
                          <div class="grid grid-cols-2 gap-2">
                            {
                              Object.keys(entries).map((entry) => {

                                return (
                                  <>
                                    <p className='font-gilroy-bold text-xs lg:text-sm text-primary-yellow text-start break-all'>{entry}</p>
                                    <p className='font-gilroy-bold text-md lg:text-lg text-primary-yellow text-end'>{entries[entry]}</p>
                                  </>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              {
                (!(selectedRaffle.status) && timerCheck >= 0) ?
                <div className='flex flex-col justify-center items-center col-span-2 -mt-5'>
                  <p className='font-gilroy-bold text-lg lg:text-xl text-white text-start mb-2'>Purchase Tickets</p>
                  <div className="grid gap-2 grid-cols-2">
                    <input name="ticketAmount" type="number" placeHolder="QTY" maxLength={26} onChange={e => {onChange(e)}} className="text-white text-center justify-center items-center bg-transparent border-2 border-white" autoComplete="off"/>
                    <BuyNowBtn
                      onClick={() => {
                        purchaseRaffleTicket(selectedRaffle, amountPurchased)
                      }}
                      disabled={!props.defaultAccount.address}
                      className="disabled:opacity-50 font-text font-semibold px-1 py-1 text-sm xl:text-xl bg-primary-red hover:opacity-50 text-white rounded-full uppercase shadow-lg ml-1 mr-1 z-20"
                    >
                      Buy Now
                    </BuyNowBtn>
                  </div>
                </div>
                :
                ""
              }
            </div>
        </div>
      </div>
    </div>
  )
}

export default SelectedRaffle;
