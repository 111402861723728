import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import {ethers} from 'ethers';
import '../../App.css'
import DropDown from "../../Components/UI/DropDown/DropDown";
import pointer from "../../img/pointer.svg";
import EmptyMaxie from '../../img/emptyMaxin.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import CustomizeABI from '../../Components/customizeABI.json'
import MintABI from '../../Components/maxinMint.json'
import ClaimsABI from '../../Components/claimsTest.json'

const { Network, Alchemy } = require("alchemy-sdk");

const Customize = (props) => {
  // console.log(props)
  // user variables
  const [walletAddress, setWalletAddress] = useState()
  const [walletType, setWalletType] = useState()

  // contract variables
  const customizeAddress = "0x223BC1c1744Ea79f7481aB773e3E7c1AFB054f00"
  const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BS_KEY}`);
  const customizeProvider = new ethers.Contract(customizeAddress, CustomizeABI, provider);

  // trait variables
  const [ownedTraits, setOwnedTraits] = useState()
  const [userTraitCategories, setUserTraitCategories] = useState([])
  const [selectedTraitCategory, setSelectedTraitCategory] = useState('All Traits')
  const [selectedTrait, setSelectedTrait] = useState()

  // maxin NFT variables
  const [ownedMaxies, setOwnedMaxies] = useState()
  const [selectedMaxie, setSelectedMaxie] = useState()
  const [imageArray, setImageArray] = useState()
  const [allowed, setAllLayerImages] = useState()

  // boolean variables
  const [readyToUpgrade, setReadyToUpgrade] = useState(false)
  const [showNFTPopup, setShowNFTPopup] = useState(false)
  const [loadingNewMaxie, setLoadingNewMaxie] = useState(false)
  const [burnTrait, setBurnTrait] = useState(true)

  // set public key & wallet type
  useEffect(() => {
    // console.log('runs first')
    if (props.defaultAccount.address){
      if (!walletAddress){
        setWalletAddress(props.defaultAccount.address)
      }
      else {
        if (walletAddress !== props.defaultAccount.address){
          setWalletAddress(props.defaultAccount.address)
        }
      }
      // setprops.defaultAccount.address(props.defaultAccount.address)
      setWalletType(props.defaultAccount.connector.walletConnector.name)
    }
    else{
      setWalletAddress()
      setWalletType()
    }
  }, [props.defaultAccount.address])

  useEffect(() => {
    // console.log('runs second')
    if (walletAddress){
      // console.log(walletAddress)
      const address = walletAddress

      const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BS_KEY}`;
      const url = `${baseURL}/getNFTs/?owner=${address}&contractAddresses%5B%5D=0xd51b6113995a1d30125F60a542b2aE0bCE678715&orderBy=transferTime`;

      const config = {
        method: 'get',
        url: url
      };

      // Make the request and print the formatted response:
      axios(config)
        .then(function(response) {
          // console.log('maxies', response)
          const maxies = response.data.ownedNfts.filter((nft) => nft.contract.address.toLowerCase() === "0xd51b6113995a1d30125F60a542b2aE0bCE678715".toLowerCase())
          setOwnedMaxies(maxies)
        })
        .catch(error => console.log('error', error));
    }
    else {
      // setOwnedTraits()
      // setUserTraitCategories()
      setOwnedMaxies()
    }

  }, [walletAddress]);

  useEffect(() => {
    // console.log('runs third')
    if (walletAddress){
      // console.log(walletAddress)
      const address = walletAddress

      const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BS_KEY}`;
      const url = `${baseURL}/getNFTs/?owner=${address}&contractAddresses%5B%5D=0x0dB6aD9ce8f2299ef23E06AB55A6E126878855B3&contractAddresses%5B%5D=0x91c1953a6e9052583bd9954cde343a727eb38979&orderBy=transferTime`;

      const config = {
        method: 'get',
        url: url
      };

      // Make the request and print the formatted response:
      axios(config)
        .then(function(response) {
          // console.log('traits', response)
          // const traits = response.data.ownedNfts
          const traits = response.data.ownedNfts.filter((nft) => nft.contract.address.toLowerCase() === "0x0dB6aD9ce8f2299ef23E06AB55A6E126878855B3".toLowerCase() || nft.contract.address === "0x91C1953A6e9052583Bd9954cdE343a727eb38979".toLowerCase());

          let categories = ['All Traits']
          if (traits){
            for (const trait of traits){
              // const response = await axios.get(trait.tokenUri.raw + '?' + new Date().getTime());
              // if (!categories.includes(response.data.attributes[0]['value'])){
              //   categories.push(response.data.attributes[0]['value'])
              // }
              if (!categories.includes(trait.metadata.attributes[0]['value'])){
                categories.push(trait.metadata.attributes[0]['value'])
              }
            }
          }

          setOwnedTraits(traits)
          setUserTraitCategories(categories)
        })
        .catch(error => console.log('error', error));
    }
    else {
      setOwnedTraits()
      setUserTraitCategories()
    }

  }, [walletAddress]);

  // check if trait is eligible to customize and ready upgrade if so.
  const getApprovedMaxie = useCallback(async (traitToBurn) => {
    // setLoadingNewMaxie(true)
    let newTrait = {}
    let currTrait = {}
    // console.log(selectedMaxie)
    const response = await axios.get(selectedMaxie.tokenUri.raw + `?${new Date().getTime()}`);
    // console.log(response)
    response.data.attributes.map((trait) => {
        currTrait[trait['trait_type']] = trait['value'];
    });

    if (traitToBurn.metadata.attributes[0]["value"] === 'Saiyan Pepe'){
      newTrait['Head'] = 'saiyan'
    }
    else if (traitToBurn.metadata.attributes[0]["value"] === '#WAMO'){
      newTrait['Skin'] = '#wamo'
    }
    else if (traitToBurn.metadata.attributes[0]["value"] === 'Finance Bro'){
      newTrait['Clothes'] = 'finance bro'
    }
    else if (traitToBurn.metadata.attributes[0]["value"] === 'Warrior Spear'){
      newTrait['Item'] = 'warrior spear'
    }
    else if (traitToBurn.metadata.attributes[0]["value"] === 'Maxin Athletics'){
      newTrait['Clothes'] = 'maxin athletics'
    }
    else if (traitToBurn.metadata.attributes[0]["value"] === 'White Party Sunglasses'){
      newTrait['Accessory'] = 'white party glasses'
    }
    else if (traitToBurn.metadata.attributes[0]["value"] === 'Hot Head'){
      newTrait['Head'] = 'hot head'
    }
    else if (traitToBurn.metadata.attributes[1]["value"]){
      newTrait[traitToBurn.metadata.attributes[0]["value"]] = traitToBurn.metadata.attributes[1]["value"].toLowerCase()
    }

    var data = JSON.stringify({
      "action": "getApprovedMaxie",
      "currTraits": currTrait,
      "newTrait": newTrait
    });
    // console.log(data)
    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        if (response.data.status === 200){
          // console.log(response.data.imageArray)
          setImageArray(response.data.imageArray)
          setSelectedTrait(traitToBurn)
          setBurnTrait(response.data.isBurn)
        }
        else {
          props.setPopup(true)
          props.setPopupState('inelegibleCustomization')
        }
      })
      .catch(function (error) {
      // console.log(error);
    });
  }, [selectedMaxie])

  // this is just the render aspect, not the logic for creation of the image
  const renderMaxie = useCallback(() => {
    if (selectedMaxie && selectedTrait){
      return (
        <div className={`w-full h-full ${!selectedMaxie ? "bg-gray" : ""} aspect-square absolute top-0 left-0 rounded-xl shadow-border flex justify-center items-center overflow-hidden`}>
          {
            selectedMaxie ?
            <>
            <FontAwesomeIcon onClick= {() => setShowNFTPopup(true)} icon={faSyncAlt} className="absolute top-5 left-5 text-white w-[10%] h-[10%] z-10 hover:text-primary-red"/>
            {
              loadingNewMaxie ?
              <img
                className="h-[10%] w-[10%] animate-spin z-10"
                src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
                alt="Loading"
              />
              :
              ""
            }
            {
              selectedTrait ?
              <button
                onClick={() => upgradeMaxie()}
                className="absolute top-5 right-5 w-[50%] h-[10%] xl:w-[30%] xl:h-[10%] bg-gray rounded-xl border-2 shadow-border flex justify-center items-center font-bold text-white z-10 hover:bg-primary-red hover:bg-opacity-20 hover:text-primary-red"
              > UPGRADE MAXIE</button>
              :
              ""
            }
            {imageArray?.map((image, index) => (
                <img
                  key={index}
                  className={`absolute bottom-0 w-full h-full object-contain`}
                  src={image}
                  onClick= {() => setShowNFTPopup(true)}
                />
              ))}
            </>
            :
            <img onClick= {() => setShowNFTPopup(true)} class="transition-opacity hover:opacity-75 cursor-pointer" src={EmptyMaxie}/>
          }
        </div>
      )
    }
    return (
      <div className={`w-full h-full ${!selectedMaxie ? "bg-gray" : ""} aspect-square absolute top-0 left-0 rounded-xl shadow-border flex justify-center items-center overflow-hidden object-cover`}>
        {
          selectedMaxie ?
          <>
          <FontAwesomeIcon
            onClick= {() => setShowNFTPopup(true)}
            icon={faSyncAlt}
            className="absolute top-5 left-5 text-white w-[10%] h-[10%] z-10 hover:text-primary-red"
          />
          {
            loadingNewMaxie ?
            <img
              className="h-[10%] w-[10%] animate-spin z-10"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            :
            ""
          }
          <img
            className={`absolute bottom-0 w-full h-full object-contain`}
            src={`${
              selectedMaxie.media[0].raw
            }?${new Date().getTime()}`}
            onClick= {() => setShowNFTPopup(true)}
          />
          </>
          :
          <img onClick= {() => setShowNFTPopup(true)} class="transition-opacity hover:opacity-75 cursor-pointer" src={EmptyMaxie}/>
        }
      </div>
    )
  }, [imageArray, selectedMaxie, selectedTrait]);

  // burn trait and send in NFT Token ID to trigger upgrade event.
  const upgradeMaxie = useCallback(async () => {
      try {
        props.setPopup(true)
        props.setPopupState('transferApprovalInProgress')
        const nftID = selectedMaxie.id.tokenId
        const traitID = selectedTrait.id.tokenId

        let walletProvider = window.ethereum
        if (walletType === "PhantomEvm"){
          walletProvider = window.phantom.ethereum
        }

        const clientProvider = new ethers.providers.Web3Provider(walletProvider);
        let nftContractABI = [
          {
           "inputs":[
              {
                 "internalType":"address",
                 "name":"to",
                 "type":"address"
              },
              {
                 "internalType":"uint256",
                 "name":"tokenId",
                 "type":"uint256"
              }
           ],
           "name":"approve",
           "outputs":[

           ],
           "stateMutability":"nonpayable",
           "type":"function"
          },
          {
      		"inputs": [
      			{
      				"internalType": "address",
      				"name": "operator",
      				"type": "address"
      			},
      			{
      				"internalType": "bool",
      				"name": "approved",
      				"type": "bool"
      			}
      		],
      		"name": "setApprovalForAll",
      		"outputs": [],
      		"stateMutability": "nonpayable",
      		"type": "function"
      	}
        ]
        const nftContract = new ethers.Contract(selectedTrait.contract.address, nftContractABI, clientProvider);
        const approveTransaction = await nftContract.connect(clientProvider.getSigner()).setApprovalForAll(customizeAddress, 1)
        approveTransaction.wait()
        .then( async (receipt) => {
          props.setPopupState('upgradeInProgress')
          console.log('Approval successful!');
          console.log('Transaction receipt:', receipt);
          const value = ethers.utils.parseUnits('0', "ether")
          const purchaseContract = new ethers.Contract(customizeAddress, CustomizeABI, clientProvider);
          const customize = await purchaseContract.connect(clientProvider.getSigner()).customize(
            nftID,
            selectedTrait.contract.address,
            traitID,
            false,
            {
              value: value
            }
          )
          customize.wait()
          .then(async (receipt) => {
            var data = JSON.stringify({
              "action": "saveCustomization",
              "walletAddress": walletAddress,
              "maxieID": parseInt(nftID, 16),
              "traitID": traitID,
              "transactionID": receipt.transactionHash
            });
            // console.log(data)
            var configOne = {
              method: 'post',
              url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
              headers: {
                'x-api-key': process.env.GATEWAY_KEY,
                'Content-Type': 'application/json'
              },
              data: data
            };

            axios(configOne)
            .then(function (response) {
              console.log(response)
            })
            .catch(function (error) {
                // console.log(error);
              });
            setSelectedTrait()
            const address = walletAddress

            const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BS_KEY}`;
            const url = `${baseURL}/getNFTs/?owner=${address}&contractAddresses%5B%5D=0x0dB6aD9ce8f2299ef23E06AB55A6E126878855B3&contractAddresses%5B%5D=0x91c1953a6e9052583bd9954cde343a727eb38979&orderBy=transferTime`;

            const config = {
              method: 'get',
              url: url,
            };

            // Make the request and print the formatted response:
            axios(config)
              .then(function(response) {
                // console.log(response)
                // const traits = response.data.ownedNfts
                // const maxies = response.data.ownedNfts.filter((nft) => nft.contract.address.toLowerCase() === "0x74aee8a45121cbfb984abc659c9d1d079374e6ad".toLowerCase())
                const traits = response.data.ownedNfts.filter((nft) => nft.contract.address.toLowerCase() === "0x0dB6aD9ce8f2299ef23E06AB55A6E126878855B3".toLowerCase() || nft.contract.address === "0x91C1953A6e9052583Bd9954cdE343a727eb38979".toLowerCase());
                let categories = ['All Traits']
                if (traits){
                  for (const trait of traits){
                    if (!categories.includes(trait.metadata.attributes[0]['value'])){
                      categories.push(trait.metadata.attributes[0]['value'])
                    }
                  }
                }
                setOwnedTraits(traits)
                setUserTraitCategories(categories)
                props.setPopupState('upgradeApproved')
              })
              .catch(error => console.log('error', error));
            console.log(receipt)
          })
          .catch((error) => {
            console.error('Approval failed:', error);
            props.setPopupState('transactionError')
          });

        })
        .catch((error) => {
          console.error('Approval failed:', error);
          props.setPopupState('transactionError')
        });
      } catch (error){
        console.log(error)
        props.setPopupState('transactionError')
      }
  }, [walletAddress, walletType, selectedMaxie, selectedTrait, burnTrait])

  const chooseNFT = (nft) => {
    // console.log(nft)
    setSelectedTrait()
    setSelectedMaxie(nft)
    setShowNFTPopup(false)
  }

  // Render all NFTs owned by wallet
  const renderNFTs = () => {
    // console.log('got here')

    return(
        <div className="bg-dark-gray mx-auto p-4 max-w-screen-lg">
          <button
            className="w-full text-end font-title-regular top-8 z-10 text-lg text-white uppercase hover:text-primary-yellow"
            onClick={() => setShowNFTPopup(false)}
          >
            &#9664; Back
          </button>
          <div className="mt-4 overflow-y-scroll">

            {ownedMaxies?.length ? (
              <div className="w-full grid grid-col md:grid-cols-3 lg:grid-cols-4 gap-5 xl:gap-12">
                {ownedMaxies.map((nft) => {
                    // console.log(nft)
                    return (
                      <div class="flex flex-col items-center justify-center hover:opacity-50">
                        <img
                          onClick={() => {
                            chooseNFT(nft)
                          }}
                          alt="NFTs"
                          src={`${
                            nft.media[0].raw
                          }?${new Date().getTime()}`}
                          className="cursor-pointer mb-4 object-cover w-full rounded-t-2xl"
                        />
                        <p className="w-full text-start p-2 text-gray-deep bg-[#EAE9E8] rounded-b-2xl font-text font-bold text-full -mt-4 truncate">
                          {nft.metadata.name}
                        </p>
                      </div>
                    );
                })}
              </div>
            ) : (
              <p className="font-title-bold text-white text-[24px] w-full text-center mb-5 relative">
                You have no Maxies available to customize in your wallet. <br></br>
                Please connect your wallet and try again!
              </p>
            )}
          </div>
        </div>
    )
  };

  // owner functions
  // const addClaimTraits = useCallback(async () => {
  //   const walletPrivateKey = process.env.OWNER_WALLET
  //   const wallet = new ethers.Wallet(walletPrivateKey);
  //   const signer = wallet.connect(provider);
  //
  //   let gasPrice = await provider.getGasPrice();
  //
  //   // Increase the gas price by a certain factor to speed up transaction processing (optional)
  //   gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2
  //
  //   // Define transaction parameters
  //   const transactionParameters = {
  //       gasPrice: gasPrice,
  //       gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
  //       // ...other transaction parameters like 'to', 'value' and 'data'
  //   };
  //   const customContract = new ethers.Contract(customizeAddress, CustomizeABI, signer);
  //   const addClaim = await customContract.addClaimTrait(
  //     6,
  //     1,
  //     'maxin athletics trait',
  //     23,
  //     transactionParameters,
  //   )
  //   addClaim.wait()
  //   .then((receipt) => {
  //     console.log('Approval successful!');
  //     console.log('Transaction receipt:', receipt);
  //   })
  //   .catch((error) => {
  //     console.error('Approval failed:', error);
  //     // props.setPopupState('transactionError')
  //   });
  //
  // }, [walletAddress, walletType, selectedTrait])
  const removeTrait = useCallback(async () => {
    const walletPrivateKey = process.env.OWNER_WALLET
    const wallet = new ethers.Wallet(walletPrivateKey);
    const signer = wallet.connect(provider);

    let gasPrice = await provider.getGasPrice();

    // Increase the gas price by a certain factor to speed up transaction processing (optional)
    gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2

    // Define transaction parameters
    const transactionParameters = {
        gasPrice: gasPrice,
        gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
        // ...other transaction parameters like 'to', 'value' and 'data'
    };
    const customContract = new ethers.Contract(customizeAddress, CustomizeABI, signer);
    const sendTrait = await customContract.ownerRemoveTrait(
      '0x72a8d8d7adaf836361d6ccde356b48f0aba8de16',
      29,
      transactionParameters,
    )
    sendTrait.wait()
    .then((receipt) => {
      console.log('Approval successful!');
      console.log('Transaction receipt:', receipt);
    })
    .catch((error) => {
      console.error('Approval failed:', error);
      // props.setPopupState('transactionError')
    });

  }, [walletAddress, walletType, selectedTrait])
  // const getClaims = useCallback(async () => {
  //   const claimName = await customizeProvider.getClaimCategoryID(2)
  //   console.log(claimName)
  // }, [])
  // const getTest = async () => {
  //   const customizations = await customizeProvider.getMaxinCustomizations(5, 1)
  //   console.log(customizations)
  // }
  // const test = async (tokenId) => {
  //   const { Alchemy, Network } = require("alchemy-sdk");
  //
  //   // Configures the Alchemy SDK
  //   const config = {
  //       apiKey: process.env.ALCHEMY_BS_KEY, // Replace with your API key
  //       network: Network.MATIC_MAINNET, // Replace with your network
  //   };
  //
  //   // Creates an Alchemy object instance with the config to use for making requests
  //   const alchemy = new Alchemy(config);
  //   const contractAddress = "0xd51b6113995a1d30125F60a542b2aE0bCE678715";
  //   //Call the method
  //   let response = await alchemy.nft.refreshNftMetadata(contractAddress, tokenId)
  //
  //   //Logging the response to the console
  //   console.log(tokenId, response)
  //
  // }
  // const sleep = (milliseconds) => {
  //   return new Promise((resolve) => setTimeout(resolve, milliseconds));
  // };
  // const runMultipleTests = async () => {
  //   for (let i = 650; i < 1000; i++) {
  //     await test(i);
  //     if ((i - 99) % 50 === 0) { // i - 99 because we started at i = 100
  //       console.log(`Pausing for 1 minute after reaching token ID ${i}`);
  //       await sleep(60000); // Pause for 1 minute (60,000 milliseconds)
  //     }
  //   }
  // };

  return (
    <div className="w-full h-full flex-grow flex-col flex px-5 xl:px-10 py-5 gap-4">
      {/*<button onClick = {() => removeTrait()}> add claim traits </button>*/}
      <div class="flex flex-col font-bold text-dark-gray items-start justify-start">
        <div class="flex flex-col items-center justify-start">
          <h1 className="text-[7vw] sm:text-[4vw]">CUSTOMIZE YOUR MAXIE</h1>
          <p className="text-[3vw] sm:text-[1.5vw] -mt-2">click below to select your maxie and begin customizing</p>
        </div>
      </div>
      {
        showNFTPopup ?
        renderNFTs()
        :
        <div className="grid  w-full h-full md:grid-cols-[1.25fr,1fr] gap-8 xl:gap-10 2xl:gap-16">
          <div className = "flex flex-col justify-start items-center flex-grow">
            <div className="w-[98.8%] sm:w-10/12 mx-auto h-0 pb-[100%] sm:pb-[83.33%] relative">
              {
                renderMaxie()
              }
            </div>
          </div>
          <div>
            {userTraitCategories?.length > 0 && (
              <DropDown
                value={selectedTraitCategory}
                values={userTraitCategories}
                selectValue={setSelectedTraitCategory}
              />
            )}
            {!ownedTraits?.length ? (
              <>
              <div className="max-w-[500px] mx-auto my-32">
                <p className="font-title-bold text-primary-red text-[24px] text-center mb-5 relative">
                  It seems you have no traits for this given collection. Try a different
                  collection or connect a different wallet.
                </p>
              </div>
              </>
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-5 max-h-[67vh] overflow-y-scroll">
                {ownedTraits
                  .filter((trait) =>
                    selectedTraitCategory !== "All Traits"
                      ? trait.metadata.attributes[0]['value'] === selectedTraitCategory
                      : true
                  )
                  .map((trait) => {
                    // console.log(trait)
                    const isSelected = selectedTrait === trait;
                    return (
                      <>
                        {isSelected ? (
                          <img
                            src={trait.metadata.image}
                            className={`w-full cursor-pointer border border-primary-red p-1 bg-white`}
                            style={{
                              marginTop: 10,
                              borderRadius: 10
                            }}
                          />
                        ) : (
                          <img
                            src={trait.metadata.image}
                            onClick={() => {
                              if (selectedMaxie) {
                                getApprovedMaxie(trait);
                              }
                            }}
                            className={`w-full cursor-pointer border border-dark-gray p-1 bg-white`}
                            style={{ marginTop: 10, borderRadius: 10 }}
                          />
                        )}
                      </>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );

}


export default Customize;
