import React, { useCallback, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import {ethers} from 'ethers';
import { Checkmark } from 'react-checkmark';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreateRaffles = (props) => {
  // contract variables
  const [publicKeyAddress, setPublicKeyAddress] = useState()
  const [walletType, setWalletType] = useState()

  // boolean variables
  const [reloadNFTs, setReloadNFTs] = useState(false)
  const [showNFTPopup, setShowNFTPopup] = useState(false)

  // user variables
  const [walletNFTs, setWalletNFTs] = useState()
  const [selectedNFT, setSelectedNFT] = useState()
  // const [verifiedCollections, setVerifiedCollections] = useState([])

  // raffle variables
  const [currencyOptions, setCurrencyOptions] = useState({})
  const [ticketPrice, setTicketPrice] = useState(0)
  const [ticketSupply, setTicketSupply] = useState(0)
  const [totalTicketValue, setTotalTicketValue] = useState(0)
  const [raffleEndDate, setRaffleEndDate] = useState(new Date())
  const [purchasingCurrency, setPurchasingCurrency] = useState('$MATIC')
  const [featuredRaffle, setFeaturedRaffle] = useState('featured')

  // not necessary but will add them so I dont break anything
  const [readyToCreate, setReadyToCreate] = useState(false)
  const [selectedNFTAddress, setSelectedNFTAddress] = useState()

  // HANDLES ALL RAFFLE INPUTS
  const onChange = (e) => {
    if (e.target.name === "ticketPrice"){
      setTicketPrice(e.target.value)
      setTotalTicketValue(e.target.value*ticketSupply)
    }
    else if (e.target.name === "ticketSupply"){
      setTicketSupply(e.target.value)
      setTotalTicketValue(e.target.value*ticketPrice)
    }
    else if (e.target.name === "featured" || e.target.name === "notFeatured"){
      setFeaturedRaffle(e.target.value)
    }
  }

  // set public key & wallet type
  useEffect(() => {
    if (props.defaultAccount.address){
      setPublicKeyAddress(props.defaultAccount.address)
      setWalletType(props.defaultAccount.connector.walletConnector.name)
    }
  }, [props.defaultAccount])

  // get all NFTs owned by the user wallet.
  useEffect(() => {
    if (publicKeyAddress){

      const address = publicKeyAddress

      const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`;
      const url = `${baseURL}/getNFTs/?owner=${address}`;

      const config = {
        method: 'get',
        url: url,
      };

      // Make the request and print the formatted response:
      axios(config)
        .then(function(response) {
          setWalletNFTs(response.data.ownedNfts)
        })
        .catch(error => console.log('error', error));
    }

  }, [publicKeyAddress, reloadNFTs]);

  // Get all currency options & verified collections
  useEffect(() => {
    var data = JSON.stringify({
      action: "getCurrencyOptionsAndVerifiedCollections",
    });
    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyraffles",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response)
        let currencyOptions = {}
        // let tempVerifiedCollections = []
        response.data.verifiedTokens.forEach((token) => {
          currencyOptions[token[1]] = token[2]
        })
        // response.data.verifiedCollections.map((nft) => {
        //   tempVerifiedCollections.push(nft[2])
        // })
        setCurrencyOptions(currencyOptions)
        // setVerifiedCollections(tempVerifiedCollections)
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [])

  // reload the get all NFTs owned by wallet function.
  useCallback(() => {
    setReloadNFTs(true);
  }, []);

  // Render all NFTs owned by wallet
  const renderNFTs = () => {
    return(
        <div className="container bg-dark-gray mx-auto p-4 max-w-screen-lg">
          <button
            className="w-full text-end font-title-regular top-8 z-10 text-lg text-white uppercase hover:text-primary-yellow"
            onClick={() => setShowNFTPopup(false)}
          >
            &#9664; Back
          </button>
          <div className="mt-4 flex-grow overflow-y-auto">

            {walletNFTs?.length ? (
              <div className="w-full grid grid-col md:grid-cols-3 lg:grid-cols-4 gap-5 xl:gap-12">
                {walletNFTs.map((nft) => {
                    return (
                      <div class="flex flex-col items-center justify-center bg-white p-3 rounded-2xl border-4 border-transparent hover:border-4 hover:border-primary-yellow">
                        <img
                          onClick={() => {
                            chooseNFT(nft)
                          }}
                          alt="NFTs"
                          src={`${
                            nft.media[0].gateway
                          }?${new Date().getTime()}`}
                          className="cursor-pointer border-2 border-dark-gray mb-4 object-cover bg-[#EAE9E8]"
                          style={{ marginTop: 10, borderRadius: 10, height: '200px', width: '200px'}}
                        />
                        <div className = "flex items-center justify-start w-full">
                            <p className="text-start py-2 text-gray-deep font-text font-bold text-full truncate">
                              {nft.contractMetadata.name}
                            </p>
                            <div className="ml-2">
                              {
                                props.verifiedCollections.includes(nft.contract.address.toLowerCase()) ?
                                <Checkmark size="12px" />
                                :
                                <FontAwesomeIcon icon={faExclamationTriangle} size="12x" color="yellow"/>
                              }
                            </div>
                        </div>
                        <p className="w-full text-start py-2 text-gray-deep  font-text font-bold text-full -mt-4 truncate">
                          {nft.metadata.name}
                        </p>
                      </div>
                    );
                })}
              </div>
            ) : (
              <p className="font-title-bold text-white text-[24px] text-center mb-5 relative">
                You have no NFTs available to raffle in your wallet. <br></br>
                Please connect your wallet and try again!
              </p>
            )}
          </div>
        </div>
    )
  };

  // Render create raffle
  const renderCreateNFT = () => {
    return(
        <div className="container bg-dark-gray mx-auto p-12 max-w-screen-lg">
          <div className="w-full grid sm:grid-cols-2 gap-5 xl:gap-12">
              <div class="">
                {!selectedNFT ? (
                <div className="flex-col w-full h-full min-h-[300px] cursor-pointer border-2 border-white rounded-2xl flex items-center justify-center">
                  <svg class="animate-bounce text-6xl rounded-full w-20 h-20 flex items-center justify-center border-2 border-white rounded-full"
                    onClick={() => {
                      setShowNFTPopup(true)
                    }}
                  >
                  </svg>
                  <p className="text-center py-2 text-white font-text font-bold text-[12px] xl:text-2xl uppercase">
                    Select NFT to Raffle
                  </p>
                </div>
                ) : (
                <>
                <div className="relative group w-full inline-block">
                  <img
                    src={`${
                      selectedNFT.media[0].gateway
                    }?${new Date().getTime()}`}
                    alt="selectedNFT"
                    className="w-full cursor-pointer border-2 border-white rounded-2xl bg-[#EAE9E8]"
                  />
                  <div
                    onClick={() => {
                      setShowNFTPopup(true)
                    }}
                  className="absolute inset-0 bg-black rounded-2xl bg-opacity-50 flex justify-center items-center flex-col opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    <div className="w-12 h-12 rounded-full border-2 border-white flex justify-center items-center text-2xl text-white font-bold mb-4">
                      +
                    </div>
                    <p className="text-center py-2 text-white font-text font-bold text-[12px] xl:text-2xl uppercase">Choose a different NFT</p>
                  </div>
                </div>
                <p className="text-center py-2 text-white font-text font-bold text-[12px] xl:text-2xl">
                  {selectedNFT.metadata.name}
                </p>
                </>
                )}
              </div>
              <div class="justify-center items-center">
                <div className='grid grid-rows-2 grid-cols-2 items-center text-center justify-center'>
                  <div className="flex justify-center items-center py-2">
                    <h3 className='bg-gray font-gilroy-bold text-sm md:text-sm lg:text-lg text-primary-yellow w-[80%]'>Ticket Price</h3>
                  </div>
                  <div className="flex justify-center items-center py-2">
                    <h3 className='bg-gray font-gilroy-bold text-sm md:text-sm lg:text-lg text-primary-yellow w-[80%]'>Ticket Supply</h3>
                  </div>
                  <div className="flex justify-center items-center">
                    <input name="ticketPrice" type="number" placeholder="Set Price Per Ticket" maxLength={26} onChange={e => {onChange(e)}} className="w-[80%] items-center text-primary-yellow bg-dark-gray font-bold border-2 border-white p-[1px] focus:outline-0" autoComplete="off"/>
                  </div>
                  <div className="flex justify-center items-center">
                    <input name="ticketSupply" type="number" placeholder="Set Quantity of Tickets" maxLength={26} onChange={e => {onChange(e)}} className="w-[80%] items-center text-primary-yellow bg-dark-gray font-bold border-2 border-white p-[1px] focus:outline-0" autoComplete="off"/>
                  </div>
                </div>
                <h3 className='font-gilroy-bold text-sm text-primary-yellow ml-3 sm:ml-6 mb-5'>Total Ticket Volume: {totalTicketValue.toFixed(2)} {purchasingCurrency}</h3>
                <div className='grid grid-rows-2 grid-cols-2 items-center text-center justify-center mb-5'>
                  <div className="flex justify-center items-center py-2">
                    <h3 className='bg-gray font-gilroy-bold text-sm md:text-sm lg:text-lg text-primary-yellow w-[80%]'>Pick Currency</h3>
                  </div>
                  <div className="flex justify-center items-center py-2">
                    <h3 className='bg-gray font-gilroy-bold text-sm md:text-sm lg:text-lg text-primary-yellow w-[80%]'>Raffle End Date</h3>
                  </div>
                  <div className="flex justify-center items-center">
                    <select className="bg-dark-gray border-2 border-white font-bold text-[12px] p-[1px] cursor-pointer text-primary-yellow w-[80%] h-8" value={purchasingCurrency} onChange={(e) => setPurchasingCurrency(e.target.value)}>
                        {Object.keys(currencyOptions).map((currency) => (
                            <option value={currency} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{currency}</option>
                        ))}
                    </select>
                  </div>
                  <div className="flex justify-center items-center">
                    <DatePicker
                      selected={raffleEndDate}
                      onChange={(dateTime) => setRaffleEndDate(dateTime)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="w-[80%] text-primary-yellow bg-dark-gray font-bold border-2 border-white p-[1px] focus:outline-0 date-picker-custom"
                    />
                  </div>
                </div>
                <div className='text-start mb-10'>
                  <h3 className='font-gilroy-bold text-sm text-primary-yellow ml-3 sm:ml-6'>Create a Featured Raffle to get higher visibility amongst competitors for an additional 2 $MATIC payment. <br></br><span className="text-xs text-gray">All partnered project NFTs will be automatically featured.</span></h3>
                  <div className='flex justify-between w-[80%] sm:w-[60%] m-auto '>
                      <div className='text-center'>
                          <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={featuredRaffle === 'featured'} type="radio" value="featured" name="featured" />
                          <p className='text-sm sm:text-md text-primary-yellow font-gilroy-bold'>Featured</p>
                      </div>
                      <div className='text-center'>
                          <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={featuredRaffle === 'notFeatured'} type="radio" value="notFeatured" name="notFeatured" />
                          <p className='text-sm sm:text-md text-primary-yellow font-gilroy-bold'>Non-Featured</p>
                      </div>
                  </div>
                </div>
                <div className='text-center'>
                  <button disabled = {!ticketPrice || !ticketSupply || !raffleEndDate || !purchasingCurrency || !selectedNFT} onClick={() => {startRaffle()}} className="bg-red-deep border border-white text-white py-2 w-36 font-bold text-lg text-center rounded-md disabled:opacity-50">
                    Start Raffle
                  </button>
                </div>
              </div>
          </div>
        </div>
    )
  }

  // Pick NFT to raffle and approve contracts ability to transfer NFT
  const chooseNFT = async (nft) => {
    props.setPopup(true)
    props.setPopupState("transferApprovalInProgress")

    // check wallet type for injected provider arg
    let walletProvider = window.ethereum
    if (walletType === "PhantomEvm"){
      walletProvider = window.phantom.ethereum
    }

    const clientProvider = new ethers.providers.Web3Provider(walletProvider);
    let nftContractABI = [
      {
       "inputs":[
          {
             "internalType":"address",
             "name":"to",
             "type":"address"
          },
          {
             "internalType":"uint256",
             "name":"tokenId",
             "type":"uint256"
          }
       ],
       "name":"approve",
       "outputs":[

       ],
       "stateMutability":"nonpayable",
       "type":"function"
      },
      {
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "operator",
  				"type": "address"
  			},
  			{
  				"internalType": "bool",
  				"name": "approved",
  				"type": "bool"
  			}
  		],
  		"name": "setApprovalForAll",
  		"outputs": [],
  		"stateMutability": "nonpayable",
  		"type": "function"
  	}
    ]
    const nftContract = new ethers.Contract(nft.contract.address, nftContractABI, clientProvider);
    try {
      if (nft.id.tokenMetadata.tokenType === "ERC721"){
        const approveTransaction = await nftContract.connect(clientProvider.getSigner()).approve(props.raffleContractAddress, nft.id.tokenId)
        approveTransaction.wait()
        .then((receipt) => {
          console.log('Approval successful!');
          console.log('Transaction receipt:', receipt);
          setSelectedNFT(nft);
          setShowNFTPopup(false)
          props.setPopupState('transferApproved')
        })
        .catch((error) => {
          console.error('Approval failed:', error);
          props.setPopupState('transactionError')
        });
      }
      else if (nft.id.tokenMetadata.tokenType === "ERC1155"){
        const approveTransaction = await nftContract.connect(clientProvider.getSigner()).setApprovalForAll(props.raffleContractAddress, 1)
        approveTransaction.wait()
        .then((receipt) => {
          console.log('Approval successful!');
          console.log('Transaction receipt:', receipt);
          setSelectedNFT(nft);
          setShowNFTPopup(false)
          props.setPopupState('transferApproved')
        })
        .catch((error) => {
          console.error('Approval failed:', error);
          props.setPopupState('transactionError')
        });
      }
    } catch {
      props.setPopupState('transactionError')
    }
  };

  // Create Raffle Functionality
  const startRaffle = useCallback(async () => {
    if (!props.verifiedCollections.includes(selectedNFT.contract.address.toLowerCase()) && featuredRaffle === 'featured'){
      props.setPopup(true)
      props.setPopupState("cantBeFeatured")
    }
    else{
      props.setPopup(true)
      props.setPopupState("raffleCreationInProgress")

      // calculate raffle duration in integer
      let endDate = Math.floor(raffleEndDate);
      let startDate = Math.floor(Date.now());
      let duration = endDate - startDate;

      //instantiate payAmount value in MATIC
      let value = ethers.utils.parseUnits("0.2", "ether")
      let featureRaffleBool = 0
      if (featuredRaffle === 'featured'){
        value = ethers.utils.parseUnits("2", "ether")
        featureRaffleBool = 1
      }

      // instantiate ticket price in ether units
      const purchaseValue = ethers.utils.parseUnits(ticketPrice, "ether")

      // instantiate raffle type based on token type
      let tokenType = 0
      if (purchasingCurrency === "$PolyDoge"){
        tokenType = 1
      }
      else if (purchasingCurrency === "$SPEPE"){
        tokenType = 2
      }

      // check wallet type for injected provider arg
      let walletProvider = window.ethereum
      if (walletType === "PhantomEvm"){
        walletProvider = window.phantom.ethereum
      }

      // instantiate raffle contract
      const clientProvider = new ethers.providers.Web3Provider(walletProvider);
      const raffleContract = new ethers.Contract(props.raffleContractAddress, props.raffleContractABI, clientProvider);
      try{
        if (selectedNFT.id.tokenMetadata.tokenType === "ERC721"){
            const createRaffle = await raffleContract.connect(clientProvider.getSigner()).createRaffle721(
            selectedNFT.contract.address,
            selectedNFT.id.tokenId,
            ticketSupply,
            purchaseValue,
            startDate,
            duration,
            tokenType,
            0,
            {
              value: value
            }
          )
            createRaffle.wait()
            .then((receipt) => {
              console.log('Approval successful!');
              console.log('Transaction receipt:', receipt);
              props.setIsLoading(true)
              props.setUpdatedRaffles(prevState => !prevState);
              props.setPopupState("raffleCreated")
              props.navigate(`/raffles/profile/user`)

            })
            .catch((error) => {
            console.error('Approval failed:', error);
            props.setPopupState('transactionError')
          });
        }
        else if (selectedNFT.id.tokenMetadata.tokenType === "ERC1155"){
            const createRaffle = await raffleContract.connect(clientProvider.getSigner()).createRaffle1155(
            selectedNFT.contract.address,
            selectedNFT.id.tokenId,
            ticketSupply,
            purchaseValue,
            startDate,
            duration,
            tokenType,
            1,
            {
              value: value
            }
          )
            createRaffle.wait()
            .then((receipt) => {
              console.log('Approval successful!');
              console.log('Transaction receipt:', receipt);
              props.setIsLoading(true)
              props.setUpdatedRaffles(prevState => !prevState);
              props.setPopupState("raffleCreated")
              props.navigate(`/raffles/profile/user`)

            })
            .catch((error) => {
            console.error('Approval failed:', error);
            props.setPopupState('transactionError')
          });
        }
      } catch{
        props.setPopupState("transactionError")
      }
    }

  }, [ticketPrice, ticketSupply, raffleEndDate, purchasingCurrency, selectedNFT, featuredRaffle]);

  const distributeRaffle = (async () => {
    const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`);

    let gasPrice = await provider.getGasPrice();

    // Increase the gas price by a certain factor to speed up transaction processing (optional)
    gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2

    // Define transaction parameters
    const transactionParameters = {
        gasPrice: gasPrice,
        gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
        // ...other transaction parameters like 'to', 'value' and 'data'
    };
    let specialValue = ethers.utils.parseUnits("0.2", "ether")
    let nonSpecialValue = ethers.utils.parseUnits("0.2", "ether")

    const walletPrivateKey = process.env.OWNER_WALLET;
    const wallet = new ethers.Wallet(walletPrivateKey);
    const signer = wallet.connect(provider);

    const raffleContract = new ethers.Contract(props.raffleContractAddress, props.raffleContractABI, signer);

    raffleContract.timeExpired(
      84,
      transactionParameters
    )
    .then((receipt) => {
      console.log('approved transaction', receipt)
    }).catch((error) => {
      console.error('cancel failed:', error);
    })

  })

  return (
    <div className="grid   gap-8  relative w-full">
      {/*<button onClick={distributeRaffle}>modifyReward</button>*/}
      <p className="bg-[#A7A2A6] sm:w-[30%] text-lg md:text-3xl font-bold text-bold text-dark-gray text-center">
        CREATE RAFFLE
      </p>
      { showNFTPopup ?
          renderNFTs() :
          renderCreateNFT()
      }
    </div>
  )
}

export default CreateRaffles;
