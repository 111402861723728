import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {ethers} from 'ethers';
import { DynamicWidget, DynamicConnectButton } from '@dynamic-labs/sdk-react';
import { faQuestionCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-elastic-carousel';
import PointsBar from "./pointsBar.js";
import '../../App.css'
const { Network, Alchemy } = require("alchemy-sdk");
var qs = require('qs');

const Profile = (props) => {
    // user variables
    const [walletType, setWalletType] = useState()
    const [walletAddress, setWalletAddress] = useState()
    const [notFetchedUser, setNotFetchedUser] = useState(true)
    const [walletNFTs, setWalletNFTs] = useState([])
    const [maxieStats, setMaxieStats] = useState({'level': 'N/A', 'totalXP': 'N/A', 'totalMP': 'N/A'})

    // social variables
    const [connectedTwitter, setConnectedTwitter] = useState(0);
    const [twitterDict, setTwitterDict] = useState();
    const [connectedDiscord, setConnectedDiscord] = useState(0);
    const [discordDict, setDiscordDict] = useState();

    // maxin/trait NFT variables
    const [ownedMaxies, setOwnedMaxies] = useState()
    const [ownedTraits, setOwnedTraits] = useState()
    const [itemsToShow, setItemsToShow] = useState()
    const [portalView, setPortalView] = useState(0)
    const [loadingNFTs, setLoadingNFTs] = useState(true)

    // boolean variables
    // const [socialsLoading, setSocialsLoading] = useState(0);
    // const [profileIsLoading, setProfileIsLoading] = useState(true);

    // set public key & wallet type
    useEffect(() => {
      if (props.defaultAccount.address){
        if (!walletAddress){
          setWalletAddress(props.defaultAccount.address)
        }
        else {
          if (walletAddress !== props.defaultAccount.address){
            setNotFetchedUser(true)
            setWalletAddress(props.defaultAccount.address)
          }
        }
        // setprops.defaultAccount.address(props.defaultAccount.address)
        // setProfileIsLoading(false)
        setWalletType(props.defaultAccount.connector.walletConnector.name)
      }
      else{
        // setProfileIsLoading(false)
        setWalletAddress()
        setWalletType()
        setNotFetchedUser(true)
        setDiscordDict()
        setConnectedDiscord(0)
        setTwitterDict()
        setConnectedTwitter(0)
      }
    }, [props.defaultAccount.address])

    // fetch user data
    useEffect(() => {
      if (walletAddress){
        var data = JSON.stringify({
          "action": "getMaxieData",
          'walletAddress': walletAddress
        });
        // console.log(data)
        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
          .then(function (response) {
            // console.log(response.data)
            setNotFetchedUser(0)
            if (response.data.connectedTwitter){
              setConnectedTwitter(1)
              setTwitterDict(response.data.twitterDict)
            }
            if (response.data.connectedDiscord){
              setConnectedDiscord(1)
              setDiscordDict(response.data.discordDict)

            }
            setMaxieStats(response.data.maxieStats)
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
      // setProfileIsLoading(false)
    }, [walletAddress]);

    // get maxies in wallet
    useEffect(() => {
      if (walletAddress){
        // console.log(walletAddress)
        const address = walletAddress

        const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BS_KEY}`;
        const url = `${baseURL}/getNFTs/?owner=${address}&contractAddresses%5B%5D=0xd51b6113995a1d30125F60a542b2aE0bCE678715&orderBy=transferTime`;

        const config = {
          method: 'get',
          url: url
        };

        // Make the request and print the formatted response:
        axios(config)
          .then(function(response) {
            // console.log('maxies', response)
            const maxies = response.data.ownedNfts.filter((nft) => nft.contract.address.toLowerCase() === "0xd51b6113995a1d30125F60a542b2aE0bCE678715".toLowerCase())
            setOwnedMaxies(maxies)
            setLoadingNFTs(false)
          })
          .catch(error => console.log('error', error));
      }
      else {
        setOwnedMaxies()
      }
    }, [walletAddress]);

    // get traits in wallet
    useEffect(() => {
      if (walletAddress){
        const address = walletAddress

        const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BS_KEY}`;
        const url = `${baseURL}/getNFTs/?owner=${address}&contractAddresses%5B%5D=0x91c1953a6e9052583bd9954cde343a727eb38979&orderBy=transferTime`;

        const config = {
          method: 'get',
          url: url
        };

        // Make the request and print the formatted response:
        axios(config)
          .then(function(response) {
            // console.log('traits', response)
            const traits = response.data.ownedNfts.filter((nft) => nft.contract.address.toLowerCase() === "0x91c1953a6e9052583bd9954cde343a727eb38979".toLowerCase())
            setOwnedTraits(traits)
          })
          .catch(error => console.log('error', error));
      }
      else {
        // setOwnedTraits()
        // setUserTraitCategories()
        setOwnedTraits()
      }
    }, [walletAddress]);

    // connect twitter
    const connectTwitter = (response) => {
      if (walletAddress){
        var data = JSON.stringify({
          "action": "twitterReadandWriteRedirect",
          'walletAddress': walletAddress,
          "urlTest": "https://api.maxinout.com/callback"
        });
        console.log(data)
        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
          .then(function (response) {
            console.log(response)
            // console.log(response.data.oauth_token)
            if (response.data.oauth_token){
              const redirectUrl = 'https://api.twitter.com/oauth/authenticate?oauth_token=' + response.data.oauth_token
              window.location.href = redirectUrl;
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
      else {
        console.log("connect wallet")
      }
    }
    const disconnectTwitter = () => {
      var data = JSON.stringify({
        'action': 'disconnectTwitterNew',
        'walletAddress': walletAddress
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log(response)
          if (response.data.statusCode === 200){
            setTwitterDict()
            setConnectedTwitter(0)
          }
          else {
            // notification saying failed to disconnect please try again
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    // disconnect discord
    const disconnectDiscord = () => {
      var data = JSON.stringify({
        'action': 'disconnectDiscordNew',
        'walletAddress': walletAddress
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log(response)
          if (response.data.statusCode === 200){
            setDiscordDict()
            setConnectedDiscord(0)
          }
          else {
            // notification saying failed to disconnect please try again
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    //
    const carouselRef = useRef();
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 800) { // adjust this value to your needs
          setItemsToShow(1);
        }
        else if (window.innerWidth < 1200) { // adjust this value to your needs
          setItemsToShow(2);
        }
        else {
          setItemsToShow(3);
        }
      };
      if (window.innerWidth < 600) {
          setItemsToShow(1);
        }
      else if (window.innerWidth < 1000) {
        setItemsToShow(2);
      }
      else {
        setItemsToShow(3);
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    const totalPages = Math.ceil(ownedMaxies?.length / itemsToShow)
    const totalTraitPages = Math.ceil(ownedTraits?.length / itemsToShow)
    let resetTimeout;

    const renderProfile = useCallback(() => {
      return (
        <>
          {
            walletAddress && !loadingNFTs ?
            <div class="flex w-full flex-col justify-start items-center text-center gap-4 overflow-y-scroll">
                <div class="flex flex-row w-full gap-8 items-center text-center justify-center text-full sm:justify-start sm:text-xl">
                  <button onClick={() => setPortalView(0)}  class={`rounded-full ${portalView === 0 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
                    My Maxies
                  </button>
                  <button  onClick={() => setPortalView(1)} class={`rounded-full ${portalView === 1 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
                    My Claimed Traits
                  </button>
                </div>
                {
                  portalView ?
                  <>
                    {ownedTraits?.length ? (
                  <Carousel
                      ref={carouselRef}
                      enableAutoPlay
                      autoPlaySpeed={4000}
                      itemsToShow={itemsToShow}
                      pagination={false}
                      itemPadding={[0, 5]}
                      onNextEnd={({ index }) => {
                       clearTimeout(resetTimeout)
                       if (index + 1 === totalTraitPages) {
                          resetTimeout = setTimeout(() => {
                             if (carouselRef.current) {
                               carouselRef.current.goTo(0)
                             }
                         }, 4000) // same time
                       }
                     }}
                      onNextStart={(currentItem, nextItem) => {
                        if (currentItem.index === nextItem.index) {
                          // we hit the last item, go to first item
                          if (carouselRef.current) {
                            carouselRef.current.goTo(0)
                          }
                        }
                      }}
                      onPrevStart={(currentItem, nextItem) => {
                        if (currentItem.index === nextItem.index) {
                          // we hit the first item, go to last item
                          if (carouselRef.current) {
                            carouselRef.current.goTo(ownedTraits?.length);
                          }
                        }
                      }}
                      disableArrowsOnEnd={false}
                      showEmptySlots={true}
                    >
                      {ownedTraits.map((nft) => {
                          return (
                              <img
                                alt="NFTs"
                                src={`${
                                  nft.media[0].gateway
                                }?${new Date().getTime()}`}
                                className="cursor-pointer object-cover w-full rounded-xl"
                              />
                          );
                      })}
                    </Carousel>
                ) : (
                    <p className="font-title-bold text-dark-gray text-[24px] text-center relative">
                      You have no Traits available in your wallet.
                    </p>
                  )}
                  </>
                  :
                  <>
                    {ownedMaxies?.length ? (
                  <Carousel
                      ref={carouselRef}
                      enableAutoPlay
                      autoPlaySpeed={4000}
                      itemsToShow={itemsToShow}
                      pagination={false}
                      itemPadding={[0, 5]}
                      onNextEnd={({ index }) => {
                       clearTimeout(resetTimeout)
                       if (index + 1 === totalPages) {
                          resetTimeout = setTimeout(() => {
                             if (carouselRef.current) {
                               carouselRef.current.goTo(0)
                             }
                         }, 4000) // same time
                       }
                     }}
                      onNextStart={(currentItem, nextItem) => {
                        if (currentItem.index === nextItem.index) {
                          // we hit the last item, go to first item
                          if (carouselRef.current) {
                            carouselRef.current.goTo(0)
                          }
                        }
                      }}
                      onPrevStart={(currentItem, nextItem) => {
                        if (currentItem.index === nextItem.index) {
                          // we hit the first item, go to last item
                          if (carouselRef.current) {
                            carouselRef.current.goTo(ownedMaxies?.length);
                          }
                        }
                      }}
                      disableArrowsOnEnd={false}
                      showEmptySlots={true}
                    >
                      {ownedMaxies.map((nft) => {
                          return (
                              <img
                                alt="NFTs"
                                src={`${
                                  nft.media[0].gateway
                                }?${new Date().getTime()}`}
                                className="cursor-pointer object-cover w-full rounded-xl"
                              />
                          );
                      })}
                    </Carousel>
                ) : (
                    <p className="font-title-bold text-dark-gray text-[24px] text-center relative">
                      You have no Maxies available in your wallet.
                    </p>
                  )}
                  </>
                }
              <div class="flex flex-col w-full justify-center items-center text-start">
                {
                  connectedTwitter ?
                  <p class="text-[4vw] sm:text-[1vw] w-full font-bold text-dark-gray uppercase">Twitter Connected</p>
                  :
                  <p class="text-[4vw] sm:text-[1vw] w-full font-bold text-dark-gray uppercase">Twitter Not Connected</p>
                }
                {
                    connectedTwitter ?
                    <div class="grid w-full">
                      <div class ="flex flex-col items-start justify-center text-center gap-2">
                        <div class="bg-dark-gray bg-opacity-20 w-full h-full flex items-center justify-center p-4 gap-2">
                          <div class="flex-1 truncate text-xs justify-center items-start text-start w-full">
                            <p class="flex-1 truncate font-bold text-bold text-dark-gray uppercase">Handle:</p>
                            <p class="flex-1 truncate font-bold text-bold text-primary-red">{twitterDict.twitterHandle}</p>
                            <br></br>
                            <p class="flex-1 truncate font-bold text-bold text-dark-gray uppercase">Integrations:</p>
                            <p class="flex-1 truncate font-bold text-bold text-primary-red">READ, LIKE, FOLLOW, RT</p>
                          </div>
                          <img className="w-[30%] object-cover rounded-full" alt="TwitterPFP" src={twitterDict.twitterPFP}/>
                        </div>
                        <button disabled={!walletAddress} onClick={() => disconnectTwitter()} className="flex bg-dark-gray font-gilroy-regular text-1.7vw text-primary-yellow px-8 py-2 rounded-full drop-shadow-lg hover:bg-gray-100 hover:cursor-pointer">
                          DISCONNECT
                        </button>
                      </div>
                    </div>
                    :
                    <div class="grid w-full">
                      <div class="bg-dark-gray bg-opacity-20 w-full h-full flex flex-col items-center justify-between text-center gap-2 py-4 px-2">
                        <p class="text-[4vw] sm:text-[1vw] w-full font-bold text-dark-gray">MAX Twitter Connection</p>
                        <p class="text-[2vw] sm:text-[0.9vw] w-full font-text text-dark-gray">
                          You will be able to use the points portal to like/rt
                          posts and follow fellow Maxies. Points are awarded instantly.
                          No actions are taken without your explicit approval.
                        </p>
                        <button disabled={!walletAddress} onClick={() => connectTwitter()} className="flex items-center bg-dark-gray font-gilroy-regular text-1.7vw text-primary-yellow px-8 py-2 rounded-full drop-shadow-lg hover:bg-gray-100 hover:cursor-pointer">
                          CONNECT
                        </button>
                      </div>
                      {/*<div class="md:bg-dark-gray md:bg-opacity-20 w-full h-full flex flex-col items-center justify-between text-center gap-2 py-4 px-2">
                        <p class="text-[4vw] sm:text-[1vw] w-full font-bold text-dark-gray">Base Twitter Connection</p>
                        <p class="text-[2vw] sm:text-[0.9vw] w-full font-text text-dark-gray">
                          Actions will have to be done on twitter. Points are calculated when active posts expire.
                          You will be excluded from Maxie following table.
                        </p>
                        <button disabled={!walletAddress} onClick={() => connectTwitter()} className="flex items-center bg-dark-gray font-gilroy-regular text-1.7vw text-primary-yellow px-8 py-2 rounded-full drop-shadow-lg hover:bg-gray-100 hover:cursor-pointer">
                          CONNECT
                        </button>
                      </div>*/}
                    </div>
                  }
              </div>
              <div class="flex flex-col w-full justify-center items-center text-start">
                {
                  connectedDiscord ?
                  <p class="text-[4vw] sm:text-[1vw] w-full font-bold text-dark-gray uppercase">Discord Connected</p>
                  :
                  <p class="text-[4vw] sm:text-[1vw] w-full font-bold text-dark-gray uppercase">Discord Not Connected</p>
                }
                {
                  connectedDiscord ?
                  <div class="grid w-full">
                    <div class ="flex flex-col items-start justify-center text-center gap-2">
                      <div class="bg-dark-gray bg-opacity-20 w-full h-full flex items-center justify-center p-4 gap-2">
                        <div class="flex-1 truncate text-xs justify-center items-start text-start w-full">
                          <p class="flex-1 truncate font-bold text-bold text-dark-gray uppercase">Handle:</p>
                          <p class="flex-1 truncate font-bold text-bold text-primary-red">{discordDict.discordHandle}</p>
                          <br></br>
                          <p class="flex-1 truncate font-bold text-bold text-dark-gray uppercase">ROLES:</p>
                          <div class="flex flex-wrap flex-rows items-start justify-start text-center gap-1 font-bold text-white text-[80%]">
                            {
                              discordDict.discordRoles['maxie'] === 1 ?
                              <button class = "bg-gray rounded-xl py-1 px-2">Maxie</button>
                              :
                              ""
                            }
                            {
                              discordDict.discordRoles['maxieHolder'] === 1 ?
                              <button class = "bg-[#ADD8E6] rounded-xl py-1 px-2">Maxie Holder</button>
                              :
                              ""
                            }
                            {
                              discordDict.discordRoles['miniWhale'] === 1 ?
                              <button class = "bg-[#90EE90] rounded-xl py-1 px-2">Mini Whale</button>
                              :
                              ""
                            }
                            {
                              discordDict.discordRoles['whale'] === 1 ?
                              <button class = "bg-[#228B22] rounded-xl py-1 px-2">Whale</button>
                              :
                              ""
                            }
                            {
                              discordDict.discordRoles['megaWhale'] === 1 ?
                              <button class = "bg-[#013220] rounded-xl py-1 px-2">Mega Whale</button>
                              :
                              ""
                            }
                          </div>
                        </div>
                        <img className="w-[30%] object-cover rounded-full" alt="TwitterPFP" src={discordDict.discordPFP}/>
                      </div>
                      <button disabled={!walletAddress} onClick={() => disconnectDiscord()} className="flex bg-dark-gray font-gilroy-regular text-1.7vw text-primary-yellow px-8 py-2 rounded-full drop-shadow-lg hover:bg-gray-100 hover:cursor-pointer">
                        DISCONNECT
                      </button>
                    </div>
                  </div>
                  :
                  <div class="grid w-full h-full">
                    <div class="bg-dark-gray bg-opacity-20 w-full h-full flex flex-col items-center justify-between text-center gap-2 py-4 px-2">
                      <p class="text-[4vw] sm:text-[1vw] w-full font-bold text-dark-gray">MAX Discord Connection</p>
                      <p class="text-[2vw] sm:text-[0.9vw] w-full font-text text-dark-gray">
                        You will be able to claim exclusive traits & lunchboxes based
                        on your discord roles - Maxie Holders, Maxie Whales, etc.
                        Both your discord and twitter must be connected in order to feature
                        in our leaderboards.
                      </p>
                      <button disabled={!walletAddress} onClick={() => window.location.href = `https://discord.com/oauth2/authorize?client_id=${process.env.DISCORD_CLIENT_ID_NEW}&redirect_uri=https%3A%2F%2Fapi.maxinout.com%2Fauth%2Fdiscord%2Fcallback&response_type=code&scope=guilds%20connections%20identify&state=${walletAddress}`} className="flex items-center bg-dark-gray font-gilroy-regular text-1.7vw text-primary-yellow px-8 py-2 rounded-full drop-shadow-lg hover:bg-gray-100 hover:cursor-pointer">
                        CONNECT
                      </button>
                    </div>
                  </div>
                  }
              </div>
            </div>
            :
            <div class="flex flex-col justify-center items-center text-center rounded-lg px-2 py-4 gap-2 sm:gap-1">
              <p class="text-[4vw] sm:text-[1.4vw] w-full font-text font-bold text-dark-gray uppercase">Connect Wallet to get started</p>
              <DynamicWidget/>
            </div>
          }
        </>
      )
    }, [walletAddress, ownedMaxies, ownedTraits, connectedDiscord, connectedTwitter, portalView, itemsToShow, loadingNFTs])

    return (
        <div className= "grid sm:grid-cols-[1fr,3fr] w-full h-full overflow-y-scroll gap-4 sm:gap-0">
            <PointsBar
              portal='profilePortal'
              level={maxieStats.level}
              totalXP={maxieStats.totalXP}
              totalMP={maxieStats.totalMP}
            />
            {renderProfile()}
        </div>
    );
}

export default Profile;
