import React, { useCallback, useState, useEffect, useRef } from "react";
import axios from "axios";
import {ethers} from 'ethers';
import '../../App.css'
import PacksABI from '../../Components/packsABI.json';
import CommonOpening from '../../img/commonOpening.mp4'
import RareOpening from '../../img/rareOpening.mp4'
import LegOpening from '../../img/legOpening.mp4'
import PackCommonIMG from '../../img/commonBox.png'
import PackRareIMG from '../../img/rareBox.png'
import PackLegIMG from '../../img/legBox.png'
import EmptyMaxie from '../../img/emptyMaxin.png'
// import CommonAudio from '../../audio/hypeBeat.mp3'
// import LegendaryAudio from '../../audio/legendaryAudio.mp3'
// import WaitingTrack from '../../audio/waitingTrack.mp3'
// import RareAudio from '../../audio/rareAudio.mp3'
// import RattleEffect from '../../audio/rattleEffect.mp3'
// import UnlockEffect from '../../audio/unlockEffect.mp3'
// import WhooshEffect from '../../audio/whooshEffect.mp3'
// import InitialEffect from '../../audio/initialEffect.mp3'
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const { Network, Alchemy } = require("alchemy-sdk");

const Drops = (props) => {

  // user variables
  const [walletType, setWalletType] = useState()
  const [walletAddress, setWalletAddress] = useState()
  const [walletNFTs, setWalletNFTs] = useState([])

  // trait contract/provider instantation
  const packsContractAddress = "0x0dB6aD9ce8f2299ef23E06AB55A6E126878855B3"
  const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BS_KEY}`);
  const packsContractProvider = new ethers.Contract(packsContractAddress, PacksABI, provider);

  // boolean variables
  const [showFeatured, setShowFeatured] = useState(true)
  const [showGiftDrops, setShowGiftDrops] = useState(false)
  const [showReveal, setShowReveal] = useState(false)
  const [showRevealTraits, setShowRevealTraits] = useState(false)
  const [playAudio, setPlayAudio] = useState(false)
  const [updatePacks, setUpdatePacks] = useState(false)
  const [updateFlag, setUpdateFlag] = useState(0);

  // pack variables
  const [allPacks, setAllPacks] = useState()
  const [giftPacks, setGiftPacks] = useState()
  const [basePackInfo, setBasePackInfo] = useState('This super dope drop is our base lunchbox and comes with 4 traits inside! You can pull anything from a common to a legendary from this lunchbox.')
  const [rarePackInfo, setRarePackInfo] = useState('This super rare drop comes with 5 traits inside guaranteeing at least one RARE trait and improving your odds of pulling a LEGENDARY. ')
  const [legPackInfo, setLegPackInfo] = useState('This legendary drop comes with 5 traits inside guaranteeing at least one EPIC trait and improving your odds of pulling a LEGENDARY. ')
  // const [commonAudio] = useState(new Audio(CommonAudio));
  // const [rareAudio] = useState(new Audio(RareAudio));
  // const [legendaryAudio] = useState(new Audio(LegendaryAudio));
  // const [rattleEffect] = useState(new Audio(RattleEffect));
  // const [unlockEffect] = useState(new Audio(UnlockEffect));
  // const [whooshEffect] = useState(new Audio(WhooshEffect));
  // const [initialEffect] = useState(new Audio(InitialEffect));
  // const [waitingAudio] = useState(new Audio(WaitingTrack));
  const [packToReveal, setPackToReveal] = useState()
  const [traitsToReveal, setTraitsToReveal] = useState({})
  const [packTraits, setPackTraits] = useState()
  const [requestIds, setRequestIds] = useState({})

  // render color scheme
  const [animating, setAnimating] = useState(false);
  const [stopRattle, setStopRattle] = useState(false);
  const [colorScheme, setColorScheme] = useState({
    'legendary': '#BD00FF',
    'epic':'#7d032a',
    'rare': '#6db047',
    'uncommon': '#6863b5',
    'common': '#254590'
  })
  const [flash, setFlash] = useState(false);
  const fadeIn = () => {
    setFlash(true);
  };
  const fadeOut = () => {
    setFlash(false);
  };
  function hexToRGBA(hex, alpha = 1) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

  // set all pack types created.
  useEffect(() => {
    const fetchData = async () => {
      const allPacks =  await packsContractProvider.getAllPackTypes()
      // console.log(allPacks)
      const indexedPacks = allPacks.map((pack, index) => ({
        ...pack,
        packID: index+1
      }));
      setAllPacks(indexedPacks)
    }
    fetchData()
  }, [updateFlag])

  // set wallet address/type variable
  useEffect(() => {
    // console.log(props.defaultAccount.address)
    if (props.defaultAccount.address){
      if (!walletAddress){
        setWalletAddress(props.defaultAccount.address)
      }
      else {
        if (walletAddress !== props.defaultAccount.address){
          setWalletAddress(props.defaultAccount.address)
        }
      }
      // setprops.defaultAccount.address(props.defaultAccount.address)
      setWalletType(props.defaultAccount.connector.walletConnector.name)
    }
    else{
      setWalletAddress()
      setWalletType()
    }
  }, [props.defaultAccount.address])

  // set gift pack types for user.
  useEffect(() => {
    if (walletAddress){
      const fetchData = async () => {
        const giftPacks =  await packsContractProvider.getGiftPacksByWallet(walletAddress)
        // console.log(giftPacks)
        const indexedGiftPacksPromises = giftPacks.map(async (amount, index) => {
          // console.log(amount.toNumber(), index)
          if (amount.toNumber() > 0){
            let packType = await packsContractProvider.getPackType(index+1);
            return {
                ...packType,
                packID: index+1
              };
          }
          return null;
        });
        const allGiftPacks = (await Promise.all(indexedGiftPacksPromises)).filter(pack => pack !== null);
        // console.log(allGiftPacks)
        setGiftPacks(allGiftPacks);
      }
      fetchData()
    }
    else {
      setGiftPacks()
    }
  }, [walletAddress, updateFlag])

  // set audio when pack is waiting for reveal.
  // useEffect(() => {
  //   if (showReveal){
  //     waitingAudio.volume = 0.1;
  //     waitingAudio.play()
  //   }
  //   else {
  //     waitingAudio.pause();  // Pause the audio
  //     waitingAudio.currentTime = 0;
  //   }
  // }, [showReveal])

  // set audio when pack is revealed.
  // useEffect(() => {
  //   if (playAudio && packToReveal.name.toLowerCase().includes('common')) {
  //     commonAudio.play();
  //   }
  //   else if (playAudio && packToReveal.name.toLowerCase().includes('rare')) {
  //     rareAudio.play();
  //   }
  //   else if (playAudio && packToReveal.name.toLowerCase().includes('max')){
  //     legendaryAudio.play()
  //   }
  //   else {
  //     commonAudio.pause();  // Pause the audio
  //     commonAudio.currentTime = 0;
  //     rareAudio.pause();  // Pause the audio
  //     rareAudio.currentTime = 0;
  //     legendaryAudio.pause();  // Pause the audio
  //     legendaryAudio.currentTime = 0;
  //   }
  // }, [playAudio, packToReveal]);

  // pause audio when unmounted
  // useEffect(() => {
  //   return () => {
  //     commonAudio.pause();  // Pause the audio
  //     commonAudio.currentTime = 0;  // Reset audio to the start
  //     rareAudio.pause();  // Pause the audio
  //     rareAudio.currentTime = 0;
  //     legendaryAudio.pause();  // Pause the audio
  //     legendaryAudio.currentTime = 0;
  //   }
  // }, []);

  // update drop renderings
  const selectFeatured = () => {
    setShowFeatured(true)
    setShowGiftDrops(false)
    setShowReveal(false)
    setShowRevealTraits(false)
    setPlayAudio(false)
  }
  const selectGiftDrops = () => {
    setShowFeatured(false)
    setShowGiftDrops(true)
  }
  const backToDropsHome = () => {
    props.setPopup(true)
    props.setPopupState('headOver')
    setShowFeatured(true)
    setShowGiftDrops(false)
    setShowReveal(false)
    setShowRevealTraits(false)
    setPlayAudio(false)
    setUpdateFlag(prev => prev + 1);
    requestIdsRef.current = {};
    traitIdsRef.current = {};
  }

  // sets up traits to be revealed
  const revealTraits = useCallback(async () => {
    let traitsToRevealTemp = {}
    const traitPromises = packTraits.map(async (trait, index) => {
      const traitURI = await packsContractProvider.getTraitURI(trait.toNumber())
      const response = await axios.get(traitURI);
      const dataWithReveal = { ...response.data, revealed: 0 };
      traitsToRevealTemp[index] = dataWithReveal
    });

    await Promise.all(traitPromises);
    setTraitsToReveal(traitsToRevealTemp)
    setStopRattle(true)
    setAnimating(true);
    // rattleEffect.play()
    // setTimeout(() => {
    //   rattleEffect.currentTime = 0;
    //   rattleEffect.play()
    //
    // }, 1000);
    // setTimeout(() => {
    //   rattleEffect.currentTime = 0;
    //   rattleEffect.play()
    // }, 2000);
    // initialEffect.play()
    setTimeout(() => {
        // unlockEffect.play()
        // whooshEffect.play()
        setFlash(true);
    }, 3500);
    setTimeout(() => {
        setShowFeatured(false)
        setShowGiftDrops(false)
        setShowReveal(false)
        setShowRevealTraits(true);
        setAnimating(false);
        setFlash(false)
        setStopRattle(false)
        setPlayAudio(true)
    }, 5000); // Wait for 5 seconds before showing traits

  }, [walletAddress, packTraits])

  // reveal all traits at once or remaining traits left to reveal
  const handleRevealAll = useCallback(() => {
      const newTraits = { ...traitsToReveal };
      Object.keys(newTraits).forEach((key) => {
          newTraits[key].revealed = 1;
      });
      setTraitsToReveal(newTraits);
  }, [traitsToReveal]);

  // reveal traits one at a time.
  const handleReveal = useCallback((index) => {
    setTraitsToReveal(prevTraits => ({
      ...prevTraits,
      [index]: {
        ...prevTraits[index],
        revealed: 1
      }
    }));
  }, [traitsToReveal]);

  // handles the spinner rendering functionality for reveal
  const frontSideVariants = {
    hidden: { rotateY: 0 },
    show: { rotateY: 1080, transition: { duration: 2 } },
  };
  const backSideVariants = {
    hidden: { rotateY: 0 },
    show: { rotateY: 1080, transition: { duration: 2 } },
  };

  // checks if all traits have been revealed
  const allTraitsRevealed = () => {
    return Object.values(traitsToReveal).every(trait => trait.revealed === 1);
  };

  const videoRef = useRef(null);

  useEffect(() => {
      if (animating && videoRef.current) {
          videoRef.current.play();
      } else if (!animating && videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0; // Optional: Reset video to start if you want it to restart each time.
      }
  }, [animating]);

  // render featured and sold out packs
  const renderPacks = useCallback(() => {
    if (showFeatured){
      return(
        <div class = "flex flex-col w-full gap-16">
          {allPacks?.map((pack, index) => (
            <div key={pack.packID} className={`flex flex-col md:flex-row items-stretch ${pack.amountPurchased.toNumber() < pack.totalSupply.toNumber() ? "" : ""}`}>
              <video className="w-full md:w-[35%] h-full object-cover md:rounded-l-2xl" width="320" height="240" autoPlay loop muted>
                <source src={pack.name.toLowerCase().includes('common') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/commonBox.mp4' :
                          pack.name.toLowerCase().includes('rare') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/rareBox.mp4' :
                          pack.name.toLowerCase().includes('max') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/legBox.mp4' :
                          null} alt={pack.name} type = 'video/mp4'/>
              </video>
              <div className="flex flex-col items-start text-start justify-center w-full  p-8 gap-2 bg-[#D9D9D9] md:rounded-r-2xl">
                <h2 className="font-bold text-[120%] lg:text-[150%] xl:text-[200%] text-dark-gray uppercase">{pack.name}</h2>
                <p className="font-bold text-[80%] lg:text-[100%] xl:text-[120%] text-primary-red -mt-4">
                  {
                    pack.name.toLowerCase().includes('common')
                      ? `Remaining Supply: ${pack.totalSupply.toNumber() - pack.amountPurchased.toNumber()}/${pack.totalSupply.toNumber()}`
                      : `Remaining Supply: ${pack.totalSupply.toNumber() - pack.amountPurchased.toNumber()}/${pack.totalSupply.toNumber()}`
                  }
                </p>
                <p className="font-bold text-[75%] text-dark-gray">
                    {pack.name.toLowerCase().includes('common') ? basePackInfo :
                    pack.name.toLowerCase().includes('rare') ? rarePackInfo :
                    pack.name.toLowerCase().includes('max') ? legPackInfo :
                    null}
                </p>
                <h2 className="font-bold text-[120%] lg:text-[150%] xl:text-[200%] text-dark-gray uppercase">${pack.packCost.toNumber()} MATIC</h2>
                {
                  pack.amountPurchased.toNumber() < pack.totalSupply.toNumber() ?
                  <button disabled={!walletAddress || (pack.amountPurchased.toNumber() === pack.totalSupply.toNumber())} onClick = {() => purchasePack(pack.packID, pack.packCost.toNumber())} className={`text-[4vw] sm:text-[1.5vw] py-1 px-12 rounded-full font-text font-bold text-center shadow-2xl ${walletAddress ? 'bg-dark-gray hover:text-dark-gray hover:bg-primary-yellow' : 'bg-dark-gray opacity-50 cursor-not-allowed'} text-primary-yellow`}>
                    BUY NOW
                  </button>
                  :
                  <button disabled={!walletAddress || (pack.amountPurchased.toNumber() === pack.totalSupply.toNumber())} className={`text-[4vw] sm:text-[1.5vw] py-1 px-12 font-text font-bold text-center shadow-2xl bg-[#8B0000] text-white`}>
                    SOLD OUT
                  </button>
                }
              </div>
            </div>
          ))}
        </div>
      )
    }
    if (showGiftDrops){
      return(
        <div class = "flex flex-col w-full gap-16">
          {giftPacks?.map((pack, index) => (
            <div key={pack.packID} className={`flex flex-col md:flex-row items-stretch ${pack.amountPurchased.toNumber() < pack.totalSupply.toNumber() ? "" : ""}`}>
              <video className="w-full md:w-[35%] h-full object-cover md:rounded-l-2xl" width="320" height="240" autoPlay loop muted>
                <source src={pack.name.toLowerCase().includes('common') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/commonBox.mp4' :
                          pack.name.toLowerCase().includes('rare') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/rareBox.mp4' :
                          pack.name.toLowerCase().includes('max') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/legBox.mp4' :
                          null} alt={pack.name} type = 'video/mp4'/>
              </video>
              <div className="flex flex-col items-start text-start justify-center w-full p-8 gap-4 bg-[#D9D9D9] md:rounded-r-2xl">
                <h2 className="font-bold text-[120%] lg:text-[150%] xl:text-[200%] text-dark-gray uppercase">{pack.name}</h2>
                <p className="font-bold text-[75%] text-dark-gray -mt-2">
                    {pack.name.toLowerCase().includes('common') ? basePackInfo :
                    pack.name.toLowerCase().includes('rare') ? rarePackInfo :
                    pack.name.toLowerCase().includes('max') ? legPackInfo :
                    null}
                </p>
                <button disabled={!walletAddress} onClick = {() => claimPack(pack.packID)} className={`text-[4vw] sm:text-[1.5vw] py-1 px-12 rounded-full font-text font-bold text-center shadow-2xl ${walletAddress ? 'bg-dark-gray hover:text-dark-gray hover:bg-primary-yellow' : 'bg-dark-gray opacity-50 cursor-not-allowed'} text-primary-yellow`}>
                  CLAIM
                </button>
              </div>
            </div>
          ))}
        </div>
      )
    }
    else if (showReveal){
      return (
        <div class = "flex flex-col items-center justify-center w-full gap-6">
          <div className="sm:w-[80%] lg:w-[40%] relative">
            <div onClick = {() => backToDropsHome()} class = {`flex flex-row text-end justify-end items-center font-bold md:text-[1.5vw] text-dark-gray  hover:text-primary-yellow gap-2 ${stopRattle ? 'opacity-0' : 'opacity-100'}`}>
              <p>skip reveal</p>
              < FontAwesomeIcon icon={faArrowRight}/>
            </div>
            <div className="relative">
              <video ref={videoRef} className={`absolute top-0 left-0 w-full object-cover rounded-2xl ${animating ? 'opacity-100' : 'opacity-0'}`}  width="320" height="240" style={{ zIndex: 98 }}>
                <source src={packToReveal.name.toLowerCase().includes('common') ? CommonOpening :
                      packToReveal.name.toLowerCase().includes('rare') ? RareOpening :
                      packToReveal.name.toLowerCase().includes('max') ? LegOpening :
                      null} alt={packToReveal.name} type = 'video/mp4'/>
              </video>
              <img
                  src={packToReveal.name.toLowerCase().includes('common') ? PackCommonIMG :
                        packToReveal.name.toLowerCase().includes('rare') ? PackRareIMG :
                        packToReveal.name.toLowerCase().includes('max') ? PackLegIMG :
                        null}
                  alt={packToReveal.name}
                  className={`w-full object-cover rounded-2xl ${stopRattle ? '' : 'rattle-effect'} ${animating ? 'opacity-0' : 'opacity-100'}`}
                  style={{ zIndex: 99 }}
                  />
            </div>
          </div>
          <button onClick = {() => revealTraits()}class={`text-[4vw] sm:text-[1.5vw] py-1 px-12 rounded-full bg-dark-gray font-text font-bold text-bold text-primary-yellow text-center justify-center shadow-2xl hover:text-dark-gray hover:bg-primary-yellow transition-opacity ${stopRattle ? 'opacity-0' : 'opacity-100'}`} >
            OPEN YOUR LUNCHBOX
          </button>
        </div>
      )
    }
    else if (showRevealTraits){
      return (
        <div class="flex flex-col items-end justify-end text-end gap-2">
          {
            !allTraitsRevealed() ? (
              <div class="">
                <button onClick={handleRevealAll} class="py-1 px-12 rounded-full bg-dark-gray font-text font-bold text-primary-yellow text-center shadow-2xl hover:text-dark-gray hover:bg-primary-yellow slideInFromTop">
                    REVEAL ALL
                </button>
                {/*<FontAwesomeIcon icon={faVolumeHigh} class="text-dark-gray hover:text-primary-yellow w-[8%]"/>*/}
              </div>
            ) : (
              <div class="">
                <button onClick={backToDropsHome} class="py-1 px-12 rounded-full bg-primary-yellow font-text font-bold text-dark-gray text-center shadow-2xl hover:text-primary-yellow hover:bg-dark-gray">
                    GO BACK TO DROPS
                </button>
              </div>
            )
          }
          <div className=" w-full h-full grid grid-rows-2 md:grid-cols-2 xl:grid-cols-3 gap-12 slideInFromTop">
            {Object.keys(traitsToReveal).map((key, index) => (
              <motion.div
                key={index}
                animate={traitsToReveal[key].revealed ? "show" : "hidden"}
                >
                <motion.div
                  variants={frontSideVariants}
                  animate={traitsToReveal[key].revealed ? "show" : "hidden"}
                  className={`flex flex-col h-full items-center justify-start p-3 bg-black bg-opacity-50 rounded-2xl relative ${traitsToReveal[key].revealed ? 'hidden' : ''}`}>
                  <div className="relative w-full cursor-pointer rounded-t-2xl" onClick={() => handleReveal(key)}>
                      <img
                          src={EmptyMaxie}
                          className="object-cover w-full rounded-t-2xl bg-white bg-opacity-20 hover:cursor-pointer emptyMaxieHover"
                      />
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center text-white font-bold text-xl">
                          Click to Reveal
                      </div>
                  </div>
                  <div className="flex flex-col items-center justify-start bg-black w-full h-full rounded-b-2xl p-4">
                    <p className="w-full text-start text-[#E0DFDF] font-bold text-[80%]">
                      Rarity:
                    </p>
                    <p
                      className={`w-full text-start text-[#E0DFDF] -mt-2 font-bold text-[200%] uppercase truncate`}
                    >
                      ??????
                    </p>
                    <div className="flex items-start justify-between text-start gap-4 w-full">
                      <div className="flex flex-col items-center justify-center text-start">
                        <p className="w-full text-start text-[#E0DFDF] font-bold text-[80%]">
                          Name:
                        </p>
                        <p className="w-full text-start text-[#E0DFDF]  font-bold text-[100%] uppercase">
                          ?????
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center text-start">
                        <p className="w-full text-start text-[#E0DFDF] font-bold text-[80%]">
                          Category:
                        </p>
                        <p className="w-full text-start text-[#E0DFDF] font-bold text-[100%] uppercase">
                          ?????
                        </p>
                      </div>
                  </div>
                  </div>
                </motion.div>
                <motion.div
                    variants={backSideVariants}
                    animate={traitsToReveal[key].revealed ? "show" : "hidden"}
                    className={`flex flex-col h-full items-center justify-start p-3 bg-opacity-50 rounded-2xl ${traitsToReveal[key].revealed ? '' : 'hidden'}`}
                    style={{backgroundColor: hexToRGBA(colorScheme[traitsToReveal[key].attributes[2]['value']], 0.5)}}
                >
                  <img src={traitsToReveal[key].image} className="cursor-pointer object-cover w-full rounded-t-2xl"/>
                  <div
                    className="flex flex-col items-center justify-start w-full h-full rounded-b-2xl p-4"
                    style={{backgroundColor: colorScheme[traitsToReveal[key].attributes[2]['value']]}}
                  >
                    <p className="w-full text-start text-[#E0DFDF] font-bold text-[80%]">
                      Rarity:
                    </p>
                    <p
                      className={`w-full text-start text-primary-yellow -mt-2 font-bold text-[200%] uppercase truncate`}
                    >
                      {traitsToReveal[key].attributes[2]['value']}
                    </p>
                    <div className="flex items-start justify-between text-start gap-4 w-full">
                      <div className="flex flex-col items-center justify-center text-start w-[70%]">
                        <p className="w-full text-start text-[#E0DFDF] font-bold text-[80%]">
                          Name:
                        </p>
                        <p className="w-full text-start text-[#E0DFDF]  font-bold text-[80%] uppercase">
                          {traitsToReveal[key].name}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center text-start">
                        <p className="w-full text-start text-[#E0DFDF] font-bold text-[80%]">
                          Category:
                        </p>
                        <p className="w-full text-start text-[#E0DFDF] font-bold text-[80%] uppercase">
                          {traitsToReveal[key].attributes[0]['value']}
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>
      );

    }
  }, [walletAddress, walletType, allPacks, showFeatured, showGiftDrops, showReveal, packToReveal, traitsToReveal, colorScheme, handleReveal, handleRevealAll, packTraits, giftPacks]);

  // event listener to get all requestIds coming in.
  const requestIdsRef = useRef({});
  useEffect(() => {
      function requestSentHandler(requestId, numberQuantity, result) {
          console.log('turned on listener...')
          requestIdsRef.current[result.transactionHash] = requestId;
      }
      console.log('turning back on')
      packsContractProvider.on('RequestSent', requestSentHandler);

      // Cleanup function
      return () => {
          console.log('turned off listener...')
          packsContractProvider.off('RequestSent', requestSentHandler);
      };
  }, []);  // Empty dependency array means this effect runs once when the component mounts and cleans up when it unmounts.

  // event listener to get all traitIds coming w/ requestId.
  const traitIdsRef = useRef({});
  useEffect(() => {
      function packPurchaseHandler(account, selectedTraits, finalRequestID, event) {
          console.log('turned on purchase listener...')
          traitIdsRef.current[finalRequestID._hex] = selectedTraits;
      }
      console.log('turning back on purchase')
      packsContractProvider.on('PackPurchase', packPurchaseHandler);

      // Cleanup function
      return () => {
          console.log('turned off purchase listener...')
          packsContractProvider.off('PackPurchase', packPurchaseHandler);
      };
  }, []);

  const waitForRequestId = (hash) => {
    return new Promise((resolve, reject) => {
        const maxRetries = 60;
        let retries = 0;
        const checkForRequestId = () => {
            const requestId = requestIdsRef.current[hash];
            if (requestId !== undefined) {
                resolve(requestId);
            } else if (retries < maxRetries) {
                retries++;
                setTimeout(checkForRequestId, 1000); // Wait for 1 second before checking again
            } else {
                reject(new Error('Max retries reached without getting request data.'));
            }
        }

        checkForRequestId();
    });
  }
  const waitForTraitsToReveal = (userRequestId) => {
    return new Promise((resolve, reject) => {
        const maxRetries = 120;
        let retries = 0;
        console.log(traitIdsRef.current)
        const checkForTraits = () => {
            const traits = traitIdsRef.current[userRequestId._hex];
            if (traits !== undefined) {
                resolve(traits);
            } else if (retries < maxRetries) {
                retries++;
                setTimeout(checkForTraits, 1000); // Wait for 1 second before checking again
            } else {
                reject(new Error('Max retries reached without getting traits data.'));
            }
        }

        checkForTraits();
    });
  }

  // purchase pack functionality
  const purchasePack = useCallback( async (packID, packCost) => {
    // check wallet type for injected provider large
    // console.log(requestIdsRef.current, traitIdsRef.current)
    props.setPopup(true)
    props.setPopupState('purchasingPack')
    try {
      let walletProvider = window.ethereum
      if (walletType === "PhantomEvm"){
        walletProvider = window.phantom.ethereum
      }

      // instantiate raffle contract
      const value = ethers.utils.parseUnits(packCost.toString(), "ether")
      const clientProvider = new ethers.providers.Web3Provider(walletProvider);
      const purchaseContract = new ethers.Contract(packsContractAddress, PacksABI, clientProvider);
      const mintPack = await purchaseContract.connect(clientProvider.getSigner()).packMint(
        walletAddress,
        packID,
        {
          value: value
        }
      )
      mintPack.wait()
      .then(async (receipt) => {
        console.log('Approval successful!');
        props.setPopupState('waitingForReveal')
        // const userRequestId = await requestIdsRef.current[receipt.transactionHash];
        // console.log(userRequestId._hex, 'got here 1');
        // // Set up a promise to wait for the 'PackPurchase' event
        try {
            const userRequestId = await waitForRequestId(receipt.transactionHash)
            // console.log(userRequestId._hex, 'got here 1');
            const traitsToReveal = await waitForTraitsToReveal(userRequestId);
            // console.log(traitsToReveal, 'got here 2')
            traitsToReveal.map(async (traitID) => {
              const trait = await packsContractProvider.getTrait(traitID)
              console.log(trait.name, trait.amountMinted.toNumber(), trait.category.toNumber(), trait.maxSupply.toNumber(), trait.rarityTier.toNumber())
            })
            setPackTraits(traitsToReveal)
            const packInfo = await packsContractProvider.getPackType(packID)
            await setPackToReveal(packInfo)
            setShowFeatured(false)
            setShowGiftDrops(false)
            setShowReveal(true)
            props.setPopupState("packPurchaseApproved")
        } catch (error) {
            console.error('Failed to get traits data:', error);
            props.setPopupState("packPurchaseApprovedWithoutReveal")
        }
      })
      .catch((error) => {
        console.error('Approval failed:', error);
        props.setPopupState('transactionError')
      });
    } catch (error) {
      console.error('Approval failed:', error);
      props.setPopupState('transactionError')
    }

  }, [walletAddress, walletType]);

  const claimPack = useCallback( async (packID) => {
    // check wallet type for injected provider arg
    console.log(packID, walletAddress)
    props.setPopup(true)
    props.setPopupState('purchasingPack')
    let walletProvider = window.ethereum
    if (walletType === "PhantomEvm"){
      walletProvider = window.phantom.ethereum
    }
    // instantiate raffle contract
    const value = ethers.utils.parseUnits("0", "ether")
    const clientProvider = new ethers.providers.Web3Provider(walletProvider);
    const purchaseContract = new ethers.Contract(packsContractAddress, PacksABI, clientProvider);

    const mintPack = await purchaseContract.connect(clientProvider.getSigner()).claimGiftPack(
      packID,
      {
        value: value
      }
    )
    mintPack.wait()
    .then(async (receipt) => {
      console.log('Approval successful!');
      props.setPopupState('waitingForReveal')
      // const userRequestId = await requestIdsRef.current[receipt.transactionHash];
      // console.log(userRequestId._hex, 'got here 1');
      // // Set up a promise to wait for the 'PackPurchase' event
      try {
          const userRequestId = await waitForRequestId(receipt.transactionHash)
          console.log(userRequestId._hex, 'got here 1');
          const traitsToReveal = await waitForTraitsToReveal(userRequestId);
          console.log(traitsToReveal, 'got here 2')
          setPackTraits(traitsToReveal)
          const packInfo = await packsContractProvider.getPackType(packID)
          await setPackToReveal(packInfo)
          setShowFeatured(false)
          setShowGiftDrops(false)
          setShowReveal(true)
          props.setPopupState("packPurchaseApproved")
      } catch (error) {
          console.error('Failed to get traits data:', error);
          props.setPopupState("packPurchaseApprovedWithoutReveal")
      }
    })
    .catch((error) => {
      console.error('Approval failed:', error);
      props.setPopupState('transactionError')
    });

  }, [walletAddress, walletType]);

  // WALLET OWNER TESTS & SETUPS
  const giftAllPacks = useCallback(async () => {
      const userWallets = [
        "0x5a755DB0F90A54336A7e770A1094e618d8B9Bf7B"
      ]
      const delay = (duration) => {
          return new Promise(resolve => setTimeout(resolve, duration));
      };
      for (const user of userWallets) {
        console.log(user)
        const walletPrivateKey = process.env.OWNER_WALLET
        const wallet = new ethers.Wallet(walletPrivateKey);
        const signer = wallet.connect(provider);

        let gasPrice = await provider.getGasPrice();

        // Increase the gas price by a certain factor to speed up transaction processing (optional)
        gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2

        // Define transaction parameters
        const transactionParameters = {
            gasPrice: gasPrice,
            gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
            // ...other transaction parameters like 'to', 'value' and 'data'
        };
        const packsContract = new ethers.Contract(packsContractAddress, PacksABI, signer);
        packsContract.giftPack(
          2,
          2,
          user,
          transactionParameters
        )
        .then((receipt) => {
          console.log('Approval successful!');
          console.log('Transaction receipt:', receipt);
        })
        .catch((error) => {
          console.error('Approval failed:', error);
          // props.setPopupState('transactionError')
        });
        await delay(5000);
      }

  }, [walletAddress, walletType])
  const createTraits = useCallback(async () => {
    const leg = [
          "chronos",
          "ems",
          "golden glow",
          "samsara"
      ]
    const epic = [
        "yin yang",
        "rainbow",
        "holy",
        "duplication",
        "infinity",
        "mesmoreyes"
    ]
    const rare = [
        "surprised blue",
        "surprised green",
        "surprised red",
        "drowsy blue",
        "drowsy orange",
        "drowsy red",
        "diabolical red",
        "diabolical grey",
        "diabolical green"
    ]
    const uncommon = [
        "blank",
        "brown wink",
        "demon",
        "grey wink",
        "light green wink",
        "orange wink"
    ]
    const common = [
          "orange",
          "light brown",
          "brown",
          "green",
          "grey"
      ]
    const allTraits = {
            'legendary': leg,
            'epic': epic,
            'rare': rare,
            'uncommon': uncommon,
            'common': common
      }
    // A helper function to wait for a certain duration
    const delay = (duration) => {
        return new Promise(resolve => setTimeout(resolve, duration));
    };

    // Iterating through each face
    for (const rarity of Object.keys(allTraits)) {
        console.log(rarity);
        let rarityId = 0;
        let maxSupply = 0;
        let minSupply = 0;

        switch(rarity) {
            case 'legendary':
                rarityId = 5;
                minSupply = 50;
                maxSupply = 65;
                break;
            case 'epic':
                rarityId = 4;
                minSupply = 85;
                maxSupply = 110;
                break;
            case 'rare':
                rarityId = 3;
                minSupply = 143;
                maxSupply = 183;
                break;
            case 'uncommon':
                rarityId = 2;
                minSupply = 345;
                maxSupply = 370;
                break;
            case 'common':
                rarityId = 1;
                minSupply = 660;
                maxSupply = 680;
                break;
        }

        for (const trait of allTraits[rarity]) {
            console.log(trait, rarityId, maxSupply, minSupply);

            const walletPrivateKey = process.env.OWNER_WALLET;
            const wallet = new ethers.Wallet(walletPrivateKey);
            const signer = wallet.connect(provider);

            let gasPrice = await provider.getGasPrice();

            // Increase the gas price by a certain factor to speed up transaction processing (optional)
            gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2

            // Define transaction parameters
            const transactionParameters = {
                gasPrice: gasPrice,
                gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
                // ...other transaction parameters like 'to', 'value' and 'data'
            };

            const packsContract = new ethers.Contract(packsContractAddress, PacksABI, signer)

            try {
                const createTrait = await packsContract.createTrait(
                    trait + ' trait',
                    7,
                    minSupply,
                    rarityId,
                    transactionParameters
                )
                .then((receipt) => {
                  console.log('Approval successful!', trait);
                  console.log('Transaction receipt:', receipt);
                })
                .catch((error) => {
                  console.error('Approval failed:', error);
                  // props.setPopupState('transactionError')
                });
                // console.log('Approval successful!');
            } catch (error) {
                console.error('Approval failed:', error);
                // props.setPopupState('transactionError');
            }

            if (maxSupply > minSupply){
              minSupply += 5
            }

            // Pause for 5 seconds
            await delay(5000);
        }
    }

  }, [walletAddress, walletType])
  const getTest = async () => {
    // const finalTrait = await packsContractProvider.getTrait(214)
    // console.log(finalTrait)
    const userWallets = [
      "0xAAb309a911E19e6e6F6da7AEc32C5333aa60Ef6C",
    ]
    for (const user of userWallets){
      console.log(user)
      const giftPacks = await packsContractProvider.getGiftPacksByWallet(user)
      console.log(giftPacks)
      const indexedGiftPacksPromises = giftPacks.map(async (amount, index) => {
        console.log(amount.toNumber(), index)
      });
    }

    // const category = await packsContractProvider.getTraitCategoryByNumber(7)
    // console.log(category)
    // const rarity = await packsContractProvider.getRarityByNumber(5)
    // console.log(rarity)
    // const pack = await packsContractProvider.getPack(12)
    // console.log(pack)
    // let i = 0
    // pack.traitArray.map(async (trait) => {
    //   console.log(trait.toNumber(), i)
    //   i += 1
    // const finalTrait = await packsContractProvider.getTrait(trait.toNumber())
    //   console.log(finalTrait.name, finalTrait.amountMinted.toNumber(), finalTrait.category.toNumber(), finalTrait.maxSupply.toNumber(), finalTrait.rarityTier.toNumber(), finalTrait.id.toNumber())
    // })
    // const traitURI = await packsContractProvider.getTraitURI(213)
    // console.log(traitURI)
    // const walletTraits = await packsContractProvider.getTraitsByWallet('0x8e5EdF86d193c536C915De0e305FdF88c8CCAeFb')
    // console.log(walletTraits[43].toNumber())
    // const walletPurchases = await packsContractProvider.getSpecificPackPurchasesByWallet('0x8e5EdF86d193c536C915De0e305FdF88c8CCAeFb', 3)
    // console.log(walletPurchases.toNumber())
    const getClaimsByWallet = await packsContractProvider.getClaimedGiftPacksByWallet('0xAAb309a911E19e6e6F6da7AEc32C5333aa60Ef6C', 2)
    console.log(getClaimsByWallet.toNumber())
    // const getWalletsByPackType = await packsContractProvider.getWalletsByPackType(3)
    // console.log(getWalletsByPackType)
    // const trait = await packsContractProvider.getTrait(213)
    // console.log(trait, trait.name, trait.amountMinted.toNumber(), trait.category.toNumber(), trait.maxSupply.toNumber(), trait.rarityTier.toNumber())
  }
  const changeTest = async () => {
    const walletPrivateKey = process.env.OWNER_WALLET
    const wallet = new ethers.Wallet(walletPrivateKey);
    const signer = wallet.connect(provider);

    let gasPrice = await provider.getGasPrice();

    // Increase the gas price by a certain factor to speed up transaction processing (optional)
    gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2
    const transactionParameters = {
        gasPrice: gasPrice,
        gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
        // ...other transaction parameters like 'to', 'value' and 'data'
    };
    const packsContract = new ethers.Contract(packsContractAddress, PacksABI, signer);
    const changeName = await packsContract.changePackTypeTotalSupply(1, 1533, transactionParameters)
    changeName.wait()
    .then((receipt) => {
      console.log('Approval successful!');
      console.log('Transaction receipt:', receipt);
    })
    .catch((error) => {
      console.error('Approval failed:', error);
      // props.setPopupState('transactionError')
    });

  }

  return (
    <div className="relative w-full h-full flex-grow flex-col flex px-10 lg:px-36 py-5 gap-4">
      {flash ? <div className="flash"></div> : ""}
      <div class="flex flex-col font-bold text-dark-gray items-start justify-start">
          {
            showReveal || showRevealTraits ?
            <>
              <h1 className="text-[8vw] sm:text-[4vw] uppercase">{packToReveal?.name}</h1>
              <div className="w-full h-0.5 bg-dark-gray"></div>
            </>
            :
            <h1 className="text-[8vw] sm:text-[4vw]">DROPS</h1>
          }
      </div>
      {
        showReveal || showRevealTraits ?
        ""
        :
        <div class ="flex flex-rows text-start items-center justify-start font-bold text-dark-gray text-[80%] sm:text-[100%] gap-16">
          <p onClick={()=>selectFeatured()} className={`${showFeatured ? 'text-primary-red' : ""}`}>FEATURED DROPS</p>
          <p onClick={()=>selectGiftDrops()} className={`${showGiftDrops ? 'text-primary-red' : ""}`}>GIFT DROPS</p>
        </div>
      }
      {
        renderPacks()
      }
      {/*<div className="">
        <button onClick = {() => giftAllPacks()}> gift packs </button>
        <br></br>
        <button onClick = {() => changeTest()}> get test </button>
      </div>*/}
    </div>

  )

}


export default Drops;
