import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {ethers} from 'ethers';
import { DynamicWidget, DynamicConnectButton } from '@dynamic-labs/sdk-react';
import { faQuestionCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PointsBar from "./pointsBar.js";
import '../../App.css'

const { Network, Alchemy } = require("alchemy-sdk");
var qs = require('qs');

const EarnPoints = (props) => {
  // user variables
  const [walletAddress, setWalletAddress] = useState()
  const [userTwitterID, setUserTwitterID] = useState()
  const [connectedTwitter, setConnectedTwitter] = useState(0)
  const [completedTweets, setCompletedTweets] = useState()
  const [reloadCompleted, setReloadCompleted] = useState(0);
  const [reloadMaxieData, setReloadMaxieData] = useState(0);
  const [maxieStats, setMaxieStats] = useState({'level': 'N/A', 'totalXP': 'N/A', 'totalMP': 'N/A'})
  const [isHolder, setIsHolder] = useState(false)

  // tweet variables
  const [liveTweets, setLiveTweets] = useState()
  const [portalView, setPortalView] = useState(0)
  const [reloadTweets, setReloadTweets] = useState(0);
  const [followerPortal, setFollowerPortal] = useState(false)
  const [maxiesToFollow, setMaxiesToFollow] = useState([])

  // boolean variables
  const [tweetsLoading, setTweetsLoading] = useState(true);

  // input variables
  const [tweetInput, setTweetInput] = useState('')

  // set public key & wallet type
  useEffect(() => {
    // console.log('got here')
    if (props.defaultAccount.address){
      if (!walletAddress){
        setWalletAddress(props.defaultAccount.address)
      }
      else {
        if (walletAddress !== props.defaultAccount.address){
          setWalletAddress(props.defaultAccount.address)
        }
      }
    }
    else{
      setWalletAddress()
      setConnectedTwitter(0)
      setCompletedTweets()
      setUserTwitterID()
      setIsHolder(false)
    }
  }, [props.defaultAccount.address])

  // fetch user twitter ID
  useEffect(() => {
    console.log(walletAddress, 'got here?')
    if (walletAddress){
      var data = JSON.stringify({
        "action": "getMaxieData",
        'walletAddress': walletAddress
      });
      // console.log(data)
      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(response.data)
          // setEngagementPoints(response.data.totalPoints)
          if (response.data.connectedTwitter){
            setConnectedTwitter(1)
            setUserTwitterID(response.data.twitterDict.twitterID)
          }
          if (response.data.discordDict.discordRoles['maxieHolder'] === 1){
            setIsHolder(true)
          }
          else {
            setIsHolder(false)
          }
          setMaxieStats(response.data.maxieStats)
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [walletAddress, reloadMaxieData]);

  // fetch tweets that user already engaged with
  useEffect(() => {
    if (walletAddress){
      var data = JSON.stringify({
        "action": "getUserEngagement",
        'walletAddress': walletAddress
      });
      // console.log(data)
      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data)
          setCompletedTweets(response.data.completedTweets)
          setTweetsLoading(false)
        })
        .catch(function (error) {
        // console.log(error);
      });
    }
  }, [walletAddress, reloadCompleted])

  // fetch all live tweets
  useEffect(() => {
    var data = JSON.stringify({
      "action": "getLiveTweetsNew"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response.data)
      setLiveTweets(response.data.liveTweets)
      // setTweetsLoading(false)
    })
    .catch(function (error){
      console.log(error)
    })
  }, [reloadTweets])

  // fetch all maxies eligible for follows
  useEffect(() => {
    var data = JSON.stringify({
      "action": "getMaxiesToFollow"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response.data)
      setMaxiesToFollow(response.data.maxiesToFollow)
    })
    .catch(function (error){
      console.log(error)
    })
  }, [])

  // render all live tweets here.
  const renderLiveTweets = useCallback(() => {
    return (
      <>
        <div class="flex flex-col justify-start text-start items-start w-full">
          <p class = "text-dark-gray font-text">A max of 20 tweets can be live at once.</p>
          <p class = "text-dark-gray font-text">Submit a twitter post ID (must mention @maxinNFT):</p>
          <div class="flex flex-row gap-2 w-full sm:w-[50%]">
            <input className="bg-white font-lekton border-2 border-dark-gray text-gray" type="string" placeholder='Twitter Post ID...' value={tweetInput} onChange={e => {handleChange(e)}}/>
            <button disabled={!walletAddress || !isHolder} onClick={() => inputTweet(tweetInput, walletAddress)} class="bg-primary-red rounded-xl text-[#EAE9E8] uppercase disabled:opacity-50 hover:opacity-50 w-full"> SUBMIT </button>
          </div>
        </div>
        <div class="flex flex-col w-full bg-dark-gray bg-opacity-20 overflow-y-scroll">
          <div class = "grid w-full h-full p-2 gap-1">
            <div class="grid grid-cols-6 w-full text-center items-center justify-center font-title-bold font-medium text-[10px] sm:text-[60%]">
              <p>Days to Expiration</p>
              <p class="col-span-2">Instructions</p>
              <p>Rewards</p>
            </div>
            {
              tweetsLoading ?
              <div className = "flex flex-rows items-center text-center justify-center w-full h-full min-h-[200px] font-title-bold text-dark-gray">
                <img
                className="h-5 w-5 mr-4 animate-spin"
                src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
                alt="Loading"
              />
                <p>connect wallet to access...</p>
              </div>
              :
              <>
              {
                liveTweets?.length ? (
                  <>
                  {
                    liveTweets.map((tweet, index) => {
                      // console.log(tweet)
                      return(
                        <div class="grid grid-cols-6 w-full bg-[#EAE9E8] text-center items-center justify-center font-title-bold font-medium text-[10px] sm:text-[80%] text-dark-gray p-1">
                          <p>{tweet.daysRemaining} day(s)</p>
                          <p class="col-span-2">{tweet.mustLike ? "Like ": ""} {tweet.mustRT ? "+ RT ": ""} {tweet.mustQuote ? "+ Quote RT ": ""} {tweet.addComment ? `+ Comment ${tweet.addComment}` : ""}</p>
                          <p>{tweet.rewardMP} MP <br/> {tweet.rewardXP} XP</p>
                          {
                            completedTweets?.hasOwnProperty(tweet.tweetID) ?
                            <div class ="flex flex-row justify-center items-center text-center col-span-2">
                              <button class="bg-gray rounded-lg text-[#EAE9E8] uppercase w-full p-2"> REWARDS CLAIMED </button>
                            </div>
                            :
                            <div class ="flex flex-row justify-center items-center text-center col-span-2 gap-2">
                              <a class="w-full" href={`https://twitter.com/maxinNFT/status/${tweet.tweetID}`} target="_blank" rel="noopener noreferrer">
                                <p class="bg-primary-red rounded-xl text-[#EAE9E8] uppercase hover:opacity-50 p-1">ENGAGE</p>
                              </a>
                              <button onClick={() => verifyEngagement(tweet.tweetID, tweet.rewardMP, tweet.rewardXP, tweet.mustLike, tweet.mustRT, tweet.mustQuote, userTwitterID)} class="bg-primary-yellow rounded-xl text-[#EAE9E8] uppercase hover:opacity-50 w-full p-1"> VERIFY </button>
                            </div>
                          }
                        </div>
                      )
                    })
                  }
                  </>
                ):
                (
                <p className="font-title-bold text-dark-gray text-[24px] text-center relative">
                  No Live Tweets
                </p>
                )
              }
              </>
            }
          </div>
        </div>
      </>
    )
  }, [walletAddress, portalView, liveTweets, connectedTwitter, userTwitterID, completedTweets, tweetInput, tweetsLoading])

  const renderMaxiesFollowMaxies = useCallback(() => {
    return (
      <div class="flex flex-col w-full max-h-[350px] bg-dark-gray bg-opacity-20">
          <div class ="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full h-full p-4 gap-4 overflow-y-scroll">
            {maxiesToFollow.map((maxieTwitter) => {
              return (
                <div class="flex flex-col w-full justify-start items-center text-center bg-dark-gray bg-opacity-20 border-2 border-gray p-2 gap-2">
                  <div className="w-24 h-24 rounded-full overflow-hidden">
                    <img src={maxieTwitter.twitterPFP} className="w-full h-full object-contain"/>
                  </div>
                  <p className="w-full text-center text-primary-red font-text font-bold text-[80%] truncate">
                    @{maxieTwitter.twitterHandle}
                  </p>
                  <a class="w-full" href={`https://twitter.com/${maxieTwitter.twitterHandle}`} target="_blank" rel="noopener noreferrer">
                    <p class="bg-primary-red rounded-xl text-[#EAE9E8] uppercase hover:opacity-50">FOLLOW</p>
                  </a>
                </div>
              )
            } )}
          </div>
      </div>
    )
  }, [maxiesToFollow])

  // function that calls the verification process api.
  const verifyEngagement = (tweetID, addedMP, addedXP, mustLike, mustRT, mustQuote, userID) => {
    props.setPopup(true)
    props.setPopupState('verifyingInstruction')
    var data = JSON.stringify({
      "action": "verifyEngagement",
      "walletAddress": walletAddress,
      "tweetID": tweetID,
      "addedMP": addedMP,
      "addedXP": addedXP,
      "userID": userID,
      "mustLike": mustLike,
      "mustRT": mustRT,
      "mustQuote": mustQuote
    });
    // console.log(data)
    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      if (response.data.statusCode === 200){
        setReloadMaxieData(reloadMaxieData + 1)
        setReloadCompleted(reloadCompleted + 1)
        props.setPopupState('rewardsClaimed')
      }
      else {
        // failed to verify data
        props.setPopupState('rewardsError')
      }

    })
    .catch(function (error){
      console.log(error)
      props.setPopupState('rewardsError')
    })
  }

  function handleChange(event) {
    // console.log(event.target.value)
    setTweetInput(event.target.value);
  }

  const inputTweet = (tweetID, walletAddress) => {
    if (walletAddress){
      // console.log(tweetID)
      props.setPopup(true)
      props.setPopupState('verifyingInput')
      var data = JSON.stringify({
        "action": "inputTweet",
        "tweetID": tweetID,
        "walletAddress": walletAddress
      });
      // console.log(data)
      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        if (response.data.statusCode === 200){
          setReloadTweets(reloadTweets + 1)
          props.setPopupState('tweetInputSuccess')
        }
        else {
          // failed to verify data
          // console.log(response.data.statusCode)
          props.setPopupState('tweetInputFailed')
        }
      })
      .catch(function (error){
        console.log(error)
        props.setPopupState('rewardsError')
      })
    }
    else{
      props.setPopup(true)
      props.setPopupState('tweetInputConnectWallet')
    }
  }

  return (
      <div className= "grid sm:grid-cols-[1fr,3fr] w-full h-full overflow-y-scroll gap-4 sm:gap-0">
          <PointsBar
            portal='earnPortal'
            level={maxieStats.level}
            totalXP={maxieStats.totalXP}
            totalMP={maxieStats.totalMP}
          />
          <div class ="flex flex-col w-full gap-4 overflow-y-scroll">
            <div class="flex flex-row w-full gap-8 items-center text-center justify-center text-full sm:justify-start sm:text-xl">
              <button onClick={() => setPortalView(0)}  class={`rounded-full ${portalView === 0 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
                Live Tweets
              </button>
              <button  onClick={() => setPortalView(1)} class={`rounded-full ${portalView === 1 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
                Maxies Follow Maxies
              </button>
            </div>
            {
            portalView ?
            <>
            <p class = "text-dark-gray font-text text-start">Only Maxie Holders over level 3 are featured here.</p>
            {renderMaxiesFollowMaxies()}
            </>
            :
            <>{renderLiveTweets()}</>
          }
          </div>
      </div>
  );
}

export default EarnPoints;
