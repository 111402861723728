import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PointsBar from "./pointsBar.js";

const PointsLeaderboards = (props) => {
    // user variables
    const [walletAddress, setWalletAddress] = useState()
    const [maxieStats, setMaxieStats] = useState({'level': 'N/A', 'totalXP': 'N/A', 'totalMP': 'N/A'})
    const [userRank, setUserRank] = useState()
    // leaderboard variable
    const [leaderboard, setLeaderboard] = useState()

    // set public key & wallet type
    useEffect(() => {
      // console.log('got here')
      if (props.defaultAccount.address){
        if (!walletAddress){
          setWalletAddress(props.defaultAccount.address)
        }
        else {
          if (walletAddress !== props.defaultAccount.address){
            setWalletAddress(props.defaultAccount.address)
          }
        }
      }
      else{
        setWalletAddress()
      }
    }, [props.defaultAccount.address])

    // useEffect to get the entire leaderboard
    useEffect(() => {
      var data = JSON.stringify({
        "action": "getLeaderboardsNew",
        "userWallet": walletAddress ? walletAddress : "none"
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        // console.log(response.data, walletAddress)
        setLeaderboard(response.data.leaderboards)
        if (walletAddress) {
          setUserRank(response.data.userRank)
        }
      })
      .catch(function (error){
        console.log(error)
      })
    }, [walletAddress])

    // fetch user data
    useEffect(() => {
      if (walletAddress){
        var data = JSON.stringify({
          "action": "getMaxieData",
          'walletAddress': walletAddress
        });
        // console.log(data)
        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
          .then(function (response) {
            setMaxieStats(response.data.maxieStats)
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }, [walletAddress]);

    const renderLeaderboard = useCallback(() => {
      return (
        <>
          <div class="hidden sm:block">
          <PointsBar
              portal='rankPortal'
              level={maxieStats.level}
              totalXP={maxieStats.totalXP}
              totalMP={maxieStats.totalMP}
              userRank={userRank}
            />
          </div>
          <div class="flex flex-col w-full overflow-y-scroll bg-dark-gray bg-opacity-20">
            <div class = "grid w-full p-2 gap-1 overflow-y-scroll">
              <div class="grid grid-cols-8 w-full text-center items-center justify-center font-title-bold font-medium text-[10px] sm:text-[60%]">
                <p>Rank</p>
                <p className="col-span-2">Twitter</p>
                <p className="col-span-2">Discord</p>
                <p>Current Level</p>
                <p>XP Gained</p>
                <p>Max Points</p>
              </div>
              {
                leaderboard?.map((user, index) => {
                return(
                  <div class="grid grid-cols-8 w-full bg-[#EAE9E8] text-center items-center justify-center font-title-bold font-medium text-[10px] sm:text-[80%] text-dark-gray p-1">
                    <p>{user.rank}</p>
                    <div class="col-span-2 flex flex-rows justify-center items-center text-center gap-1">
                      <img className = "w-[12%] h-[12%] rounded-full" src={user.twitterPFP}/>
                      <p class="truncate">{user.twitterHandle}</p>
                    </div>
                    <div class="col-span-2 flex flex-rows justify-center items-center text-center gap-1">
                      <img className = "w-[12%] h-[12%] rounded-full" src={user.discordPFP}/>
                      <p class="truncate">{user.discordHandle}</p>
                    </div>
                    <p>{user.level}</p>
                    <p>{user.totalXP}</p>
                    <p>{user.totalMP}</p>
                  </div>
                )
              })
              }
            </div>
          </div>
        </>
      )

    }, [leaderboard, maxieStats, userRank, walletAddress])

    return (
        <div className= "grid sm:grid-cols-[1fr,3fr] w-full h-full overflow-y-scroll">
          {renderLeaderboard()}
        </div>
    );
}

export default PointsLeaderboards;
