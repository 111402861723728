import MaxinHome from "../img/maxinHome.png"



const Home = ({ children, ...props }) => {

  return (
    <img className = "flex items-center justify-center" style={{ maxHeight: "calc(100vh - 150px)", objectFit: "contain" }} src={MaxinHome}/>
  )

}

export default Home;
