import React, { useCallback, useState, useEffect, useRef } from 'react';
import PointsIMG from "../../img/points-img.png"
import TweetItem from "./TweetItem.js"
import axios from 'axios';
import {ethers} from 'ethers';
import { DynamicWidget, DynamicConnectButton } from '@dynamic-labs/sdk-react';
import Carousel from 'react-elastic-carousel';
import { faQuestionCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../App.css'
import MaxinTraitsABI from '../../Components/maxinTraitsABI.json';

const { Network, Alchemy } = require("alchemy-sdk");

var qs = require('qs');

const Points = (props) => {

  // user variables
  const [walletType, setWalletType] = useState()
  const [walletAddress, setWalletAddress] = useState()
  const [notFetchedUser, setNotFetchedUser] = useState(true)
  const [walletNFTs, setWalletNFTs] = useState([])

  // social connection variables
  const [connectedTwitter, setConnectedTwitter] = useState(0);
  const [twitterDict, setTwitterDict] = useState();
  const [connectedDiscord, setConnectedDiscord] = useState(0);
  const [discordDict, setDiscordDict] = useState();
  const [engagementPoints, setEngagementPoints] = useState(0);
  const [discordRolesPoints, setDiscordRolesPoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);

  // render variables
  const [itemsToShow, setItemsToShow] = useState()
  const [leaderboard, setLeaderboard] = useState([])
  const [toolTipVisible, setToolTipVisible] = useState([])
  const [pageRank, setPageRank] = useState(0);
  const [liveTweets, setLiveTweets] = useState([])
  const [alreadyClaimed, setAlreadyClaimed] = useState([])
  const [discordUserRoles, setDiscordUserRoles] = useState({})

  // boolean variables
  const [onClaimsPortal, setOnClaimsPortal] = useState(false)
  const [onSpendPoints, setOnSpendPoints] = useState(false)
  const [portalView, setPortalView] = useState(0)
  const [fullRankings, setFullRankings] = useState(false)

  // trait contract/provider instantation
  const claimsContractAddress = "0x91c1953a6e9052583bd9954cde343a727eb38979"
  const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`);
  const claimsContract = new ethers.Contract(claimsContractAddress, MaxinTraitsABI, provider);

  // claimable traits variables
  const [claimableTraits, setClaimableTraits] = useState()

  // set public key & wallet type
  useEffect(() => {
    if (props.defaultAccount.address){
      if (!walletAddress){
        setWalletAddress(props.defaultAccount.address)
      }
      else {
        if (walletAddress !== props.defaultAccount.address){
          setNotFetchedUser(true)
          setWalletAddress(props.defaultAccount.address)
        }
      }
      // setprops.defaultAccount.address(props.defaultAccount.address)
      setWalletType(props.defaultAccount.connector.walletConnector.name)
    }
    else{
      setWalletAddress()
      setWalletType()
      setNotFetchedUser(true)
      setDiscordDict()
      setConnectedDiscord(0)
      setTwitterDict()
      setConnectedTwitter(0)
    }
  }, [props.defaultAccount.address])

  // fetch user data
  useEffect(() => {
    if (walletAddress && notFetchedUser){
      var data = JSON.stringify({
        "action": "getUserData",
        'walletAddress': walletAddress,
        "platform": "points"
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          setEngagementPoints(response.data.totalPoints)
          setNotFetchedUser(0)
          if (response.data.connectedTwitter){
            setConnectedTwitter(1)
            setTwitterDict(response.data.twitterDict)
          }
          else {
            const urlParams = new URLSearchParams(window.location.search);
            const oauth_token = urlParams.get('oauth_token');
            const oauth_verifier = urlParams.get('oauth_verifier');
            if (oauth_token && oauth_verifier){
              connectTwitter(oauth_token, oauth_verifier)
            }
          }
          if (response.data.connectedDiscord){
            setConnectedDiscord(1)
            setDiscordDict(response.data.discordDict)

          }
          else {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (code){
              connectDiscord(code)
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [walletAddress, notFetchedUser]);

  // get all NFTs owned by the user wallet.
  useEffect(() => {
    if (walletAddress){

      const address = walletAddress

      const baseURL = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`;
      const url = `${baseURL}/getNFTs/?owner=${address}`;

      const config = {
        method: 'get',
        url: url,
      };

      // Make the request and print the formatted response:
      axios(config)
        .then(function(response) {
          // console.log(response.data.ownedNfts)
          const filteredArray = response.data.ownedNfts.filter((nft) => nft.contract.address === "0x8396b098aee517efa1049cd7f3e05c48c19b1bae" || nft.contract.address === "0x91c1953a6e9052583bd9954cde343a727eb38979");
          // console.log(filteredArray)
          setWalletNFTs(filteredArray)
        })
        .catch(error => console.log('error', error));
    }

  }, [walletAddress]);

  // Get all points from Discord Roles and save in Database
  useEffect(() => {
    if (connectedDiscord) {
      var data = JSON.stringify({
        "action": "getPointsFromRoles",
        "discordID": discordDict.discordUserID
      });
      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log(response)
          setDiscordUserRoles(response.data.discordUserRoles)
          setDiscordRolesPoints(response.data.totalRolePoints)
        })
    }
  }, [connectedDiscord, discordDict])

  // get total points of user.
  useEffect(() => {
    setTotalPoints(engagementPoints + discordRolesPoints)
  }, [engagementPoints, discordRolesPoints])

  // get Leaderboard after total points of user is updated.
  useEffect(() => {
    var data = JSON.stringify({
      "action": "getLeaderboards"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setLeaderboard(response.data.leaderboards)
    })
    .catch(function (error){
      console.log(error)
    })
  }, [totalPoints])

  // set claimable traits array
  useEffect(() => {
    const fetchTraits = async () => {
      const traits = await claimsContract.getAllClaimTraits()
      if (traits) {
        // console.log(traits)
        let traitsTable = []
        let traitVisible = []
        for (const trait of traits) {
          if (!(trait.paused)){
            const traitURI = await claimsContract.getClaimTraitURI(traits.indexOf(trait))
            var config = {
              method: "get",
              url: traitURI,
              headers: {
                "Content-Type": "application/json",
              },
            };
            axios(config)
              .then(function (response) {
                // console.log(trait)
                traitsTable.push({
                  'traitID': traits.indexOf(trait),
                  'name': response.data.name,
                  'projectName': trait.projectName,
                  'image': response.data.image,
                  'amountMinted': trait.amountMinted.toNumber(),
                  'maxSupply': trait.maxSupply.toNumber(),
                  'maxMintsPerWallet': trait.maxMintsPerWallet.toNumber(),
                  'maxMintsPerDiscord': trait.maxMintsPerDiscord.toNumber(),
                  'points': trait.pointsNeeded.toNumber(),
                  'discordRoleIDNeeded': trait.discordRoleIDNeeded,
                })
                traitVisible.push(false)
                // console.log(response.data)
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }
        setClaimableTraits(traitsTable)
        setToolTipVisible(traitVisible)
      }
    };
    fetchTraits()
  }, [])

  // get all past & live tweets
  useEffect(() => {
    var data = JSON.stringify({
      "action": "getLiveTweets"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      setLiveTweets(response.data.liveTweets)
    })
    .catch(function (error){
      console.log(error)
    })
  }, [])

  useEffect(() => {
    const fetchAlreadyClaimed = async () => {
      if (connectedDiscord && claimableTraits?.length){
        let alreadyClaimedTemp = []
        for (const trait of claimableTraits){
          const amountClaimed = await claimsContract.getClaimDiscordMints(
            trait.traitID,
            discordDict.discordUserID
          )
          // console.log(amountClaimed.toNumber(), trait)
          if (amountClaimed.toNumber() === trait.maxMintsPerDiscord){
            alreadyClaimedTemp.push(trait.traitID)
          }
        }
        setAlreadyClaimed(alreadyClaimedTemp)
      }
    }

    fetchAlreadyClaimed()
  }, [connectedDiscord, discordDict, claimableTraits])

  // connect twitter
  const twitterRedirect = (response) => {
    if (walletAddress){
      var data = JSON.stringify({
        "action": "getTwitterRedirect",
        "platform": "points",
        'walletAddress': walletAddress
      });
      // console.log(data)
      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log(response)
          if (response.data.oauth_token){
            const redirectUrl = 'https://api.twitter.com/oauth/authenticate?oauth_token=' + response.data.oauth_token
            window.location.href = redirectUrl;
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
    else {
      console.log("connect wallet")
    }
  }
  const connectTwitter = async (oauthToken, verifier) => {

    var data = JSON.stringify({
      "action": "connectTwitter",
      "oauth_token": oauthToken,
      "oauth_verifier": verifier,
      "walletAddress": walletAddress,
      'platform': 'points'
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        setTwitterDict(response.data.twitterDict)
        setConnectedTwitter(1)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const disconnectTwitter = () => {
    var data = JSON.stringify({
      'action': 'disconnectTwitter',
      'walletAddress': walletAddress,
      'platform': 'points'
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        setTwitterDict()
        setConnectedTwitter(0)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // connect discord
  const connectDiscord = (code) => {
    // change this to back end
    if (walletAddress){
      let client_id = process.env.DISCORD_CLIENT_ID
      let client_secret = process.env.DISCORD_CLIENT_SECRET

      var data = qs.stringify({
        'client_id': client_id,
        'client_secret': client_secret,
        'grant_type': 'authorization_code',
        'code': code,
        'redirect_uri': 'https://www.maxinout.com/points'
      });

      var config = {
        method: 'post',
        url: 'https://discord.com/api/oauth2/token',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        // console.log(response)
        var data = JSON.stringify({
          'action': 'saveDiscordCredentials',
          'accessToken': response.data.access_token,
          'refreshToken': response.data.refresh_token,
          'walletAddress': walletAddress,
          'platform': "points"
        });
        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
          .then(function (response) {
            // console.log(response)
            setDiscordDict(response.data.discordDict)
            setConnectedDiscord(1)
          })
          .catch(function (error) {
            // console.log(error);
          });
      })
      .catch(function (error) {
        // console.log(error);
      })
    }
    else {
      console.log('connect wallet')
    }
  }
  const disconnectDiscord = () => {
    var data = JSON.stringify({
      'action': 'disconnectDiscord',
      'walletAddress': walletAddress,
      'platform': "points"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        setDiscordDict()
        setConnectedDiscord(0)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // render step 1 - connect socials
  const renderStartPage = useCallback(() => {
    return (
      <div class="w-full h-full flex flex-col justify-start items-center text-center gap-2 md:gap-0 sm:overflow-y-scroll p-2 sm:p-0">
        <div class="md:h-[33%] flex flex-col justify-center items-center text-center">
          <h1 class="font-bold text-[5vw] sm:text-[3.5vw] text-dark-gray uppercase"> CONNECT YOUR SOCIALS</h1>
          <p class="font-bold text-[3.5vw] sm:text-[1.5vw] text-dark-gray uppercase">AND EARN POINTS TO USE ON MAXIN PLATFORMS</p>
        </div>
        <div class="grid md:grid-cols-3 sm:gap-4 w-full md:h-[33%] sm:px-4">
          <div class="flex flex-col justify-center items-center text-center rounded-lg px-2 py-4 gap-2 sm:gap-1">
            <p class="text-[4vw] sm:text-[1.4vw] w-full h-[20%] font-text font-bold text-dark-gray uppercase sm:truncate">Connect Wallet</p>
            <div class="md:bg-dark-gray md:bg-opacity-20 w-full h-full flex items-center justify-center">
              <DynamicWidget/>
            </div>
          </div>
          <div class="flex flex-col justify-center items-center text-center rounded-lg px-2 py-4 gap-2 sm:gap-1">
            {
              connectedTwitter ?
              <div onClick={() => disconnectTwitter()} class="text-[4vw] sm:text-[1.4vw] w-full h-[20%] font-text font-bold text-dark-gray uppercase underline hover:text-primary-yellow truncate">
                DISCONNECT TWITTER
              </div>
              :
              <p class="text-[4vw] sm:text-[1.4vw] w-full h-[20%] font-text font-bold text-dark-gray uppercase">Connect Twitter</p>
            }
            {
                connectedTwitter ?
                  <div class="bg-dark-gray bg-opacity-20 w-full h-full flex items-center justify-center p-4 gap-2">
                    <div class="flex-1 truncate text-xs justify-center items-start text-start w-full">
                      <p class="flex-1 truncate font-bold text-bold text-dark-gray uppercase">Handle:</p>
                      <p class="flex-1 truncate font-bold text-bold text-primary-red">{twitterDict.twitterHandle}</p>
                    </div>
                    <img className="w-[30%] sm:w-[50%] object-cover rounded-full" alt="TwitterPFP" src={twitterDict.twitterPFP}/>
                  </div>
                :
                <div class="md:bg-dark-gray md:bg-opacity-20 w-full h-full flex items-center justify-center">
                  <button disabled={!walletAddress} onClick={() => twitterRedirect()} className="flex items-center bg-dark-gray font-gilroy-regular text-1.7vw text-primary-yellow px-8 py-[12px] rounded-xl drop-shadow-lg border border-white hover:bg-gray-100 hover:cursor-pointer">
                    Connect Twitter
                  </button>
                </div>
              }
          </div>
          <div class="flex flex-col justify-center items-center text-center rounded-lg px-2 py-4 gap-2 sm:gap-1">
            {
              connectedDiscord ?
              <div onClick={() => disconnectDiscord()} class="text-[4vw] sm:text-[1.4vw] w-full h-[20%] font-text font-bold text-dark-gray uppercase underline hover:text-primary-yellow hover:cursor-pointer truncate">
                DISCONNECT DISCORD
              </div>
              :
              <p class="text-[4vw] sm:text-[1.4vw] w-full h-[20%] font-text font-bold text-dark-gray uppercase">Connect Discord</p>
            }
            {
                  connectedDiscord ?
                    <div class="bg-dark-gray bg-opacity-20 w-full h-full flex items-center justify-center p-4 gap-2">
                      <div class="flex-1 truncate text-xs justify-center items-start text-start w-full">
                        <p class="flex-1 truncate font-bold text-bold text-dark-gray uppercase">Handle:</p>
                        <p class="flex-1 truncate font-bold text-bold text-primary-red">{discordDict.discordHandle}</p>
                      </div>
                      <img className="w-[30%] sm:w-[50%] object-cover rounded-full" alt="TwitterPFP" src={discordDict.discordPFP}/>
                    </div>
                  :
                  <div class="md:bg-dark-gray md:bg-opacity-20 w-full h-full flex items-center justify-center">
                    <button disabled={!walletAddress} onClick={() => window.location.href = "https://discord.com/api/oauth2/authorize?client_id=1109141234762645504&redirect_uri=https%3A%2F%2Fwww.maxinout.com%2Fpoints&response_type=code&scope=identify%20guilds%20email%20connections"} className="flex items-center bg-dark-gray font-gilroy-regular text-1.7vw text-primary-yellow px-8 py-[12px] rounded-xl drop-shadow-lg border border-white hover:bg-gray-100 hover:cursor-pointer">
                      Connect Discord
                    </button>
                  </div>
                }
          </div>
        </div>
        <div class="md:h-[33%] w-full flex justify-center items-center">
          {
            walletAddress && connectedTwitter && connectedDiscord ?
            <button onClick={()=>setOnClaimsPortal(true)} class="text-[3vw] sm:text-[1.5vw] py-1 px-12 rounded-full bg-dark-gray font-text font-bold text-bold text-primary-yellow text-center shadow-2xl hover:text-dark-gray hover:bg-primary-yellow" >
              ENTER CLAIMS PORTAL
            </button>
            :
            ""
          }
        </div>
      </div>
    )
  }, [walletAddress, walletNFTs, connectedDiscord, connectedTwitter])

  // render step 2 - claims portal
  const carouselRef = useRef();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) { // adjust this value to your needs
        setItemsToShow(1);
      }
      else if (window.innerWidth < 1200) { // adjust this value to your needs
        setItemsToShow(2);
      }
      else {
        setItemsToShow(3);
      }
    };

    if (window.innerWidth < 600) {
        setItemsToShow(1);
      }
    else if (window.innerWidth < 1000) {
      setItemsToShow(2);
    }
    else {
      setItemsToShow(3);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const filteredNFTsLength = walletNFTs.filter((nft) => nft.contract.address === "0x8396b098aee517efa1049cd7f3e05c48c19b1bae" || nft.contract.address === "0x91c1953a6e9052583bd9954cde343a727eb38979").length;
  const totalPages = Math.ceil(filteredNFTsLength / itemsToShow)
  let resetTimeout;

  const [remainingTimes, setRemainingTimes] = useState([]);

  const claimsPortal = useCallback(() => {
    return(
      <div class="w-full h-full flex flex-col justify-center items-center text-center p-8 overflow-y-scroll gap-4">
        <div class="grid grid-cols-3 sm:grid-cols-4 h-full w-full gap-4 sm:h-[30%]">
          <div class="flex flex-col justify-center items-center text-center col-span-1 bg-dark-gray bg-opacity-20 rounded-lg p-1 w-full">
              <p class="text-full md:text-[120%] w-full font-text font-bold text-dark-gray uppercase">
                <span class="flex items-center justify-center h-full">
                  Engagement <br/> Points
                </span>
              </p>
              <p class="text-[300%] w-full font-text font-bold text-primary-red">
                <span class="flex items-center justify-center h-full">
                  {totalPoints}
                </span>
              </p>
          </div>
          <div class="flex flex-col justify-center items-start text-start col-span-2 sm:col-span-3">
            <h1 class="font-bold text-[4vw] sm:text-[1vw] text-dark-gray uppercase">HOW TO EARN POINTS:</h1>
            <p class="font-bold text-[2vw] sm:text-[0.8vw] text-dark-gray uppercase">POINTS ARE EARNED THROUGH DISCORD ROLES, TWITTER RAIDS, AND USING MAXIN PRODUCTS.</p>
            <br></br>
            <h1 class="font-bold text-[4vw] sm:text-[1vw] text-dark-gray uppercase">WHAT ARE POINTS USED FOR:</h1>
            <p class="font-bold text-[2vw] sm:text-[0.8vw] text-dark-gray uppercase">POINTS CAN BE USED TO REDEEM TRAITS, TO PURCHASE FREE RAFFLE TICKETS, AND TO GAIN ACCESS TO WL FOR THE MAXIN MINT</p>
          </div>
        </div>
        <p class="w-full uppercase text-[50%] text-dark-gray font-bold flex text-start items-start justify-start">*POINTS FROM TWITTER ENGAGEMENT ARE ADDED ONCE TIMER RUNS OUT ON TWEET*</p>
        <div class="flex w-full items-center sm:gap-8 flex-col sm:flex-row sm:h-[10%]">
          <button onClick={() => setPortalView(0)}  class={`text-xl py-1 px-4 rounded-full ${portalView === 0 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
            My Traits
          </button>
          <button  onClick={() => setPortalView(1)} class={`text-xl py-1 px-4 rounded-full ${portalView === 1 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
            Leaderboards
          </button>
          <button  onClick={() => setPortalView(2)} class={`text-xl py-1 px-4 rounded-full ${portalView === 2 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
            Live Tweets
          </button>
        </div>
        <div class="flex flex-col w-full justify-center items-center text-center min-h-[320px] sm:min-h-[40%] sm:h-[40%]">
          {
            portalView === 0 ?
            <>
            {walletNFTs?.length ? (
            <Carousel
                ref={carouselRef}
                enableAutoPlay
                autoPlaySpeed={4000}
                itemsToShow={itemsToShow}
                pagination={false}
                itemPadding={[0, 10]}
                onNextEnd={({ index }) => {
                 clearTimeout(resetTimeout)
                 if (index + 1 === totalPages) {
                    resetTimeout = setTimeout(() => {
                       if (carouselRef.current) {
                         carouselRef.current.goTo(0)
                       }
                   }, 4000) // same time
                 }
               }}
                onNextStart={(currentItem, nextItem) => {
                  if (currentItem.index === nextItem.index) {
                    // we hit the last item, go to first item
                    if (carouselRef.current) {
                      carouselRef.current.goTo(0)
                    }
                  }
                }}
                onPrevStart={(currentItem, nextItem) => {
                  if (currentItem.index === nextItem.index) {
                    // we hit the first item, go to last item
                    if (carouselRef.current) {
                      carouselRef.current.goTo(filteredNFTsLength);
                    }
                  }
                }}
                disableArrowsOnEnd={false}
                showEmptySlots={true}
              >
                {walletNFTs.filter((nft) => nft.contract.address === "0x8396b098aee517efa1049cd7f3e05c48c19b1bae" || nft.contract.address === "0x91c1953a6e9052583bd9954cde343a727eb38979").map((nft) => {
                    return (
                      <div class="flex flex-col items-center justify-center bg-dark-gray bg-opacity-20 rounded-xl w-[95%]">
                        <img
                          alt="NFTs"
                          src={`${
                            nft.media[0].gateway
                          }?${new Date().getTime()}`}
                          className="cursor-pointer object-cover bg-[#EAE9E8] w-full rounded-t-xl"
                        />

                        <p className="w-full text-start p-2 text-gray-deep font-text text-[80%] truncate">
                          <span> trait name: </span>
                          <br></br>
                          <span class="uppercase font-bold">{nft.metadata.name}</span>
                        </p>
                      </div>
                    );
                })}
              </Carousel>
          ) : (
              <p className="font-title-bold text-dark-gray text-[24px] text-center relative">
                You have no Traits available in your wallet.
              </p>
            )}
            </>
            :
            <>
            {
              portalView === 1 ?
              <div class="flex flex-col w-full h-full">
                <div class = "grid grid-rows-8 w-full h-full bg-dark-gray bg-opacity-20 p-2 gap-1">
                  <div class="grid grid-cols-6 w-full text-center items-center justify-center font-title-bold font-medium text-[80%]">
                    <p>rank</p>
                    <p>points</p>
                    <p class="col-span-2">twitter</p>
                    <p class="col-span-2">discord</p>
                  </div>
                  {
                    leaderboard?.length ? (
                      <>
                      {
                        leaderboard.filter((user, index) => index <= 4).map((user) => {
                          return(
                            <div class="grid grid-cols-6 w-full bg-[#EAE9E8] text-center items-center justify-center font-title-bold font-medium text-[80%] sm:text-[80%] text-dark-gray">
                              <p>{user.rank}</p>
                              <p>{user.totalPoints ? user.totalPoints : 0}</p>
                              <p class="col-span-2 truncate">{user.twitterHandle}</p>
                              <p class="col-span-2 truncate">{user.discordHandle}</p>
                            </div>
                          )
                        })
                      }
                      </>
                    ): (
                    <p className="font-title-bold text-dark-gray text-[24px] text-center relative">
                      Leaderboard not yet established.
                    </p>
                    )
                  }
                </div>
                <div onClick={() => setFullRankings(true)} class='flex text-start items-center justify-start gap-2 text-dark-gray font-title-bold hover:text-primary-yellow'>
                  <p class="">go to full rankings</p>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
              :
              <div class="flex flex-col w-full max-h-[300px] overflow-y-scroll bg-dark-gray bg-opacity-20">
                <div class = "grid w-full h-full p-2 gap-1">
                  <div class="grid grid-cols-7 w-full text-center items-center justify-center font-title-bold font-medium text-[10px] sm:text-[80%]">
                    <p class="col-span-2">Time Remaining</p>
                    <p class="col-span-2" >Tweet ID</p>
                    <p>Points<br/> for Like</p>
                    <p>Points <br/>for RT</p>
                    <p>Points for <br/>Quote RT</p>
                  </div>
                  {
                    liveTweets?.length ? (
                      <>
                      {
                        liveTweets.filter((tweet) => tweet.claimsEnded === 0).map((tweet, index) => {
                          return(
                            <TweetItem
                              key={`${tweet.tweetID}-${index}`}
                              endDate={tweet.endDate}
                              tweetID={tweet.tweetID}
                              likePoints={tweet.likePoints}
                              rtPoints={tweet.rtPoints}
                              replyPoints={tweet.replyPoints}
                              claimsEnded={tweet.claimsEnded}
                            />
                          )
                        })
                      }
                      </>
                    ):
                    (
                    <p className="font-title-bold text-dark-gray text-[24px] text-center relative">
                      No Live Tweets
                    </p>
                    )
                  }
                </div>
              </div>
            }
            </>
          }
        </div>
        <div class="w-full flex justify-center items-center sm:h-[20%]">
          <button onClick={()=>setOnSpendPoints(true)} class="text-[3vw] lg:text-[1.5vw] py-1 px-12 rounded-full bg-dark-gray font-text font-bold text-bold text-primary-yellow text-center shadow-2xl hover:text-dark-gray hover:bg-primary-yellow" >
            CLAIM TRAITS
          </button>
        </div>
      </div>
    )
  }, [walletAddress, walletNFTs, itemsToShow, portalView, totalPoints]);

  // render full leaderboard
  const rankPortal = () => {
    const onNext = () => {
      setPageRank(prevPage => prevPage + 1); // Increase page number by 1.
    };
    const onPrev = () => {
      setPageRank(prevPage => Math.max(prevPage - 1, 0)); // Decrease page number by 1, but not less than 0.
    };
    const leaderboardPage = leaderboard?.slice(pageRank * 10, (pageRank + 1) * 10); // Get the part of the leaderboard for the current page.
    const totalRankPages = Math.ceil(leaderboard?.length / 10) - 1;

    return(
      <div class="w-full h-full flex flex-col justify-start items-center text-center p-8 gap-1">
        <div class = "grid sm:grid-cols-3 w-full">
          <div>
          </div>
          <p class="font-bold text-[4vw] sm:text-[1.5vw] text-dark-gray uppercase">LEADERBOARD</p>
          <div onClick={() => setFullRankings(false)} class='flex w-full text-end items-center text-[12px] sm:text-[1vw] justify-end gap-2 text-dark-gray font-title-bold hover:text-primary-yellow hover:cursor-pointer'>
            <p class="">go back to points</p>
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
        <div class = "grid grid-rows-8 w-full h-full bg-dark-gray bg-opacity-20 p-2 gap-1">
          <div class="grid grid-cols-6 w-full font-title-bold font-medium text-[80%] text-center justify-center items-center">
            <p>rank</p>
            <p>points</p>
            <p class="col-span-2 truncate">twitter</p>
            <p class="col-span-2 truncate">discord</p>
          </div>
          {
            leaderboardPage?.length ? (
              <>
              {
                leaderboardPage.map((user) => {
                  return(
                    <div class="grid grid-cols-6 w-full bg-[#EAE9E8] font-title-bold font-medium text-[60%] sm:text-[80%] text-dark-gray text-center justify-center items-center truncate">
                      <p>{user.rank}</p>
                      <p>{user.totalPoints ? user.totalPoints : 0}</p>
                      <p class="col-span-2 truncate">{user.twitterHandle}</p>
                      <p class="col-span-2 truncate">{user.discordHandle}</p>
                    </div>
                  )
                })
              }
              </>
            ): (
            <p className="font-title-bold text-dark-gray text-[24px] text-center relative">
              Leaderboard not yet established.
            </p>
            )
          }
        </div>
        <div class="flex justify-center items-center text-center gap-4 text-dark-gray uppercase font-title-bold">
          <button class="uppercase hover:text-primary-yellow hover:cursor-pointer" onClick={onPrev} disabled={pageRank <= 0}>Previous</button>
          <button class="uppercase hover:text-primary-yellow hover:cursor-pointer" onClick={onNext} disabled={pageRank >= totalRankPages}>Next</button>
        </div>
      </div>
    )
  }

  const handleMouseEnter = (index) => {
    setToolTipVisible(toolTipVisible.map((item, idx) => idx === index ? true : item));
  }
  const handleMouseLeave = (index) => {
      setToolTipVisible(toolTipVisible.map((item, idx) => idx === index ? false : item));
  }

  // render step 3 - spend portal
  const spendPortal = useCallback(() => {
    return (
      <div class="w-full h-full flex flex-col justify-start items-center text-center p-8">
        <div class="flex flex-col md:grid sm:grid-cols-4 gap-4 w-full h-full">
          <div class = "flex flex-col justify-start items-center text-start sm:col-span-1 h-full gap-4">
            <div class="flex flex-col bg-dark-gray bg-opacity-20 justify-center items-center text-center rounded-lg px-2 py-4 w-full md:h-[30%]">
              <p class="text-full md:text-[120%] w-full font-text font-bold text-dark-gray uppercase">
                <span class="flex items-center justify-center h-full">
                  Engagement <br/> Points
                </span>
              </p>
              <p class="text-[300%] w-full font-text font-bold text-primary-red">
                <span class="flex items-center justify-center h-full">
                  {totalPoints}
                </span>
              </p>
            </div>
            <button onClick={()=>setOnSpendPoints(false)} class="md:text-[0.8vw] py-1 px-2 rounded-full w-full bg-dark-gray font-text font-bold text-bold text-primary-yellow text-center shadow-2xl hover:text-dark-gray hover:bg-primary-yellow" >
              BACK TO MY POINTS
            </button>
          </div>
          <div class="flex flex-col justify-start items-start text-start sm:col-span-3 h-full">
            <h1 class={`text-xl rounded-full text-primary-red font-bold text-bold font-text uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>CLAIM TRAITS:</h1>
            <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-3 w-full justify-center items-center text-center gap-4 max-h-[50vh] overflow-y-scroll">
              {
                claimableTraits.map((nft, index) => {
                  return (
                    <div key={index} class="relative flex flex-col items-center justify-center bg-dark-gray bg-opacity-20 w-full h-full p-2 gap-2">
                      {
                        toolTipVisible[index] && (
                        <div className="absolute flex flex-col items-start justify-start text-start text-[10px] bg-white text-dark-gray p-1 rounded-md top-6 right-2 w-[60%]">
                          <div><span class="font-bold">Required Role</span>: {nft.discordRoleIDNeeded === 'none' ? 'None' : discordUserRoles[nft.discordRoleIDNeeded][1]}</div>
                          <div><span class="font-bold">Required Points</span>: {nft.points}pts</div>
                          <div><span class="font-bold">Supply</span>: {nft.maxSupply - nft.amountMinted}/{nft.maxSupply}</div>
                        </div>)
                      }
                      <div className = "flex flex-col items-center justify-start w-full">
                        <div className="flex justify-between items-center w-full text-start text-gray-deep font-text text-[70%]">
                          <span> trait name: </span>
                          <FontAwesomeIcon
                          icon={faQuestionCircle}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                          />
                        </div>
                        <p className="w-full text-start text-gray-deep uppercase font-bold text-[60%] truncate">
                          {nft.name}
                        </p>
                      </div>
                      <img
                        alt="NFTs"
                        src={`${
                          nft.image
                        }?${new Date().getTime()}`}
                        className="cursor-pointer object-cover bg-[#EAE9E8] w-full"
                      />
                      {
                        alreadyClaimed.includes(nft.traitID) ?
                        <button
                            className="w-[80%] h-full py-1 bg-[#793F35] rounded-xl text-white uppercase font-bold text-[70%] shadow-xl"
                          >
                            CLAIMED
                        </button>
                        :
                        <>
                        {
                          nft.points <= totalPoints && (nft.discordRoleIDNeeded === 'none' || discordUserRoles[nft.discordRoleIDNeeded][0] === 1) ?
                          <button
                              onClick={() => traitClaim(nft.traitID)}
                              className="w-[80%] h-full py-1 bg-primary-red rounded-xl text-white uppercase font-bold text-[70%] shadow-xl"
                            >
                              CLAIM
                          </button>
                          :
                          <button
                              className="w-[80%] h-full py-1 bg-gray rounded-xl text-white uppercase font-bold text-[70%] shadow-xl"
                            >
                              INELIGIBLE
                          </button>

                        }
                        </>
                      }
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }, [walletAddress, walletNFTs, totalPoints, claimableTraits, toolTipVisible, alreadyClaimed, discordUserRoles])

  const traitClaim = useCallback(async (traitID) => {
    props.setPopup(true)
    props.setPopupState('claimingInProgress')

    const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`);
    const walletPrivateKey = process.env.OWNER_WALLET
    const wallet = new ethers.Wallet(walletPrivateKey);
    const signer = wallet.connect(provider);

    let gasPrice = await provider.getGasPrice();

    // Increase the gas price by a certain factor to speed up transaction processing (optional)
    gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2

    // Define transaction parameters
    const transactionParameters = {
        gasPrice: gasPrice,
        gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
        // ...other transaction parameters like 'to', 'value' and 'data'
    };

    const claimsContract = new ethers.Contract(claimsContractAddress, MaxinTraitsABI, signer);

    claimsContract.traitClaims(
      walletAddress,
      traitID,
      1,
      discordDict.discordUserID,
      transactionParameters
    )
    .then((receipt) => {
      setAlreadyClaimed((prevClaimed) => [...prevClaimed, traitID])
      console.log('Approval successful!');
      console.log('Transaction receipt:', receipt);
      props.setPopupState('claimApproved')
    })
    .catch((error) => {
      console.error('Approval failed:', error);
      // props.setPopupState('transactionError')
    });

  }, [walletAddress, walletType, claimableTraits, connectedDiscord, connectedTwitter, discordDict])

  // const testContract = async () => {
  //     const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`);
  //     const walletPrivateKey = process.env.OWNER_WALLET;
  //     const wallet = new ethers.Wallet(walletPrivateKey);
  //     const signer = wallet.connect(provider);
  //
  //     let gasPrice = await provider.getGasPrice();
  //
  //     // Increase the gas price by a certain factor to speed up transaction processing (optional)
  //     gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2
  //
  //     // Define transaction parameters
  //     const transactionParameters = {
  //         gasPrice: gasPrice,
  //         gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
  //         // ...other transaction parameters like 'to', 'value' and 'data'
  //     };
  //
  //     const claimTestContract = new ethers.Contract(claimsContractAddress, MaxinTraitsABI, signer);
  //
  //     claimTestContract.ct_changePointsNeeded(
  //       2,
  //       0,
  //       transactionParameters
  //     )
  //     .then((receipt) => {
  //       console.log('approved transaction', receipt)
  //     }).catch((error) => {
  //       console.error('cancel failed:', error);
  //     })
  // }

  return (
    <div className="grid lg:grid-cols-[30%,70%] items-center justify-center mx-auto sm:w-[80%] md:h-[80%] gap-4 bg-[#D9D9D9]">
      <div class="hidden lg:block w-full h-full">
        <img src={PointsIMG} class="w-full h-full object-cover mt-[3vh]"/>
      </div>
      {
        onClaimsPortal ?
        <>
        {
          onSpendPoints ?
          <>{spendPortal()}</>
          :
          <>
            {
              fullRankings ?
              rankPortal()
              :
              claimsPortal()
            }
          </>
        }
        </>
        :
        <>
        {renderStartPage()}
        </>
      }
      {/*<button onClick={() => testContract()}> test </button>*/}
    </div>
  )

}

export default Points;
