import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {ethers} from 'ethers';
import { DynamicWidget, DynamicConnectButton } from '@dynamic-labs/sdk-react';
import { faQuestionCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../App.css'
import MaxinTraitsABI from '../../Components/maxinTraitsABI.json';
import PacksABI from '../../Components/packsABI.json';
import PointsBar from "./pointsBar.js";
import CommonOpening from '../../img/commonOpening.mp4';
import RareOpening from '../../img/rareOpening.mp4';
import LegOpening from '../../img/legOpening.mp4';

const { Network, Alchemy } = require("alchemy-sdk");

var qs = require('qs');

const SpendPoints = (props) => {
    // user variables
    const [walletAddress, setWalletAddress] = useState()
    const [reloadCompleted, setReloadCompleted] = useState(0);
    const [reloadMaxieData, setReloadMaxieData] = useState(0);
    const [maxieStats, setMaxieStats] = useState({'level': 'N/A', 'totalXP': 'N/A', 'totalMP': 'N/A'})
    const [connectedDiscord, setConnectedDiscord] = useState(0)
    const [discordDict, setDiscordDict] = useState();

    // boolean variables
    const [portalView, setPortalView] = useState(0)
    const [loadingTraits, setLoadingTraits] = useState(true)
    const [loadingClaim, setLoadingClaim] = useState(true)
    const [loadingLunchboxes, setLoadingLunchboxes] = useState(true)
    const [loadingClaimLB, setLoadingClaimLB] = useState(true)
    const [clickedPurchased, setClickedPurchased] = useState(false)
    const [clickedPurchasedLB, setClickedPurchasedLB] = useState(false)
    const [reloadLBs, setReloadLBs] = useState(0)

    // claimable traits variables
    const [claimableTraits, setClaimableTraits] = useState()
    const [toolTipVisible, setToolTipVisible] = useState([])
    const [discordUserRoles, setDiscordUserRoles] = useState({
      '1098467311532388433': [0, 'Maxie'],
      '1098467311532388436': [0, 'Maxie Holder'],
      '1146541818784272414': [0, 'Maxie Mini Whale'],
      '1146091269664296970': [0, 'Maxie Whale'],
      '1146091536312959107': [0, 'Maxie Mega Whale']
    })
    const [alreadyClaimed, setAlreadyClaimed] = useState([])

    // claimable lunchbox variables
    const [allPacks, setAllPacks] = useState()

    // Instantiating provider
    const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`);
    // Insantiating claims contract.
    const claimsContractAddress = "0x91c1953a6e9052583bd9954cde343a727eb38979"
    const claimsContract = new ethers.Contract(claimsContractAddress, MaxinTraitsABI, provider);
    // Instantiating lunchbox contract.
    const packsContractAddress = "0x0dB6aD9ce8f2299ef23E06AB55A6E126878855B3"
    const packsContractProvider = new ethers.Contract(packsContractAddress, PacksABI, provider);

    // set public key & wallet type
    useEffect(() => {
      // console.log('got here')
      if (props.defaultAccount.address){
        if (!walletAddress){
          setWalletAddress(props.defaultAccount.address)
        }
        else {
          if (walletAddress !== props.defaultAccount.address){
            setWalletAddress(props.defaultAccount.address)
          }
        }
      }
      else{
        setWalletAddress()
      }
    }, [props.defaultAccount.address])

    // fetch user data
    useEffect(() => {
      if (walletAddress){
        var data = JSON.stringify({
          "action": "getMaxieData",
          'walletAddress': walletAddress
        });
        // console.log(data)
        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
          .then(function (response) {
            if (response.data.connectedDiscord){
              setConnectedDiscord(1)
              setDiscordDict(response.data.discordDict)
              const discordRoles = response.data.discordDict.discordRoles
              let discordRolesID = {}
              Object.keys(discordRoles).map((role) => {
                if (role === 'maxie'){
                  discordRolesID['1098467311532388433'] = [discordRoles[role], 'Maxie']
                }
                if (role === 'maxieHolder'){
                  discordRolesID['1098467311532388436'] = [discordRoles[role], 'Maxie Holder']
                }
                if (role === 'miniWhale'){
                  discordRolesID['1146541818784272414'] = [discordRoles[role], 'Maxie Mini Whale']
                }
                if (role === 'whale'){
                  discordRolesID['1146091269664296970'] = [discordRoles[role], 'Maxie Whale']
                }
                if (role === 'megaWhale'){
                  discordRolesID['1146091536312959107'] = [discordRoles[role], 'Maxie Mega Whale']
                }
              })
              setDiscordUserRoles(discordRolesID)
            }
            // console.log(discordUserRoles)
            // setDiscordUserRoles(discordRolesID)
            setMaxieStats(response.data.maxieStats)
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
    }, [walletAddress, reloadMaxieData]);
    // set claimable traits array
    useEffect(() => {
      const fetchTraits = async () => {
        const traits = await claimsContract.getAllClaimTraits()
          if (traits) {
            // Create an array of promises for non-paused traits
            const promises = traits.map(async (trait, index) => {
              if (!trait.paused) {
                const traitURI = await claimsContract.getClaimTraitURI(index);

                // Axios config. Note that returning the axios request
                // directly makes this entire function a Promise, which
                // is necessary for Promise.all() to work correctly
                return axios({
                  method: "get",
                  url: traitURI,
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then(response => {
                  // Process response here, but we will NOT update the state directly
                  // Instead, we'll return the new trait data which will be processed later
                  return {
                    traitID: index,
                    name: response.data.name,
                    projectName: trait.projectName,
                    image: response.data.image,
                    amountMinted: trait.amountMinted.toNumber(),
                    maxSupply: trait.maxSupply.toNumber(),
                    maxMintsPerWallet: trait.maxMintsPerWallet.toNumber(),
                    maxMintsPerDiscord: trait.maxMintsPerDiscord.toNumber(),
                    points: trait.pointsNeeded.toNumber(),
                    discordRoleIDNeeded: trait.discordRoleIDNeeded,
                  };
                })
                .catch(error => {
                  console.error(error);
                  return null;  // Return null or some error data to handle later
                });
              }
              return null;  // Also return null for paused traits
            });

            // Once all promises are resolved, process the array of resolved trait data
            Promise.all(promises).then(traitsData => {
              const traitsTable = traitsData.filter(data => data !== null);  // Remove null/error data
              const traitVisible = traitsTable.map(() => false);  // Create an array of false values

              // Update the state
              setClaimableTraits(traitsTable);
              setToolTipVisible(traitVisible);
              setLoadingTraits(false);
            });
          }
      };
      fetchTraits()
    }, [])
    // set already claimed traits
    useEffect(() => {
      const fetchAlreadyClaimed = async () => {
        if (connectedDiscord && claimableTraits?.length && walletAddress){
          let alreadyClaimedTemp = []
          for (const trait of claimableTraits){
            const amountClaimedByDiscord = await claimsContract.getClaimDiscordMints(
              trait.traitID,
              discordDict.discordUserID
            )
            if (amountClaimedByDiscord.toNumber() >= trait.maxMintsPerDiscord){
              alreadyClaimedTemp.push(trait.traitID)
            }
            // console.log(amountClaimedByDiscord.toNumber(), trait)
            const amountClaimedByWallet = await claimsContract.getClaimWalletMints(
              trait.traitID,
              walletAddress
            )
            if (amountClaimedByWallet.toNumber() >= trait.maxMintsPerWallet){
              alreadyClaimedTemp.push(trait.traitID)
            }
            // console.log(amountClaimedByWallet.toNumber(), trait)

          }
          // console.log(alreadyClaimedTemp)
          setAlreadyClaimed(alreadyClaimedTemp)
          setLoadingClaim(false)
          setLoadingClaimLB(false)
        }
        // setLoadingTraits(false)
      }
      fetchAlreadyClaimed()
    }, [claimableTraits, connectedDiscord, walletAddress, reloadMaxieData])

    // set claimable lunchboxes
    useEffect(() => {
      const fetchData = async () => {
        const allPacks =  await packsContractProvider.getAllPackTypes()
        // console.log(allPacks)
        const indexedPacks = allPacks.map((pack, index) => ({
          ...pack,
          packID: index+1
        }));
        // console.log(indexedPacks)
        setAllPacks(indexedPacks)
        setLoadingLunchboxes(false)
      }
      fetchData()
    }, [reloadLBs])

    // function to view extra info on claimable traits upon mouse hover
    const handleMouseEnter = (index) => {
      setToolTipVisible(toolTipVisible.map((item, idx) => idx === index ? true : item));
    }
    const handleMouseLeave = (index) => {
        setToolTipVisible(toolTipVisible.map((item, idx) => idx === index ? false : item));
    }

    // render traits to claim
    const renderClaimTraits = useCallback(() => {
      return (
        <div class="flex flex-col w-full overflow-y-scroll">
          {
              loadingTraits ?
              <div className = "flex items-center text-center justify-center w-full h-full min-h-[350px]">
                <img
                  className="w-[10%] animate-spin"
                  src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
                  alt="Loading"
                />
              </div>
              :
              <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full justify-center items-center text-center gap-4 overflow-y-scroll">
              {
                claimableTraits?.map((nft, index) => {
                  return (
                    <div key={index} class="relative flex flex-col items-center justify-center bg-dark-gray bg-opacity-20 w-full h-full p-2 gap-1">
                      {
                        toolTipVisible[index] && (
                        <div className="absolute flex flex-col items-start justify-start text-start text-[5%] bg-white text-dark-gray p-1 rounded-md top-6 right-2 w-[80%]">
                          <div><span class="font-bold">Required Role</span>: {nft.discordRoleIDNeeded === 'none' ? 'None' : discordUserRoles[nft.discordRoleIDNeeded][1]}</div>
                          <div><span class="font-bold">Required Points</span>: {nft.points}pts</div>
                          <div><span class="font-bold">Max Mints</span>: {nft.maxMintsPerWallet}</div>
                          <div><span class="font-bold">Supply</span>: {nft.maxSupply - nft.amountMinted}/{nft.maxSupply}</div>
                        </div>)
                      }
                      <div className = "flex flex-col items-center justify-start w-full">
                        <div className="flex justify-between items-center w-full text-start text-gray-deep font-text text-[70%]">
                          <span> trait name: </span>
                          <FontAwesomeIcon
                          icon={faQuestionCircle}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                          />
                        </div>
                        <p className="w-full text-start text-gray-deep uppercase font-bold text-[60%] truncate">
                          {nft.name}
                        </p>
                        <p className="w-full text-start text-primary-red uppercase font-bold text-[60%] truncate">
                          {nft.points} pts
                        </p>
                      </div>
                      <img
                        alt="NFTs"
                        src={`${
                          nft.image
                        }?${new Date().getTime()}`}
                        className="cursor-pointer object-cover bg-[#EAE9E8] w-full"
                      />
                      {
                        loadingClaim ?
                        <p className ="flex items-center text-center justify-center w-full font-bold text-dark-gray">
                        ...
                        </p>
                        :
                        <>
                        {
                          alreadyClaimed?.includes(nft.traitID) ?
                          <button
                              className="w-[80%] h-full py-1 bg-[#793F35] rounded-xl text-white uppercase font-bold text-[70%] shadow-xl"
                            >
                              CLAIMED
                          </button>
                          :
                          <>
                          {
                            nft.points <= maxieStats.totalMP && (nft.discordRoleIDNeeded === 'none' || discordUserRoles[nft.discordRoleIDNeeded][0] === 1) ?
                            <button
                                disabled={clickedPurchased}
                                style={{ opacity: clickedPurchased ? 0.5 : 1}}
                                onClick={() => traitClaim(nft.traitID, nft.points, index)}
                                className="w-[80%] h-full py-1 bg-primary-red rounded-xl text-white uppercase font-bold text-[70%] shadow-xl"
                              >
                                CLAIM
                            </button>
                            :
                            <button
                                className="w-[80%] h-full py-1 bg-gray rounded-xl text-white uppercase font-bold text-[70%] shadow-xl"
                              >
                                INELIGIBLE
                            </button>

                          }
                          </>
                        }
                        </>
                      }
                    </div>
                  );
                })
              }
              </div>
          }
        </div>
      )
    }, [walletAddress, claimableTraits, toolTipVisible, alreadyClaimed, discordUserRoles, discordDict, clickedPurchased, maxieStats, loadingClaim])

    // trait claiming functionality
    const traitClaim = useCallback(async (traitID, costMP, index) => {
      setClickedPurchased(true)
      props.setPopup(true)
      props.setPopupState('claimingInProgress')
      // console.log(maxieStats.totalMP, costMP)
      if (maxieStats.totalMP >= costMP){
        var data = JSON.stringify({
          "action": "spendMP",
          'walletAddress': walletAddress,
          'costMP': costMP
        });
        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
        .then(async function (response) {
          // console.log(response)
          if (response.data.statusCode === 200){
            const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`);
            const walletPrivateKey = process.env.OWNER_WALLET
            const wallet = new ethers.Wallet(walletPrivateKey);
            const signer = wallet.connect(provider);

            let gasPrice = await provider.getGasPrice();
            gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2
            const transactionParameters = {
                gasPrice: gasPrice,
                gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
                // ...other transaction parameters like 'to', 'value' and 'data'
            };

            const claimsContract = new ethers.Contract(claimsContractAddress, MaxinTraitsABI, signer);

            const claim = await claimsContract.traitClaims(
              walletAddress,
              traitID,
              1,
              discordDict.discordUserID,
              transactionParameters
            )
            claim.wait()
            .then((receipt) => {
              const updatedTraits = [...claimableTraits];
              updatedTraits[index] = {
                  ...updatedTraits[index],            // Make a shallow copy of the first element
                  amountMinted: updatedTraits[index].amountMinted + 1
              };
              setClaimableTraits(updatedTraits)
              setReloadMaxieData(reloadMaxieData + 1)
              console.log('Approval successful!');
              setClickedPurchased(false);
              props.setPopupState('claimApproved')
            })
            .catch((error) => {
                console.error('Approval failed:', error);
                props.setPopupState('transactionError')
                var data = JSON.stringify({
                  "action": "returnMP",
                  'walletAddress': walletAddress,
                  'costMP': costMP
                });
                var config = {
                  method: 'post',
                  url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
                  headers: {
                    'x-api-key': process.env.GATEWAY_KEY,
                    'Content-Type': 'application/json'
                  },
                  data: data
                };
                axios(config)
                .then(function (response) {
                  // console.log(response)
                  // if (response.data.statusCode === 200){
                  //   // props.setPopupState('pointsReturned')
                  // }
                  // else {
                  //   // props.setPopupState('pointsNotReturned')
                  // }
                })
                .catch((error) => {
                  console.log(error)
                  // props.setPopupState('transactionError')
                })
            });
          }
          else {
            props.setPopupState('transactionError')
          }
        })
        .catch(function (error) {
          console.log(error);
          props.setPopupState('transactionError')
        });
      }
      else {
        props.setPopupState('transactionError')
      }

    }, [walletAddress, claimableTraits, connectedDiscord, discordDict, maxieStats])

    // render lunchboxes to claim
    const renderClaimLunchboxes = useCallback(() => {
      return (
        <div class="flex flex-col w-full overflow-y-scroll">
          <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full justify-center items-center text-center gap-4 overflow-y-scroll">
            {
                loadingLunchboxes ?
                <div className = "flex items-center text-center justify-center w-full h-full min-h-[350px]">
                  <img
                    className="w-[10%] animate-spin"
                    src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
                    alt="Loading"
                  />
                </div>
                :
                <>
                {
                allPacks?.filter((pack) => pack.amountPurchased.toNumber() !== pack.totalSupply.toNumber()).map((pack, index) => {
                  return (
                    <div key={index} class="relative flex flex-col items-center justify-center bg-dark-gray bg-opacity-20 w-full h-full p-2 gap-1">
                      <div className = "flex flex-col items-center justify-start w-full">
                        <p className="w-full text-start text-gray-deep uppercase font-bold text-[60%] truncate">
                          {pack.name}
                        </p>
                        <p className="w-full text-start text-primary-red uppercase font-bold text-[60%] truncate">
                          {pack.name.toLowerCase().includes('common') ? '500 pts' : '1000 pts'}
                        </p>
                      </div>
                      <video className="cursor-pointer object-cover w-full" autoPlay loop muted>
                        <source src={pack.name.toLowerCase().includes('common') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/commonBox.mp4' :
                                  pack.name.toLowerCase().includes('rare') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/rareBox.mp4' :
                                  pack.name.toLowerCase().includes('max') ? 'https://d34mwqgz4pm8c0.cloudfront.net/maxin/lunchbox/videos/legBox.mp4' :
                                  null} alt={pack.name} type = 'video/mp4'/>
                      </video>
                      {
                        loadingClaimLB ?
                        <p className ="flex items-center text-center justify-center w-full font-bold text-dark-gray">
                        ...
                        </p>
                        :
                        <>
                        {
                        (pack.name.toLowerCase().includes('common') ? 500 <= maxieStats.totalMP : 1000 <= maxieStats.totalMP) && discordUserRoles['1098467311532388436'][0] === 1 ?
                        <button
                            disabled={clickedPurchasedLB}
                            style={{ opacity: clickedPurchasedLB ? 0.5 : 1}}
                            onClick={() => lunchboxClaim(pack.packID, pack.name.toLowerCase().includes('common') ? 500 : 1000, index)}
                            className="w-[80%] h-full py-1 bg-primary-red rounded-xl text-white uppercase font-bold text-[70%] shadow-xl"
                          >
                            CLAIM
                        </button>
                        :
                        <button
                            className="w-[80%] h-full py-1 bg-gray rounded-xl text-white uppercase font-bold text-[70%] shadow-xl"
                          >
                            INELIGIBLE
                        </button>

                      }
                        </>
                      }
                    </div>
                  )
                })
              }
                </>
            }
          </div>
        </div>
      )
    }, [walletAddress, allPacks, discordDict, discordUserRoles, maxieStats, clickedPurchasedLB, loadingClaimLB])

    // lunchbox claiming functionality
    const lunchboxClaim = useCallback(async (lunchboxID, costMP, index) => {
        setClickedPurchasedLB(true)
        props.setPopup(true)
        props.setPopupState('claimingInProgress')
        if (maxieStats.totalMP >= costMP){
          var data = JSON.stringify({
            "action": "spendMP",
            'walletAddress': walletAddress,
            'costMP': costMP
          });
          var config = {
            method: 'post',
            url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
            headers: {
              'x-api-key': process.env.GATEWAY_KEY,
              'Content-Type': 'application/json'
            },
            data: data
          };

          axios(config)
          .then(async function (response) {
            if (response.data.statusCode === 200){
              const walletPrivateKey = process.env.OWNER_WALLET
              const wallet = new ethers.Wallet(walletPrivateKey);
              const signer = wallet.connect(provider);
              let gasPrice = await provider.getGasPrice();
              // Increase the gas price by a certain factor to speed up transaction processing (optional)
              gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2
              // Define transaction parameters
              const transactionParameters = {
                  gasPrice: gasPrice,
                  gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
                  // ...other transaction parameters like 'to', 'value' and 'data'
              };
              const packsContract = new ethers.Contract(packsContractAddress, PacksABI, signer);
              const giftLB = await packsContract.giftPack(
                lunchboxID,
                1,
                walletAddress,
                transactionParameters
              )
              giftLB.wait()
              .then((receipt) => {
                console.log('Approval successful!');
                setReloadMaxieData(reloadMaxieData + 1)
                setClickedPurchasedLB(false);
                props.setPopupState('claimApprovedLB')
              })
              .catch((error) => {
                console.error('Approval failed:', error);
                props.setPopupState('transactionError')
                var data = JSON.stringify({
                  "action": "returnMP",
                  'walletAddress': walletAddress,
                  'costMP': costMP
                });
                var config = {
                  method: 'post',
                  url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding',
                  headers: {
                    'x-api-key': process.env.GATEWAY_KEY,
                    'Content-Type': 'application/json'
                  },
                  data: data
                };
                axios(config)
                .then(function (response) {
                  // console.log(response)
                })
                .catch((error) => {
                  console.log(error)
                  // props.setPopupState('transactionError')
                })
              });
            }
            else {
              props.setPopupState('transactionError')
            }
          })
          .catch(function (error) {
            console.log(error);
            props.setPopupState('transactionError')
          });
        }

    }, [walletAddress, allPacks, maxieStats])

    // functions to test claims contract
    const testContract = async () => {
        const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`);
        const walletPrivateKey = process.env.OWNER_WALLET;
        const wallet = new ethers.Wallet(walletPrivateKey);
        const signer = wallet.connect(provider);

        let gasPrice = await provider.getGasPrice();

        // Increase the gas price by a certain factor to speed up transaction processing (optional)
        gasPrice = gasPrice.mul(2); // Increases the gas price by a factor of 2

        // Define transaction parameters
        const transactionParameters = {
            gasPrice: gasPrice,
            gasLimit: ethers.utils.hexlify(10000000),  // Set your gas limit here
            // ...other transaction parameters like 'to', 'value' and 'data'
        };

        const claimTestContract = new ethers.Contract(claimsContractAddress, MaxinTraitsABI, signer);

        claimTestContract.ct_changeTraitMaxMintsPerDiscord(
          3,
          1,
          transactionParameters
        )
        .then((receipt) => {
          console.log('approved transaction', receipt)
        }).catch((error) => {
          console.error('cancel failed:', error);
        })
    }
    const getClaims = async () => {
      const getClaims = await claimsContract.getClaimWalletMints(3, '0x5a755DB0F90A54336A7e770A1094e618d8B9Bf7B')
      // console.log(getClaims.toNumber())
      const getClaimsDiscord = await claimsContract.getClaimDiscordMints(3, '747562971634466816')
      // console.log(getClaimsDiscord.toNumber())
    }

    return (
      <div className= "grid sm:grid-cols-[1fr,3fr] w-full h-full overflow-y-scroll gap-4 sm:gap-0">
          <PointsBar
            portal='claimsPortal'
            level={maxieStats.level}
            totalXP={maxieStats.totalXP}
            totalMP={maxieStats.totalMP}
          />
          <div class ="flex flex-col w-full gap-1 overflow-y-scroll">
            <div class="flex flex-row w-full gap-8 items-center text-center justify-center text-full sm:justify-start sm:text-xl">
              <button onClick={() => setPortalView(0)}  class={`rounded-full ${portalView === 0 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
                Claim Traits
              </button>
              <button  onClick={() => setPortalView(1)} class={`rounded-full ${portalView === 1 ? "text-primary-red font-bold text-bold" : "text-dark-gray"} font-text text-center uppercase hover:font-bold hover:text-bold hover:text-primary-red`}>
                Claim Lunchboxes
              </button>
            </div>
            <p class = "text-dark-gray font-text text-start">Connect your wallet address & discord profile to enable claims</p>
            {
            portalView ?
            <>{renderClaimLunchboxes()}</>
            :
            <>{renderClaimTraits()}</>
          }
            {/*<button onClick={() => testContract()}> test </button>*/}
          </div>
      </div>
    );
}

export default SpendPoints;
