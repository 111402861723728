import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import BodyShopIMG from '../../img/bodyshop-img.png'

const BodyShopHome = (props) => {
  const [clients, setClients] = useState({})
  const [itemsToShow, setItemsToShow] = useState()
  const [stats, setStats] = useState({
            "totalTrans": '',
            "totalSales": '',
            "totalUpgrades": ''
          })

  useEffect(() => {

    var data = JSON.stringify({
      action: 'grabAllClients'
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyraffles",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response)
        setClients(response.data.clients)
        setStats({
                  "totalTrans": parseInt(response.data.totalCustomizations[0]) + parseInt(response.data.totalSales[0]),
                  "totalSales": response.data.totalSales[0],
                  "totalUpgrades": response.data.totalCustomizations[0]
                })
      })
      .catch(function (error) {
          console.log(error);
        })
  }, []);

  const carouselRef = useRef();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) { // adjust this value to your needs
        setItemsToShow(2);
      }
      else if (window.innerWidth < 1300) { // adjust this value to your needs
        setItemsToShow(3);
      }
      else {
        setItemsToShow(4);
      }
    };

    if (window.innerWidth < 600) {
        setItemsToShow(2);
      }
    else if (window.innerWidth < 1300) {
      setItemsToShow(3);
    }
    else {
      setItemsToShow(4);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const totalPages = Math.ceil(clients.length / itemsToShow)
  let resetTimeout;

  // enter maxin body shop
  const enterMaxinShop = (async () => {
    // const index = await props.raffleContract.getRaffleIndex(item.creator, item.nftContractAddress, item.nftId)
    props.navigate(`/bodyshop/maxin`)
  })

  return (
    <div class="flex flex-col lg:grid lg:grid-cols-[1fr,1.5fr] lg:grid-rows-[30%,70%] items-center text-center justify-center gap-4 lg:gap-0 lg:items-start lg:text-start lg:justify-between w-full h-full lg:p-4">
      <div class="flex flex-col font-logo text-dark-gray items-center justify-center gap-4 lg:gap-8">
        <div class="flex flex-col font-logo text-dark-gray items-center justify-center">
          <h1 className="text-[8vw] sm:text-[4vw]">THE BODY SHOP</h1>
          <p className="text-[4vw] sm:text-[1.5vw] -mt-2">a robust trait swapping solution</p>
        </div>
        <button onClick = {() => enterMaxinShop()}class="text-[4vw] sm:text-[1.5vw] py-1 px-12 rounded-full bg-dark-gray font-text font-bold text-bold text-primary-yellow text-center shadow-2xl hover:text-dark-gray hover:bg-primary-yellow" >
          ENTER MAXIN BODY SHOP
        </button>
      </div>
      <div class="flex flex-col items-center justify-center w-full">
        <div class="grid grid-cols-3 gap-4 w-full h-full">
          <div class="flex flex-col bg-[#D9D9D9] justify-center items-center text-center rounded-lg px-2 py-4">
            <p class="text-[10px] sm:text-sm w-full h-[50%] font-text font-bold text-dark-gray uppercase">Traits Purchased</p>
            <p class="text-2xl sm:text-4xl w-full font-text font-bold text-primary-red">{stats.totalSales}</p>
          </div>
          <div class="flex flex-col bg-[#D9D9D9] justify-center items-center text-center rounded-lg px-2 py-4">
            <p class="text-[10px] sm:text-sm w-full h-[50%] font-text font-bold text-dark-gray uppercase">Customizations</p>
            <p class="text-2xl sm:text-4xl font-text font-bold text-primary-red">{stats.totalUpgrades}</p>
          </div>
          <div class="flex flex-col bg-[#D9D9D9] justify-center items-center text-center rounded-lg px-2 py-4">
            <p class="text-[10px] sm:text-sm w-full h-[50%] font-text font-bold text-dark-gray uppercase">Transactions Processed</p>
            <p class="text-2xl sm:text-4xl font-text font-bold text-primary-red">{stats.totalTrans}</p>
          </div>
        </div>
      </div>
      <div class="hidden lg:block flex flex-col items-center justify-start w-full h-full">
      </div>
      <div class="flex flex-col items-center justify-start w-full h-full gap-8 sm:gap-16">
        <p className="text-[4vw] sm:text-[1.7vw] font-bold text-dark-gray"><span class="text-primary-red">EXPLORE</span> some of our popular collections:</p>
        <Carousel
            ref={carouselRef}
            enableAutoPlay
            autoPlaySpeed={4000}
            itemsToShow={itemsToShow}
            pagination={false}
            itemPadding={[0, 10]}
            disableArrowsOnEnd={true}
            showEmptySlots={true}
          >
          {
            Object.keys(clients).filter((client) => client !== 'Maxin' && client !== 'OniMasks' && client !== 'cryptocrew' && client !== 'Sorcies').map((client) => {
              return(
                <div className="flex flex-col items-center justify-center text-center gap-2">
                  <div className="w-32 h-32 rounded-full overflow-hidden bg-[#D9D9D9] p-2">
                    <a href={`https://body-shop.xyz/${client}`} target="_blank" rel="noopener noreferrer">
                      <img
                        src={clients[client][1]}
                        className="w-full h-full object-contain"
                        alt={client}
                      />
                    </a>
                  </div>
                  <p class="font-bold text-dark-gray uppercase text-center">{clients[client][0]}</p>
                </div>
              )
            })
          }
        </Carousel>
      </div>
    </div>
  )

}

export default BodyShopHome
