import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios from "axios";
import RafflesHome from './Raffle/RafflesHome'
import CreateRaffle from './Raffle/CreateRaffle'
import SelectedRaffle from "./Raffle/SelectedRaffle"
import Profile from './Raffle/Profile'
import Loading from './Raffle/Loading'
import Customizer from './Customizer/Builder'
import Whitepaper from './Whitepaper/Whitepaper'
import BodyShopHome from './BodyShopHome/BodyShopHome'
import BodyShopMaxin from './BodyShopHome/MaxinDrops'
import BSMaxinCustomize from './BodyShopHome/MaxinCustomize'
import MaxiesCheck from './BodyShopHome/MaxieCheck'
import TraitsInfo from './BodyShopHome/TraitsInfo'
import Points from './Points/PointsNew'
import PointsProfile from './Points/Profile'
import PointsDashboard from './Points/Dashboard'
import PointsSpend from './Points/SpendPoints'
import PointsEarn from './Points/EarnPoints'
import PointsLeaderboards from './Points/Leaderboards'
import Home from './Home'
import SideBarNew from '../Components/SideBarNew'
import MaxinLogo from '../img/maxinLogo.png'
import PolyDogeIcon from "../img/polyDogeIcon.png";
import DrillClubLogo from '../img/drillClubLogo.png'
import RokoroLogo from '../img/rokoroLogo.png'
import Failed from "../img/failedtransaction.png";
import PoweredBy from "../img/poweredBy.png"
import MaxinHome from "../img/maxinHome.png"
import BodyShopIMG from '../img/bodyshop-img.png'
import twitter from "../img/twitterHome.svg";
import discord from "../img/discord.svg";
import { Checkmark } from 'react-checkmark';
import '../Components/WalletCard.css'
import { useAccount} from 'wagmi'
import RaffleContractABI from '../Components/raffleContractABI.json';
import {ethers} from 'ethers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus, faHouse, faArrowUp, faTools, faCoins, faTicket, faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import { DynamicWidget } from '@dynamic-labs/sdk-react';
import menuIcon from "../img/burger-menu.svg";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Network, Alchemy } from "alchemy-sdk";
import { useLocation } from 'react-router-dom';

const Nav = ({ children, ...props }) => {
  // home screen one-time loader
  // console.log(props)
  // Logo variables
  // const logoList = [MaxinLogo, PolyDogeIcon, DrillClubLogo, RokoroLogo];
  // const [currentLogo, setCurrentLogo] = useState(0);
  // const [opacity, setOpacity] = useState(1);

  // page variable
  var currentURL = window.location.href;

  const [page, setPage] = useState('')
  const navigate = useNavigate();
  const [showScroll, setShowScroll] = useState(false)

  // mobile variables
  const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = (val) => {
    setShowMenu(val || !showMenu);
  };

  // popup variables
  const [popup, setPopup] = useState(false)
  const [popupState, setPopupState] = useState();
  const [popupArray, setPopupArray] = useState([])
  const timeoutRef = useRef();

  // wallet variables
  let defaultAccount = useAccount({
    onConnect({ address, connector, isReconnected }) {
      console.log('Connected', { address, connector, isReconnected })
    },
  });
  defaultAccount = useAccount({
    onDisconnect() {
      console.log('Disconnected')
    },
  })

  // verified collections
  const [verifiedCollections, setVerifiedCollections] = useState([])
  const [verifiedUsers, setVerifiedUsers] = useState()

  // raffle variables
  const [allRaffles, setAllRaffles] = useState([])
  const [allSelectedRaffles, setAllSelectedRaffles] = useState()
  const [updatedRaffles, setUpdatedRaffles] = useState(true)
  const [checkIfContains, setCheckIfContains] = useState(
    {
      hasMaxinRaffle: false,
      hasDrillClubRaffle: false,
      hasPolyDogeRaffle: false,
      hasRokoroRaffle: false,
      hasPepeRaffle: false
    }
  )

  // loading variables
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    console.log(currentURL)
    var urlParts = currentURL.split("/");
    var pageName = urlParts[urlParts.length - 1];
    if (urlParts.includes("raffles")){
      pageName = 'raffles'
    }
    else if (urlParts.includes("points")){
      pageName = 'points/profile'
    }
    else if (urlParts.includes("maxin")){
      if (urlParts.includes("customize") || urlParts.includes("maxies") || urlParts.includes("traits")){
        pageName = 'bodyshop/maxin/' + pageName
      } else{
        pageName = 'bodyshop/' + pageName
      }
    }
    console.log(pageName)
    setPage(pageName)
  }, [currentURL])

  // get all Verified Collections
  useEffect(() => {
    var data = JSON.stringify({
      action: "getAllVerifiedCollections"
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyraffles",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        let tempVerifiedCollections = []
        for (const collection of response.data.verifiedCollections){
          tempVerifiedCollections.push(collection[2].toLowerCase())
        }
        setVerifiedCollections(tempVerifiedCollections)
      })
      .catch(function (error) {
        console.log(error)
      });
  }, []);

  // get all Verified Users
  useEffect(() => {
    var data = JSON.stringify({
      'action': 'getVerifiedUsers'
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyraffles",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        let verifedUsersTemp = {}
        for (const userData of response.data.verifiedUsers){
          verifedUsersTemp[userData[0]] = {
            twitterHandle: userData[1],
            discordHandle: userData[2],
            discordPFP: userData[4],
            twitterPFP: userData[5]
          }
        }
        // console.log(verifedUsersTemp)
        setVerifiedUsers(verifedUsersTemp)
      })
      .catch(function (error) {
        console.log(error)
      });

  }, []);

  //instantiate raffle contract
  // 0xb333D8a6c221e3B380e15080040D404969F11Eb6
  const raffleContractAddress = '0xc910443f676b9af2e7d8B57b8565bc4DF8f5dcED'
  const provider = ethers.getDefaultProvider(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_KEY}`);
  const raffleContract = new ethers.Contract(raffleContractAddress, RaffleContractABI, provider);

  // toggle to create page
  const handleCreateRaffleClick = useCallback(() => {
    setShowMenu(false)
    navigate("/raffles/create")
  }, []);

  // toggle to user profile page
  const handleUserProfileClick = useCallback(() => {
    if(defaultAccount.address){
      setShowMenu(false)
      navigate(`/raffles/profile/user`)
    }
    else{
      setPopup(true)
      setPopupState("notSignedIn")
    }
  }, [defaultAccount]);

  // toggle to home page
  const handleHomePageClick = useCallback(() => {
    setShowMenu(false)
    navigate("/")
  }, []);

  const handleRafflePageClick = useCallback(() => {
    setShowMenu(false)
    navigate("/raffles")
    setPage("raffles")
  }, []);

  const [alchemy, setAlchemy] = useState(null);

  useEffect(() => {
    if (page === 'raffles'){
      const settings = {
        apiKey: process.env.ALCHEMY_KEY,
        network: Network.MATIC_MAINNET,
      };
      setAlchemy(new Alchemy(settings));
    }
  }, []);

  // Function to get the metadata of an NFT: accepts the NFT contract address and the token ID to get the metadata of
  // async function getNFTMetadata(nftContractAddress, tokenId) {
  //   try {
  //     const response = await alchemy.nft.getNftMetadata(nftContractAddress, tokenId);
  //     console.log(response)
  //     return response;
  //   } catch (error) {
  //     console.error('Error getting NFT metadata:', error);
  //     throw error;
  //   }
  // }

  useEffect(() => {
    // console.log(page, allRaffles.length)
    if (page === 'raffles') {
      const fetchData = async () => {
        const raffles = await raffleContract.getAllRaffles();
        console.log(raffles)
        if (!raffles) return;

        const tempSelectedRaffles = {};

        const rafflePromises = raffles.map(async (raffle, index) => {
          // if (raffle.nftContractAddress.toLowerCase() === '0x0dB6aD9ce8f2299ef23E06AB55A6E126878855B3'.toLowerCase() && raffle.status === 0  && checkIfContains['hasMaxinRaffle'] === false){
          //   checkIfContains['hasMaxinRaffle'] = true
          // }
          // console.log(raffle)
          if (raffle.nftContractAddress.toLowerCase() === '0xd51b6113995a1d30125F60a542b2aE0bCE678715'.toLowerCase() && raffle.status === 0  && checkIfContains['hasMaxinRaffle'] === false){
            console.log(raffle)
            checkIfContains['hasMaxinRaffle'] = true
          }
          else if (raffle.nftContractAddress.toLowerCase() === '0x39cd103414106b922eb09c7d45df89608b59e887'.toLowerCase() && raffle.status === 0 && checkIfContains['hasDrillClubRaffle'] === false){

            checkIfContains['hasDrillClubRaffle'] = true
          }
          else if (raffle.nftContractAddress.toLowerCase() === '0xd67D0Dd4B6E8639F8c51F60bFcb646Cb6eD5E993'.toLowerCase() && raffle.status === 0 && checkIfContains['hasRokoroRaffle'] === false){
            checkIfContains['hasRokoroRaffle'] = true
          }

          if (raffle.tokenAddress.toLowerCase() === '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472'.toLowerCase() && raffle.status === 0 && checkIfContains['hasPolyDogeRaffle'] === false){
            checkIfContains['hasPolyDogeRaffle'] = true
          }
          else if (raffle.tokenAddress.toLowerCase() === '0xfcA466F2fA8E667a517C9C6cfa99Cf985be5d9B1'.toLowerCase() && raffle.status === 0 && checkIfContains['hasPepeRaffle'] === false){
            checkIfContains['hasPepeRaffle'] = true
          }

          const data = JSON.stringify({
            action: "getRaffleExtraData",
            collectionAddress: raffle.nftContractAddress,
            raffleID: index,
          });

          const config = {
            method: "post",
            url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyraffles",
            headers: {
              "x-api-key": process.env.GATEWAY_KEY,
              "Content-Type": "application/json",
            },
            data: data,
          };

          try {
            const response = await axios(config);
            const extradata = JSON.parse(response.data.extradata);
            const addedRaffleData = {
              collectionName: extradata[0],
              nftName: extradata[4],
              imageLink: extradata[2],
              verified: extradata[3],
              featured: extradata[5],
              endDate: extradata[6],
              raffleID: raffles.indexOf(raffle)
            };
            const updatedRaffle = {
              ...raffle,
              ...addedRaffleData
            };

            // Update tempSelectedRaffles
            tempSelectedRaffles[index] = updatedRaffle;

            return updatedRaffle;
          } catch (error) {
            // Handle error here if necessary
            console.log(error)
            return null;
          }
        });

        try {
          const tempAllRaffles = await Promise.all(rafflePromises);

          // Filter out null results
          const validRaffles = tempAllRaffles.filter(Boolean);

          // Your sort logic
          validRaffles.sort((a, b) => {
            if (a.featured !== b.featured) {
              return b.featured - a.featured;
            } else {
              return (a.startDate.toNumber() + a.duration.toNumber()) - (b.startDate.toNumber() + b.duration.toNumber());
            }
          });

          setAllRaffles(validRaffles);
          setAllSelectedRaffles(tempSelectedRaffles);
          setIsLoading(false);

        } catch (error) {
          // Handle error
          console.error(error);
        }
      };

      fetchData();
    }
  }, [updatedRaffles, page]);


  useEffect(() => {
    if (popupState === 'transactionError' || popupState === 'transferApproved'
          || popupState === 'raffleCreated' || popupState === 'rafflePurchased'
          || popupState === 'wrongAmount' || popupState === 'cancelledRaffle' || popupState === 'partnerEmpty' || popupState === 'notSignedIn' || popupState === 'cantBeFeatured' || popupState === 'claimApproved' || popupState === 'inelegibleCustomization' || popupState === 'packPurchaseApproved' || popupState === 'packPurchaseApprovedWithoutReveal' || popupState === "upgradeApproved" || popupState === "headOver" || popupState === "invalidID" || popupState === "rewardsClaimed" || popupState === "rewardsError" || popupState == 'tweetInputFailed' || popupState == 'tweetInputSuccess' || popupState === 'claimApprovedLB'){
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        resetPopup(popupState);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [popupState, popup])

  const renderPopup = () => {
    if (popupState === "transferApprovalInProgress") {
      // clearTimeout(timeoutRef.current);
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Approval in Progress - please follow the prompt on your wallet.</p>
          </div>
        </div>
      );
    }
    else if(popupState === "transactionError"){
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Transaction Failed. Please Try Again.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "transferApproved") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">NFT approved to transfer! Fill out the raffle parameters to raffle your NFT.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "raffleCreationInProgress") {
      // clearTimeout(timeoutRef.current);
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Raffle Creation in Progress - please follow the prompt on your wallet.</p>
          </div>
        </div>
      );
    }
    else if (popupState === "raffleCreated") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Raffle Created! Head over to your profile to view live raffle.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "purchaseInProgress") {
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Purchase in Progress - please follow the prompt on your wallet.</p>
          </div>
        </div>
      );
    }
    else if (popupState === "rafflePurchased") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Raffle Tickets Purchased!</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if(popupState === "wrongAmount"){
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Amount Entered Invalid. Please Try Again.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "cancellationInProgress") {
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Cancellation in Progress - please follow the prompt on your wallet.</p>
          </div>
        </div>
      );
    }
    else if (popupState === "cancelledRaffle") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Raffle has been Cancelled! Your NFT has been safely returned.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if(popupState === "notSignedIn"){
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Must be signed in to your wallet to access profile. Connect your wallet and try again.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if(popupState === "partnerEmpty"){
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Currently no live raffles for this collection available.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if(popupState === "cantBeFeatured"){
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">This collection is not verified within our system and therefore cannot be a featured raffle.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "claimingInProgress") {
      // clearTimeout(timeoutRef.current);
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Claim in Progress...</p>
          </div>
        </div>
      );
    }
    else if (popupState === "claimApproved") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Transaction Approved! Your claim is being processed and your trait will be sent upon approval.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if(popupState === "inelegibleCustomization"){
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Inelegible Customization. Try a different trait.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "purchasingPack") {
      // clearTimeout(timeoutRef.current);
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Purchasing Pack - please follow the prompt on your wallet.</p>
          </div>
        </div>
      );
    }
    else if (popupState === "waitingForReveal") {
      // clearTimeout(timeoutRef.current);
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Transaction Approved - Waiting for reveal, this can take a while...</p>
          </div>
        </div>
      );
    }
    else if (popupState === "packPurchaseApproved") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Transaction Approved! You can now reveal your traits.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "packPurchaseApprovedWithoutReveal") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Transaction Approved! Traits cannot be revealed due to network congestion. Head over to customizer to see your new traits.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "upgradeInProgress") {
      // clearTimeout(timeoutRef.current);
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Upgrading in Progress...Do not exit out of page.</p>
          </div>
        </div>
      );
    }
    else if (popupState === "upgradeApproved") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Transaction Approved! Your Maxie has finished upgrading.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "headOver") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Head over to 'customize' to view traits.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if(popupState === "invalidID"){
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Invalid Token ID.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "verifyingInstruction") {
      // clearTimeout(timeoutRef.current);
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Verifiying Instructions...</p>
          </div>
        </div>
      );
    }
    else if (popupState === "rewardsClaimed") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Rewards have been claimed!</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "rewardsError"){
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Verification Failed. Please try again in 15 minutes.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "verifyingInput") {
      // clearTimeout(timeoutRef.current);
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Verifiying Tweet ID...</p>
          </div>
        </div>
      );
    }
    else if (popupState === "tweetInputSuccess") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Tweet has been added!</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "tweetInputFailed"){
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Tweet Input Failed. Please ensure that tweet tags @maxinNFT.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "tweetInputConnectWallet"){
      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img
                className="h-5 w-5 mr-4"
                src={Failed}
                alt="Loading"
              />
              <p className="text-white font-lekton mr-2">Connect Wallet to input Tweet.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
    else if (popupState === "claimApprovedLB") {
      // setTimeout(() => {
      //   resetPopup(popupState);
      // }, 5000);

      return (
        <div style={{position:'fixed', bottom: 35, right: 35, zIndex:100}}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size='24px'/>
              <p className="text-white font-lekton ml-2 mr-2">Claim Approved! Head over to "gift drops" under the "drops" tab to claim your lunchbox.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
              >
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
  };
  const resetPopup = () => {
    setPopup(false);
    setPopupState();
  };

  // functionality to render scroll back up button.
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false)
      }
    };

    window.addEventListener('scroll', checkScrollTop)

    return () => {
      window.removeEventListener('scroll', checkScrollTop)
    };
  }, [showScroll]);

  // functionality to scroll back to top of page.
  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
      <section id="nav-section" className=" min-h-screen grid sm:grid-cols-[max-content,1fr] ">
        <div
          className={`hidden sm:block fixed  z-20 right-0 h-screen sm:sticky top-0 bg-white transition-all`}
        >
          <SideBarNew
            navigate={navigate}
            setPage={setPage}
            page={page}
          />
        </div>
        <div className="flex flex-col h-full flex-grow gap-8 z-10">
          <div className="top-0 right-0 sticky z-30 w-full">
            <div className="header flex justify-between sm:justify-end items-center sm:w-full pl-5 pr-10 sm:px-7 relative">
              <div class="h-[80%] flex items-center justify-center sm:hidden">
                <img src={MaxinLogo} alt="CurrentLogo" class="max-h-full max-w-full hover:cursor-pointer" onClick={() => {navigate("/")}} />
              </div>
              <div
                className={`fixed  flex flex-col items-center justify-start gap-8 p-8 w-[70%] z-20 right-0 h-screen sm:sticky top-0 bg-gray-deep/90 sm:bg-transparent transition-all ${
                  showMenu ? "sm:hidden" : "opacity-0 pointer-events-none sm:opacity-100 sm:pointer-events-auto sm:hidden"
                }`}
              >
                {
                  showMenu ?
                  <>
                  <div class="flex flex-col gap-2">
                    <DynamicWidget />
                    <div class="w-full h-[10%] mt-4 flex flex-col text-gilroy-bold uppercase text-white items-center justify-center mb-4 hover:cursor-pointer">
                      POWERED BY
                      <img src={MaxinLogo} alt="PoweredBy" class="max-h-full max-w-full" onClick={() => window.open("https://discord.gg/p77QeCs6qs")} />
                    </div>
                    <button className="flex h-[5%] gap-4 items-center w-full font-gilroy-regular text-lg text-primary-yellow px-4 py-2 rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"  onClick={handleHomePageClick}>
                      <FontAwesomeIcon icon={faHouse} />
                      <div>Home</div>
                    </button>
                    <button className="flex h-[5%] gap-4 items-center w-full font-gilroy-regular text-lg text-primary-yellow px-4 py-2 rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"  onClick={handleCreateRaffleClick}>
                      <FontAwesomeIcon icon={faPlus} />
                      <div>Create Raffle</div>
                    </button>
                    <button className="flex h-[5%] gap-4 items-center w-full font-gilroy-regular text-lg text-primary-yellow px-4 py-2 rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"  onClick={handleUserProfileClick}>
                      <FontAwesomeIcon icon={faUser} />
                      <div>Raffle Profile</div>
                    </button>
                    <button className="flex h-[5%] gap-4 items-center w-full font-gilroy-regular text-lg text-primary-yellow px-4 py-2 rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"  onClick={handleRafflePageClick}>
                      <FontAwesomeIcon icon={faTicket} />
                      <div>Raffle Home</div>
                    </button>
                    <button className="flex h-[5%] gap-4 items-center w-full font-gilroy-regular text-lg text-primary-yellow px-4 py-2 rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"
                      onClick={() => {
                        navigate("/points/profile")
                        setPage("points")
                        setShowMenu(false)
                        }
                      }>
                      <FontAwesomeIcon icon={faCoins} />
                      <div>Points</div>
                    </button>
                    <button className="flex h-[5%] gap-4 items-center w-full font-gilroy-regular text-lg text-primary-yellow px-4 py-2 rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"
                    onClick={() => {
                      navigate("/bodyshop/maxin")
                      setPage("/bodyshop/maxin")
                      setShowMenu(false)
                      }
                    }>
                      <FontAwesomeIcon icon={faTools} />
                      <div>Drops</div>
                    </button>
                    <button className="flex h-[5%] gap-4 items-center w-full font-gilroy-regular text-lg text-primary-yellow px-4 py-2 rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"  onClick={() => {
                      navigate("/bodyshop/maxin/customize")
                      setPage("/bodyshop/maxin/customize")
                      setShowMenu(false)
                    }}>
                      <FontAwesomeIcon icon={faPaintbrush} />
                      <div>Customize</div>
                    </button>
                    <button className="flex h-[5%] gap-4 items-center w-full font-gilroy-regular text-lg text-primary-yellow px-4 py-2 rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"  onClick={() => {
                      navigate("/bodyshop/maxin/maxies")
                      setPage("/bodyshop/maxin/maxies")
                      setShowMenu(false)
                    }}>
                      <FontAwesomeIcon icon={faPaintbrush} />
                      <div>Maxies</div>
                    </button>
                  </div>
                  <button className="flex gap-4 items-center font-gilroy-bold text-lg text-primary-yellow px-4 py-2 rounded-full drop-shadow-lg border border-white hover:bg-gray-100 -mt-56"  onClick={() => {setShowMenu(false)}}>
                    X
                  </button>
                  </>
                  :
                  ""
                }
              </div>
              <div onClick={handleShowMenu} className="sm:hidden h-auto">
                <img className=" cursor-pointer" src={menuIcon} alt="menu button" />
              </div>
              {
                page === "raffles" ?
                <div className=" hidden h-full sm:flex sm:items-center sm:justify-center sm:gap-4">
                  <button className="hidden sm:flex items-center bg-dark-gray font-gilroy-regular text-2xl text-primary-yellow px-4 py-[7px] rounded-lg drop-shadow-lg border border-white hover:bg-gray-100"  onClick={handleCreateRaffleClick}>
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <div>
                    <DynamicWidget />
                  </div>
                  <button className="hidden sm:flex bg-dark-gray items-center font-gilroy-regular text-md text-primary-yellow p-3 drop-shadow-lg rounded-full border border-white hover:bg-gray" onClick={handleUserProfileClick}>
                    <FontAwesomeIcon icon={faUser} />
                  </button>
                </div>
                :
                <>
                {
                  page.includes("bodyshop/maxin") || page.includes("points") ?
                  <div className="hidden h-full w-full sm:flex sm:items-center sm:justify-end">
                    <DynamicWidget />
                  </div>
                  :
                  ""
                }
                </>
              }
            </div>
          </div>
          <div className={`flex items-center justify-center w-full h-full ${page === 'raffles' ? 'px-2': ''} sm:px-0 ${showMenu ? "blur-lg sm:blur-none" : ""}`}>
            {popup ? renderPopup(): ""}
            {isLoading && page === "raffles" ? (
              <div className="flex items-center justify-center h-screen">
                <Loading />
              </div>
            )
            :
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/raffles" element={<RafflesHome
                defaultAccount = {defaultAccount}
                setPage={setPage}
                allRaffles={allRaffles}
                allSelectedRaffles={allSelectedRaffles}
                raffleContract = {raffleContract}
                raffleContractABI = {RaffleContractABI}
                raffleContractAddress = {raffleContractAddress}
                provider={props.provider}
                setPopup={setPopup}
                setPopupState={setPopupState}
                setPopupArray={setPopupArray}
                resetPopup={resetPopup}
                verifiedCollections={verifiedCollections}
                verifiedUsers={verifiedUsers}
                checkIfContains={checkIfContains}
                navigate={navigate}
              />} />
              <Route path="/raffles/create" element={<CreateRaffle
                defaultAccount = {defaultAccount}
                setPage={setPage}
                raffleContractABI = {RaffleContractABI}
                raffleContractAddress = {raffleContractAddress}
                provider={props.provider}
                setPopup={setPopup}
                setPopupState={setPopupState}
                verifiedCollections={verifiedCollections}
                verifiedUsers={verifiedUsers}
                setUpdatedRaffles = {setUpdatedRaffles}
                setIsLoading={setIsLoading}
                navigate={navigate}
              />} />
              <Route path="/raffles/profile/:userAddress" element={<Profile
                defaultAccount = {defaultAccount}
                setPage={setPage}
                allRaffles={allRaffles}
                allSelectedRaffles={allSelectedRaffles}
                raffleContract = {raffleContract}
                raffleContractABI = {RaffleContractABI}
                raffleContractAddress = {raffleContractAddress}
                provider={props.provider}
                setPopup={setPopup}
                setPopupState={setPopupState}
                verifiedCollections={verifiedCollections}
                verifiedUsers={verifiedUsers}
                setUpdatedRaffles = {setUpdatedRaffles}
                setIsLoading={setIsLoading}
                navigate={navigate}
              />} />
              <Route path="/raffles/raffle/:raffleId" element={
                  (page === 'raffles' && allRaffles !== undefined) ? (
                      <SelectedRaffle
                          defaultAccount={defaultAccount}
                          setPage={setPage}
                          page={page}
                          allRaffles={allRaffles}
                          allSelectedRaffles={allSelectedRaffles}
                          raffleContractABI={RaffleContractABI}
                          raffleContractAddress={raffleContractAddress}
                          provider={props.provider}
                          setPopup={setPopup}
                          setPopupState={setPopupState}
                          verifiedCollections={verifiedCollections}
                          verifiedUsers={verifiedUsers}
                          navigate={navigate}
                      />
                  ) : null // or you can provide a fallback component or message here
              } />
              <Route path="/customizer" element={<Customizer/>} />
              <Route path="/whitepaper" element={<Whitepaper/>} />
              <Route path="/bodyshop" element={<BodyShopHome
                defaultAccount = {defaultAccount}
                setPage={setPage}
                navigate={navigate}
                provider={props.provider}
                setPopup={setPopup}
                setPopupState={setPopupState}
                setPopupArray={setPopupArray}
                resetPopup={resetPopup}
                />} />
              <Route path="/bodyshop/maxin" element={<BodyShopMaxin
                defaultAccount = {defaultAccount}
                setPage={setPage}
                navigate={navigate}
                provider={props.provider}
                setPopup={setPopup}
                setPopupState={setPopupState}
                setPopupArray={setPopupArray}
                resetPopup={resetPopup}
                />} />
              <Route path="/bodyshop/maxin/customize" element={<BSMaxinCustomize
                defaultAccount = {defaultAccount}
                setPage={setPage}
                navigate={navigate}
                provider={props.provider}
                setPopup={setPopup}
                setPopupState={setPopupState}
                setPopupArray={setPopupArray}
                resetPopup={resetPopup}
                />} />
              <Route path="/bodyshop/maxin/maxies" element={<MaxiesCheck
                defaultAccount = {defaultAccount}
                setPage={setPage}
                navigate={navigate}
                provider={props.provider}
                setPopup={setPopup}
                setPopupState={setPopupState}
                setPopupArray={setPopupArray}
                resetPopup={resetPopup}
                />} />
              <Route path="/bodyshop/maxin/traits" element={<TraitsInfo
                defaultAccount = {defaultAccount}
                setPage={setPage}
                navigate={navigate}
                provider={props.provider}
                setPopup={setPopup}
                setPopupState={setPopupState}
                setPopupArray={setPopupArray}
                resetPopup={resetPopup}
                />} />
              <Route path="/points" element={<Points
                  defaultAccount = {defaultAccount}
                  provider={props.provider}
                  setPopup={setPopup}
                  setPopupState={setPopupState}
                  setPopupArray={setPopupArray}
                  resetPopup={resetPopup}
                />}>
                  <Route index element={<div />} />
                  <Route path="profile" element={<PointsProfile
                    defaultAccount = {defaultAccount}
                    provider={props.provider}
                    setPopup={setPopup}
                    setPopupState={setPopupState}
                    setPopupArray={setPopupArray}
                    resetPopup={resetPopup}/>
                  } />
                  <Route path="spend" element={<PointsSpend
                    defaultAccount = {defaultAccount}
                    provider={props.provider}
                    setPopup={setPopup}
                    setPopupState={setPopupState}
                    setPopupArray={setPopupArray}
                    resetPopup={resetPopup}
                    />} />
                  <Route path="earn" element={<PointsEarn
                    defaultAccount = {defaultAccount}
                    provider={props.provider}
                    setPopup={setPopup}
                    setPopupState={setPopupState}
                    setPopupArray={setPopupArray}
                    resetPopup={resetPopup}
                    />} />
                  <Route path="leaderboards" element={<PointsLeaderboards
                    defaultAccount = {defaultAccount}
                    provider={props.provider}
                    setPopup={setPopup}
                    setPopupState={setPopupState}
                    setPopupArray={setPopupArray}
                    resetPopup={resetPopup}
                    />} />
              </Route>
            </Routes>
            }
          </div>
          {
            page !== "raffles" && page !== "bodyshop/maxin/customize" && page !== "bodyshop/maxin/traits" ?
            <div className="hidden lg:flex lg:fixed lg:bottom-8 lg:right-8 lg:z-50 lg:hover:cursor-pointer lg:gap-4">
                <img src={discord} alt="logo" onClick={() => window.open("https://discord.gg/p77QeCs6qs")} class="w-[40px]"/>
                <img src={twitter} alt="logo" onClick={() => window.open("https://twitter.com/maxinNFT")} class="w-[40px]"/>
            </div>
            :
            ""
          }
          {showScroll &&
            <FontAwesomeIcon onClick={scrollTop} icon={faArrowUp} className="fixed bottom-6 left-2 sm:left-[154px] w-[4vw] z-50  py-4 rounded-full bg-gray text-white hover:cursor-pointer hover:bg-opacity-50" />
          }
        </div>
        {
          page === "bodyshop" ?
          <img src={BodyShopIMG} class="absolute bottom-0 z-0" />
          :
          ""
        }
      </section>
  );

}

export default Nav;
